import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import '../../components/LicenseModal/LicenseModal.css'
const LicenseModal = ({openModal,closeModal}) => {
    const [bundlePrice,setBundlePrice] = useState(500)
    const [userLicensePrice,setUserLicensePrice] = useState(500)
    const [companyLicensePrice,setCompanyLicensePrice] = useState(500)
    const [purchaseItems,setPurchaseItems] = useState({
      fiveJobsBundle : 0,
      userLicense : 0,
      companyLincense : 0
    })
    const licenseModalStyle = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width:window.screen.width>1023 ? "40%" : "80%",
          borderRadius: "25px",
          padding: "10px",
          height:"400px"
        },
      };
      const handleDecreaseCount = (field,count) => {
        if(count>0){
            // if(field == "fiveJobsBundle"){
                setPurchaseItems({...purchaseItems,[field]:count - 1})
            // }
            // else if(field == "userLicense"){
            //     setPurchaseItems({...purchaseItems,userLicense:count - 1})
            // }
        }
      }
      const handleIncreaseCount = (field,count) => {
        if(count<20){
            setPurchaseItems({...purchaseItems,[field]:count + 1})
        }
      }
      const handleCloseModal = () => {
        setPurchaseItems({fiveJobsBundle:0,userLicense:0,companyLincense:0})
        closeModal(false)
      }
  return (
    <div>
        <Modal
        isOpen={openModal}
        onRequestClose={()=>closeModal(false)}
        ariaHideApp={false}
        style={licenseModalStyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      > 
        <div className="CmpnyDetailsCrossIcon" onClick={()=>handleCloseModal()}>x</div>
        <div className="CmpnyDetailTitle">Purchase License</div>
        <div className='licenseContentOuterDiv'>
        <div className='licenseContent'>
            <div><span>5 Jobs Bundle</span><span>:</span></div>
            <div>
                <span onClick={()=>handleDecreaseCount("fiveJobsBundle",purchaseItems?.fiveJobsBundle)}>-</span>
                <span>{purchaseItems?.fiveJobsBundle}</span>
                <span onClick={()=>handleIncreaseCount("fiveJobsBundle",purchaseItems?.fiveJobsBundle)}>+</span>
            </div>
        </div>
        <div className='licenseContent'>
            <div><span>User License</span><span>:</span></div>
            <div>
                <span onClick={()=>handleDecreaseCount("userLicense",purchaseItems?.userLicense)}>-</span>
                <span>{purchaseItems?.userLicense}</span>
                <span onClick={()=>handleIncreaseCount("userLicense",purchaseItems?.userLicense)}>+</span>
            </div>
        </div>
        <div className='licenseContent'>
            <div><span>Company License</span><span>:</span></div>
            <div>
                <span onClick={()=>handleDecreaseCount("companyLincense",purchaseItems?.companyLincense)}>-</span>
                <span>{purchaseItems?.companyLincense}</span>
                <span onClick={()=>handleIncreaseCount("companyLincense",purchaseItems?.companyLincense)}>+</span>
            </div>
        </div>
        </div>
        <div className='totalOuterDiv'>
          <div className='totalContent'>
            <span><span>{purchaseItems?.fiveJobsBundle} X 5 Jobs Bundle</span><span>=</span></span>
            <span>{purchaseItems?.fiveJobsBundle * bundlePrice}</span>
          </div>
          <div className='totalContent'>
            <span><span>{purchaseItems?.userLicense} X User License</span><span>=</span></span>
            <span>{purchaseItems?.userLicense * userLicensePrice}</span>
          </div>
          <div className='totalContent'>
            <span><span>{purchaseItems?.companyLincense} X Company License</span><span>=</span></span>
            <span>{purchaseItems?.companyLincense * companyLicensePrice}</span>
          </div>
        </div>
        <div className='gstDiv'>
          <span>+ 18% GST</span>
        </div>
        <div className='TotalPayableAmount gstDiv'>
          <span>Total Payable Amount</span>
          <span>{
          ((purchaseItems?.fiveJobsBundle * bundlePrice) + (purchaseItems?.userLicense * userLicensePrice) + (purchaseItems?.companyLincense * companyLicensePrice)) + 
          (((purchaseItems?.fiveJobsBundle * bundlePrice) + (purchaseItems?.userLicense * userLicensePrice) + (purchaseItems?.companyLincense * companyLicensePrice)) * 0.18)
          }</span>
        </div>
        {/* <div className='ptpDiv'>
          <span>Proceed To Payment</span>
        </div> */}
      </Modal>
    </div>
  )
}

export default LicenseModal