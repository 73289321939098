import React, { useCallback, useEffect, useState, useFocusEffect, useRef } from "react";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import "./Dashboard.css";
import { PortalUserRole, Url } from "../../EnvoirnmentSetup/APIs";
import moment from "moment";
import { json, useLocation, useNavigate } from "react-router-dom";
import dashboardempimg from '../../assets/dash_employees.png'
import { useSelectedCardContext, useSelectedSubMenuContext, useSelectedSubMenuJobContext } from "../../Context/Context";
import { ModuleCards } from "../../constants/ModuleCards";
import Modal from "react-modal";
import Lottie from "lottie-react";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import quikHireLogo from '../../assets/quikHireLogo.png'
import { companyValidation, field, onAllowAllCharacters, onlyAlphabets, onlyLinkedInProfileNonMandatory } from "../../Validation/Validation";
import { DocumentUploader } from "../../constants/constantFunctions";
import addCompanyLogo from '../../assets/companyLogoUpload.svg'
import { RxCrossCircled } from "react-icons/rx";
import qsubmit from '../../assets/QSubmit.svg'
import loader from "../../../src/assets/PANLoader.json";
import LicenseModal from "../../components/LicenseModal/LicenseModal";
import logOutLogo from '../../assets/logoutBtnNew.svg'
import LogOutLogo from '../../assets/logOutLogo.svg'


const Dashboard = () => {
  const [fromDate, setFromDate] = useState(new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000));
  const [addCompanyDetailsModal,setCompanyDetailsModal] = useState(false)
  const [lgOutPopup, setLgOutPopup] = useState(false)
  const [termsAndCndtnsModal,setTermsAndCndtnsModal] = useState(false)
  const [userId,setUserId] = useState()
  const [errPopup, setErrPopup] = useState(false)
  const [toDate, setToDate] = useState(new Date());
  const [fetching, setFetching] = useState(false);
  const [companySizeOptions,setCompanySizeOptions] = useState([])
  const [industryOptions,setIndustryOptions] = useState([])
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const [validTill,setValidTill] = useState('')
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [isPremium,setIsPremium] = useState(false)
  const [openPurchaseModal,setOpenPurchaseModal] = useState(false)
  const [fetchAgain,setFetchAgain] = useState(false)
  // const [purchaseItems,setPurchaseItems] = useState({
  //   fiveJobsBundle : 0,
  //   userLicense : 0,
  //   companyLincense : 0
  // })
  const [posterData, setPosterData] = useState({
    totalJobsPosted: 0,
    totalJobsDeleted: 0,
    totalJobsExpired: 0,
    totalJobsActive: 0,
    totalNotifications: 0,
    uniqueApplicantsViewed: 0,
    uniqueApplicantsNotified: 0,
    uniqueApplicantsApplied: 0,
    uniqueApplicantProfiles: 0,
    totalApplicantsContacted: 0,
    activeCompanies:0,
  })
  const [companyDetailsObj,setCompanyDetailsObj] = useState({companyName:field,
  companyLogo:null,
  companyDescription:field,
  companyAddress:field,
  companyUrl:field,
  linkedinUrl:field,
  facebookUrl:"",
  instagramUrl:"",
  companySize:{},
  industries:[{industryId:null}],
  establishmentDate:''
  })
  const [successPopup,setSuccessPopup] = useState(false)
  const [popupMsg, setPopupMsg] = useState("")
  const [errPopup1, setErrPopup1] = useState(false)
  const [logoImg,setLogoImg] = useState()
  const [KeywordsEntryInput, setKeywordsEntryInput] = useState("");
  const [KeywordsEntries, setKeywordsEntries] = useState([]);
  const [KeywordsSuggestions, setKeywordsSuggestions] = useState([]);
  const {subMenu, setSubMenu, subMenuColor, setSubMenuColor} = useSelectedSubMenuContext();
  const {subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs} = useSelectedSubMenuJobContext();
  const fileUploadRef = useRef()
  let usersName =  sessionStorage.getItem("userName")
  usersName = JSON.parse(usersName)
  const navigate = useNavigate();
  const propData = useLocation()
  const divRef = useRef(null);
  useEffect(() => {
    getDataByDates();
    setSelectedCard(ModuleCards.Dashboard);
    getUserDataname();
    getCompanySizeOptions();
    getIndustryOptions();
    // if(propData?.state?.showAddCompanyPopup==false && PortalUserRole=="ROLE_MANAGER"){
    // setCompanyDetailsModal(true)
    // }else{
    // setCompanyDetailsModal(false)
    // }
    if(KeywordsSuggestions?.length>0){
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [KeywordsSuggestions,fetchAgain])
   
  const getIndustryOptions = () => {
    let token = sessionStorage.getItem("token");
    try {
      fetch(Url.companyIndustry, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((resp) => resp.json())
        .then((res)=>{
          setIndustryOptions(res?.DATA)
        })
        .catch((e)=>{
          console.log(e);
        })
      }
      catch(e){
        console.log(e);
      }
  }
  const getCompanySizeOptions = () => {
    let token = sessionStorage.getItem("token");
    try {
      fetch(Url.compnaySize, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((resp) => resp.json())
        .then((res)=>{
          setCompanySizeOptions(res?.DATA)
        })
        .catch((e)=>{
          console.log(e);
        })
      }
      catch(e){
        console.log(e);
      }
  }
  const getUserDataname = () => {
    let token = sessionStorage.getItem("token");
    try {
      fetch(Url.getuser, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((resp) => resp.json())
        .then((res) => {
          setUserId(res?.DATA?.userId)
          setIsPremium(res?.DATA?.isPremium)
          setValidTill(moment(res?.DATA?.validTill).format('DD-MMM-YYYY'))
          if(!res?.DATA?.termsAndConditions){
            setTermsAndCndtnsModal(true)
          }
          else if(res?.DATA?.company.length>0){
            setCompanyDetailsModal(false)
          }
          else if(res?.DATA?.company.length==0 && PortalUserRole=="ROLE_MANAGER"){
           setCompanyDetailsModal(true)
          }
          else{
            setTermsAndCndtnsModal(false)
            setCompanyDetailsModal(false)
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
      maxWidth:window.screen.width>1023?'60%':'90%'
    },
  };
  const companyDetails = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: window.screen.width>1023? "60%" : "85%",
      maxWidth:window.screen.width>1023? "60%" : "85%",
      borderRadius: "25px",
      padding: "10px",
    },
  };

  const termsAndConditionsStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: window.screen.width>1023? "45%" : "82%",
      borderRadius: "25px",
      padding: window.screen.width>1023?"20px":"10px",
    },
  };

  const ApplictionActionloaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };
  const getDataByDates = () => {
    //   console.log("date",moment(fromDate).format("yyyy-MM-DD"))
    try {
      setFetching(true)
      let token = sessionStorage.getItem("token");

      fetch(Url?.getPosterDashboardDetails.replace("{fromDate}", moment(fromDate).format("yyyy-MM-DD")).replace("{toDate}", moment(toDate).format("yyyy-MM-DD")), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log({ res })
          if (res.SUCCESS) {
            setFetching(false)
            setPosterData({
              totalJobsPosted: res?.DATA?.totalJobsPosted,
              totalJobsDeleted: res?.DATA?.totalJobsDeleted,
              totalJobsExpired: res?.DATA?.totalJobsExpired,
              totalJobsActive: res?.DATA?.totalJobsActive,
              totalNotifications: res?.DATA?.totalNotifications,
              uniqueApplicantsViewed: res?.DATA?.uniqueApplicantProfiles,
              uniqueApplicantsNotified: res?.DATA?.uniqueApplicantsNotified,
              uniqueApplicantsApplied: res?.DATA?.uniqueApplicantsApplied,
              uniqueApplicantProfiles: res?.DATA?.uniqueApplicantsViewed,
              totalApplicantsContacted: res?.DATA?.totalApplicantsContacted,
              activeCompanies:res?.DATA?.activeCompanies
            })
          }
          else{
            setFetching(false)
          }
        });
    } catch (error) {
      console.log("Experience", error);
      setFetching(false)
    }
  };


  const [userData, setUserData] = useState({
    token: "",
  });
  
  useEffect(() => {
    const getUserData = () => {
      let token = sessionStorage.getItem("token");
      let userrole = sessionStorage.getItem("role");
      let username = sessionStorage.getItem("user_name");
      setUserData({ ...userData, token: token });
      if (token?.length > 0) {
        // navigate('/joblist')
        return;
      } else {
        navigate("/login");
      }
    };
    getUserData();
    setSubMenu(false)
    setSubMenuJob(false)
  }, []);

  const handleLogout = () => {
    sessionStorage.clear()
    navigate('/login')
  }
  
  const handleInputChange = (e,field) => {
    if(field=="Company Name"){
      setCompanyDetailsObj({...companyDetailsObj,companyName:companyValidation(field,e?.target?.value)})
    }else if(field=="Company Description"){
      setCompanyDetailsObj({...companyDetailsObj,companyDescription:companyValidation(field,e?.target?.value)})
    }else if(field=="Company Website Link"){
      setCompanyDetailsObj({...companyDetailsObj,companyUrl:companyValidation(field,e?.target?.value?.trim())})
    }else if(field=="Corporate Address"){
      setCompanyDetailsObj({...companyDetailsObj,companyAddress:companyValidation(field,e?.target?.value)})
    }
    else if(field=="companyLogo"){
      if(e.target.files[0]){
        const validLogoTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml']
        if(validLogoTypes?.includes(e.target.files[0]?.type)){
        setLogoImg(URL.createObjectURL(e.target.files[0]))
        setCompanyDetailsObj({...companyDetailsObj,companyLogo:e.target.files[0]})
        }else{
          setPopupMsg("Please select a valid Company Logo format.")
          setErrPopup(true)
        }
      }
    }
    else if(field=="LinkedIn URL"){
      setCompanyDetailsObj({...companyDetailsObj,linkedinUrl:onlyLinkedInProfileNonMandatory(field,e?.target?.value?.trim())})
    }
    else if(field=="facebookUrl"){
      setCompanyDetailsObj({...companyDetailsObj,facebookUrl:e?.target?.value?.trim()})
    }
    else if(field=="instagramUrl"){
      setCompanyDetailsObj({...companyDetailsObj,instagramUrl:e?.target?.value?.trim()})
    }
    else if(field == "Company Size"){
        setCompanyDetailsObj({...companyDetailsObj,companySize:companySizeOptions[e?.target?.selectedIndex -1]})
    }
    else if(field == "industry"){
     setKeywordsEntries([])
     setSelectedKeywords([])
     handleServiceGet(e?.target?.value)
     setCompanyDetailsObj({...companyDetailsObj,industries:[{industryId:e?.target?.value}]})
    }
  }

  const handleAddCompany = () => {
    setFetching(true)
    console.log("companydetails", companyDetailsObj)
    if(!!companyDetailsObj?.companyLogo){
    DocumentUploader(companyDetailsObj?.companyLogo, 'companyLogo', "company-logo").then((result) => {
      setFetching(false)
      console.log({result})
      sendCopanyDetails(result?.COMPANY_LOGO)
    })
    .catch((err)=>setFetching(false))
  }else{
      setFetching(false)
      sendCopanyDetails()
    }
  }

  const correctedUrl =(givenLink)=>{
    givenLink = givenLink?.toLowerCase()
    let data = new Promise((res, rej)=>{
      let updatedNewLink;
      if (givenLink?.startsWith("http://www.") || givenLink?.startsWith("https://www.") || givenLink?.startsWith("http://in.") || givenLink?.startsWith("https://in.")){
        updatedNewLink = givenLink
      }
      else if (givenLink?.startsWith("www.") || givenLink?.startsWith("in.")) {
        updatedNewLink = `https://${givenLink}`
      }
      else if (givenLink?.startsWith("https://") || givenLink?.startsWith("http://")) {
        let newLink = givenLink
        let colIndex = newLink.indexOf(":") + 3
        let lastIndex = newLink.length
        let strAfterRemove = newLink.slice(colIndex, lastIndex)
        updatedNewLink = `https://www.${strAfterRemove}`
      }
      else {
        updatedNewLink = `https://www.${givenLink}`
      }
      console.log("updatedNewLink",updatedNewLink);
      res(updatedNewLink)
    })
    return data;
}

  const sendCopanyDetails = async(companyLogoUrl=null) =>{
    setFetching(true)
    const CompUrl = !!companyDetailsObj?.companyUrl?.fieldValue ?  await correctedUrl(companyDetailsObj?.companyUrl?.fieldValue?.trim()) : ""
    const CompLinkedUrl =!!companyDetailsObj?.linkedinUrl?.fieldValue ? await correctedUrl(companyDetailsObj?.linkedinUrl?.fieldValue?.trim()) : ""
    const CompFBUrl =!!companyDetailsObj?.facebookUrl ?  await correctedUrl(companyDetailsObj?.facebookUrl?.trim()) : ""
    const CompInstaUrl =!!companyDetailsObj?.instagramUrl ? await correctedUrl(companyDetailsObj?.instagramUrl?.trim()) : ""

    const obj =  {
      "companyName": companyDetailsObj?.companyName?.fieldValue,
      "companyLogo": !!companyLogoUrl ? companyLogoUrl : null,
      "companyDescription": companyDetailsObj?.companyDescription?.fieldValue,
      "companyAddress": companyDetailsObj?.companyAddress?.fieldValue,
      "companyUrl": CompUrl,
      "linkedinUrl":CompLinkedUrl?.length>0? CompLinkedUrl?.toLowerCase() : null,
      "facebookUrl":CompFBUrl?.length>0? CompFBUrl?.toLowerCase() : null,
      "instagramUrl":CompInstaUrl?.length>0? CompInstaUrl?.toLowerCase() : null,
      "companySize":companyDetailsObj?.companySize,
      "establishmentDate":moment(companyDetailsObj?.establishmentDate).format('MMM-yyyy'),
      "industries":companyDetailsObj?.industries,
      "services":KeywordsEntries
  } 
  if(!( !!companyDetailsObj?.companyName?.fieldValue)){
    setFetching(false)
    setPopupMsg("Please Enter Company Name.")
    setCompanyDetailsModal(false)
    setErrPopup1(true)
 }else if(!(!!companyDetailsObj?.companyName?.isValidField)){
  setFetching(false)
  setPopupMsg("Please Enter Valid Company Name.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}else if(!(!!companyDetailsObj?.companyDescription?.fieldValue)){
  setFetching(false)
   setPopupMsg("Please Enter Company Description.")
   setCompanyDetailsModal(false)
   setErrPopup1(true)
 }else if(!( !!companyDetailsObj?.companyDescription?.isValidField)){
  setFetching(false)
  setPopupMsg("Please Enter Valid Company Description.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
 else if(!(!!companyDetailsObj?.companyUrl?.fieldValue)){
  setFetching(false)
   setPopupMsg("Please Enter Company Website Link.")
   setCompanyDetailsModal(false)
   setErrPopup1(true)
 } else if(!(!!companyDetailsObj?.companyUrl?.isValidField )){
  setFetching(false)
  setPopupMsg("Please Enter Valid Company Website Link.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
 else if(!( !!companyDetailsObj?.companyAddress?.fieldValue)){
  setFetching(false)
   setPopupMsg("Please Enter Company Address.")
   setCompanyDetailsModal(false)
   setErrPopup1(true)
 }else if(!(!!companyDetailsObj?.companyAddress?.isValidField )){
  setFetching(false)
  setPopupMsg("Please Enter Valid Company Address.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
//  else if(!(!!companyDetailsObj?.linkedinUrl?.isValidField)){
//   setPopupMsg("Please Enter Valid LinkedIn URL.")
//   setCompanyDetailsModal(false)
//   setErrPopup1(true)
// }
else if(!(!!companyDetailsObj?.industries[0]?.industryId)){
  setFetching(false)
  setPopupMsg("Please Select Industry.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
else if(!(!!companyDetailsObj?.establishmentDate)){
  setFetching(false)
  setPopupMsg("Please Select Company Established On.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
else if(!(!!companyDetailsObj?.companySize?.companySize)){
  setFetching(false)
  setPopupMsg("Please Select Company Size.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
else if(KeywordsEntries?.length == 0){
  setFetching(false)
  setPopupMsg("Please Select Services.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
 else{
  setFetching(true)
      try{
      fetch(Url?.AddCompany, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        body: JSON.stringify(obj),
      }).then((resp)=>resp.json())
      .then((res)=>{
        // console.log("Add company api response",res);
       if(res?.SUCCESS){
        setFetching(false)
        setPopupMsg("Company Details Added Successfully.")
        setFetchAgain(new Date())
        setCompanyDetailsModal(false)
        setSuccessPopup(true)
       }else{
        setFetching(false)
        setCompanyDetailsModal(false)
        setPopupMsg(res?.message)
        setErrPopup1(true)
       }
      })
      .catch((e)=>{
        console.log(e);
        setFetching(false)
      })
    }catch(e){
      console.log(e);
      setFetching(false)
    }}
  }

  const handleKeywordsChange = async (e) => {
    const selectedKeyWords = e.map((item) => ({
      value: typeof item.value === 'string' ? undefined : item.value,
      label: item.label
    }));

    const updatedKeywords = e.map((item) => ({
      serviceId: typeof item.value === 'string' ? undefined : item.value,
      serviceName: item.label
    }));

    setSelectedKeywords(selectedKeyWords);
    setKeywordsEntries(updatedKeywords);
  };
  const handleServiceGet = (industryId)=>{
    try {
      let token = sessionStorage.getItem("token");
      fetch(Url?.getServicesOptions.replace('{industryid}',industryId), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            let newData = res?.DATA?.map((i) => {
              return {
                ...i,
                label: i?.serviceName,
                value: i?.serviceId,
              };
            });
              setKeywordsSuggestions(newData);
          }
        });
    } catch (error) {
      console.log("keywords.error..........", error);
    }
  }

  const handleOpenPurchaseModal = () => {
    // setPurchaseItems({fiveJobsBundle : 0,userLicense : 0,companyLincense : 0})
    setOpenPurchaseModal(true)
  }
  const handleAcceptTermsAndConditions = () => {
    setFetching(true)
    let token = sessionStorage?.getItem('token')
      const obj1 = {
        "userId": userId,
        "termsAndConditions": true
      }  
    fetch(Url?.acceptTermsAndConditions,{
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body:JSON?.stringify(obj1)
      })
      .then((resp)=>resp?.json())
      .then((res)=>{
        if(res?.SUCCESS){
          setFetching(false)
          setTermsAndCndtnsModal(false)
          setFetchAgain(new Date())
        }
        else{
          setFetching(false)
          setPopupMsg(res?.message)
          setErrPopup(true)
        }
      })
      .catch((e)=>{
        setFetching(false)
        console.log(e);
      })
  }

  const handleMailTo = () => {
    // window.open('mailto:support@quikhire.ai', '_blank');
    window.location.href = 'mailto:support@quikhire.ai'
  }

  return (
    <div >

      {/* GO Back functionality */}
      {/* <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}><p onClick={() => { navigate(-1) }} style={{ padding: "20px" }}>Back to homepage</p></div> */}


      <div className="Dashboardtopimg">
        <div className="dashboardimgdiv" style={{position:"relative"}}>
          <div className={PortalUserRole=="ROLE_MANAGER"? "dashboardEmpContent mtopForPackageStatus":"dashboardEmpContent"}>
            <p>Hello, </p>
            <h1 className="dashboardUserName">{usersName}</h1>
            <p>{PortalUserRole == "ROLE_MANAGER"? "Company Admin":""}</p>
            {PortalUserRole == "ROLE_MANAGER" && <small className="packageStatus">{isPremium? 'Premium' : 'Freemium'}</small>}
            {PortalUserRole == "ROLE_MANAGER" && <small className="ValidTillStatus">Valid Till: {validTill}</small>}
          </div>

          <div className="dasboardempimg">
            <img src={dashboardempimg} alt="" />
          </div>

        </div>
        <div className="posterreportMiddleContainerWrapper" >
          <p className="dashboarddataselect">Please select the date range to filter the data.</p>
          <div className="dashboardDates">
            <div className="posterreportFromDate" >
              <p>From Date:</p>
              <ComponentAndScreenConstant.DatePickerComponent
                placeholderText="Enter here"
                label={""}
                // minDate={new Date()}
                maxDate={new Date()}
                isRequired={true}
                InputSty={{
                  backgroundColor: "#FFF",
                  textAlignVertical: "top",
                  // height: 100,
                  color: "#222B45",
                  fontSize: 14,
                }}
                selectedDate={new Date(fromDate)}
                onChange={(e) => {
                  console.log(e);
                  setFromDate(e);
                }}
                minYearsRequired={6}
              />
            </div>
            <div className="posterreportFromDate">
              <p >To Date:</p>
              <ComponentAndScreenConstant.DatePickerComponent
                placeholderText="Enter here"
                label={""}
                // minDate={new Date()}
                maxDate={new Date()}
                isRequired={true}
                InputSty={{
                  backgroundColor: "#FFF",
                  textAlignVertical: "top",
                  // height: 100,
                  color: "#222B45",
                  fontSize: 14,
                }}
                minDate={fromDate}
                selectedDate={new Date(toDate)}
                onChange={(e) => {
                  console.log(e);
                  setToDate(e);
                }}
                minYearsRequired={6}
              />
            </div>
          </div>
          <button
            className="posterSubmitReportDates"
            onClick={() => {
              getDataByDates();
            }}
          >
            <img src={qsubmit} alt="something went wrong"/>
          </button>
        </div>
      </div>
      <div className='posterreportUpperContainerWrapper'>
        <div className="innerDivForposterreportUpperContainerWrapper">
        {/* <div className='posterreportUpperContainerLeftWrapper'> */}
    {/*{  PortalUserRole == "ROLE_MANAGER" && <> <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{"0"}</p><p className='DashboardCountContainertitle'>Allocated User Licences</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{"0"}</p><p className='DashboardCountContainertitle spaceBetweenPara'><span>Remaining User Licences</span> <span className="addMore" onClick={handleOpenPurchaseModal}>+ Add More</span></p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{"0"}</p><p className='DashboardCountContainertitle'>Allocated Company Licences</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{"0"}</p><p className='DashboardCountContainertitle spaceBetweenPara'><span>Remaining Company Licences</span>{<span className="addMore" onClick={handleOpenPurchaseModal}>+ Add More</span>}</p>
        </div>
        </>
        }*/}
        
         
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.totalJobsPosted}</p><p className='DashboardCountContainertitle'>Jobs Posted</p>
        </div>
        {/*{PortalUserRole == "ROLE_MANAGER" && <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{"0"}</p><p className='DashboardCountContainertitle spaceBetweenPara'><span>Remaining Jobs Posts</span><span className="addMore" onClick={handleOpenPurchaseModal}>+ Add More</span></p>
        </div>}*/}
        <div className='posterreportentryName'>
        <p className='DashboardCountContainer'>{posterData?.totalJobsActive}</p><p className='DashboardCountContainertitle'>Current Active Jobs</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.totalJobsExpired}</p><p className='DashboardCountContainertitle'>Jobs Expired</p>
        </div>
        <div className='posterreportentryName'>
        <p className='DashboardCountContainer'>{posterData?.totalJobsDeleted}</p><p className='DashboardCountContainertitle'>Jobs Deleted</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.totalNotifications}</p><p className='DashboardCountContainertitle'>Notifications</p>
        </div>
        {/* </div> */}
        {/* <div className='posterreportUpperContainerRightWrapper'> */}
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.uniqueApplicantsViewed}</p><p className='DashboardCountContainertitle'>Applicants Viewed</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.uniqueApplicantsNotified}</p><p className='DashboardCountContainertitle'>Applicants Notified Through Job Posting</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.uniqueApplicantsApplied}</p><p className='DashboardCountContainertitle'>Candidates Applied</p>
        </div>
        {/* <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.uniqueApplicantProfiles}</p><p className='DashboardCountContainertitle'>Linkedin Profiles Views</p>
        </div> */}
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.totalApplicantsContacted}</p><p className='DashboardCountContainertitle'>Applicants Contacted</p>
        </div>
        {PortalUserRole == "ROLE_MANAGER" &&<div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.activeCompanies}</p><p className='DashboardCountContainertitle'>Total Registered Companies</p>
        </div>}
        {PortalUserRole == "ROLE_RECRUTER" &&<div className='posterreportentryName'style={{visibility:window.screen.width>1020 && "hidden",display:window.screen.width<1020 && "none"}}>
          <p className='DashboardCountContainer'>{posterData?.activeCompanies}</p><p className='DashboardCountContainertitle'>Total Registered Companies</p>
        </div>}
        {/* <div className='posterreportentryName' style={{visibility:PortalUserRole=="ROLE_MANAGER" && "hidden",display:PortalUserRole=="ROLE_RECRUTER" && "none"}}></div> */}
        {/* </div> */}
        </div>
      </div>
      <Modal
        isOpen={termsAndCndtnsModal}
        onRequestClose={()=>setTermsAndCndtnsModal(false)}
        ariaHideApp={false}
        style={termsAndConditionsStyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      > 
        <h2 className="tandcHeading">Terms and Conditions</h2>
        <p className="tandcPara tandcParaHeading">By using Quikhire.ai as a recruiter/hiring platform, you agree to the following terms and conditions:</p>
        <p className="tandcPara">You must provide accurate information during registration and maintain the confidentiality of your account. Job postings must be lawful, accurate, and non-discriminatory. You are responsible for complying with all applicable laws, including data protection regulations, and for conducting recruitment activities professionally and ethically. Quikhire.ai reserves the right to remove any job postings or terminate your account for any breach of these terms. Fees for services must be paid promptly. Quikhire.ai is not liable for any damages resulting from your use of the platform. These terms may be updated, and continued use of Quikhire.ai constitutes acceptance of any changes.</p>
        <p className="tandcPara tandcParaFooter">For any queries, contact: <span onClick={()=>handleMailTo()}>support@quikhire.ai</span>.</p>
        <div className="TandAbtns">
         <button onClick={()=>setLgOutPopup(true)}>Decline</button>
         <button onClick={handleAcceptTermsAndConditions}>I Agree</button>
        </div>
      </Modal>

      <Modal
        isOpen={addCompanyDetailsModal}
        onRequestClose={()=>setCompanyDetailsModal(false)}
        ariaHideApp={false}
        style={companyDetails}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      > 
        {false && <div className="CmpnyDetailsCrossIcon">x</div>}
       <div className="CmpnyDetailTitle">Add Company Details</div>
       <div className="CmpnyDetailsForm" ref={divRef}>
          <div className="CmpnyNameLogoDescDiv">
             <div className="CmpnyNameLogoDiv">
                <div className="cmpnyNameDiv">
                  <label>Company Name<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
                  <input value={companyDetailsObj?.companyName?.fieldValue} onChange={(e)=>handleInputChange(e,"Company Name")} type="text" placeholder="Enter your company name"/>
                  <small className='RegErrMsg'>{companyDetailsObj?.companyName?.errorField}</small>
                </div>
                <div className="cmpnyNameDiv" style={{position:"relative"}}>
                  <label>Company Logo</label>
                  <input ref={fileUploadRef} accept="image/jpeg, image/jpg, image/png, image/svg+xml" type="file" className="inputTypeFile" style={{opacity:0,width:105}} onChange={(e)=>handleInputChange(e,"companyLogo")}/>
                  <div style={{position:"absolute",top:25}}>
                    {!!companyDetailsObj?.companyLogo?<img src={logoImg} onClick={()=>fileUploadRef.current.click()} style={{maxWidth:100,height:25,cursor:"pointer",position:"relative"}} alt='Something went wrong'/>
                    : <img src={addCompanyLogo} style={{width:100,cursor:"pointer",position:"relative"}} alt='Something went wrong' onClick={()=>fileUploadRef.current.click()}/>}
                     {!!companyDetailsObj?.companyLogo?<RxCrossCircled onClick={()=>{setCompanyDetailsObj({...companyDetailsObj,companyLogo:null});setLogoImg(null)}} style={{color:"red",fontSize:12,position:"absolute",cursor:"pointer"}}/>:<></>}
                  </div>
                </div>
             </div>
             <div className="CmpnyDescDiv">
              <label>Company Description<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
               <textarea maxLength={1500} value={companyDetailsObj?.companyDescription.fieldValue} onChange={(e)=>handleInputChange(e,"Company Description")} placeholder="Enter your company description here..."></textarea>
               <small className='RegErrMsg'>{companyDetailsObj?.companyDescription?.errorField}</small>
             </div>
          </div>
          <div className="CmpnyLinksDiv">
            <div className="CmpnyLinkSubDiv">
              <label>Company Website Link<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
              <input type="text" value={companyDetailsObj?.companyUrl?.fieldValue} onChange={(e)=>handleInputChange(e,"Company Website Link")} placeholder="Enter your company website URL" />
              <small className='RegErrMsg'>{companyDetailsObj?.companyUrl?.errorField}</small>
            </div>
            <div className="CmpnyLinkSubDiv">
              <label>Corporate Address<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
              <input type="text" value={companyDetailsObj?.companyAddress?.fieldValue} onChange={(e)=>handleInputChange(e,"Corporate Address")} placeholder="Enter your corporate address" />
              <small className='RegErrMsg'>{companyDetailsObj?.companyAddress?.errorField}</small>
            </div>
          </div>
          <div className="CmpnyLinksDiv">
            <div className="CmpnyLinkSubDiv">
              <label>LinkedIn</label>
              <input type="text" value={companyDetailsObj?.linkedinUrl?.fieldValue} onChange={(e)=>handleInputChange(e,"LinkedIn URL")} placeholder="Enter your company's LinkedIn URL" />
              <small className='RegErrMsg'>{companyDetailsObj?.linkedinUrl?.errorField}</small>
            </div>
            <div className="CmpnyLinkSubDiv">
              <label>Facebook</label>
              <input type="text" value={companyDetailsObj?.facebookUrl} onChange={(e)=>handleInputChange(e,"facebookUrl")} placeholder="Enter your company's Facebook URL" />
            </div>
          </div>
          <div className="CmpnyLinksDiv">
            <div className="CmpnyLinkSubDiv">
              <label>Instagram</label>
              <input type="text" value={companyDetailsObj?.instagramUrl} onChange={(e)=>handleInputChange(e,"instagramUrl")} placeholder="Enter your company's Instagram URL" />
            </div>
            <div className="CmpnyLinkSubDiv">
            <label>Industry<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
            <select value={companyDetailsObj?.industries[0]?.industryId} onChange={(e)=>handleInputChange(e,"industry")}>
            <option style={{display:"none"}}>{"Select Industry"}</option>
              {
                industryOptions?.map((item,ind)=>{
                  return(
                    <option value={item?.industryId}>{item?.industryName}</option>
                  )
                })
              }
            </select>
            </div>
          </div>
          <div className="CmpnyLinksDiv">
            <div className="CmpnyLinkSubDiv">
            <ComponentAndScreenConstant.DatePickerComponent
                    placeholderText="Enter here"
                    label={"Established On"}
                    // minDate={new Date()}
                    maxDate={new Date()}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    dateFormat='MMM-yyyy'
                    showFullMonthYearPicker={true}
                    showMonthYearPicker={true}
                    ParentFieldWrapper={{width:"100%",marginTop:"2px"}}
                    customHeightRequired = "true"
                    selectedDate={!!companyDetailsObj?.establishmentDate ? new Date(companyDetailsObj?.establishmentDate) : ""}
                    onChange={(e) => {
                      console.log(e);
                      setCompanyDetailsObj({ ...companyDetailsObj, establishmentDate: e });
                    }}
                    // scrollBottom={scrollBottom}
                    // setScrollBottom={setScrollBottom}
                  />
            </div>
            <div className="CmpnyLinkSubDiv">
            <label>Company Size<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
            <select value={companyDetailsObj?.companySize?.companySizeId} onChange={(e)=>handleInputChange(e,"Company Size")}>
              <option style={{display:"none"}}>Select Size</option>
             {
              companySizeOptions?.map((item,ind)=>{
                 return(
                  <option value={item?.companySizeId}>{item?.companySize}</option>
                 )
              })
             }
            </select>
            </div>
          </div>
          <div className="CmpnyLinkSubDiv">
              <ComponentAndScreenConstant.SelectField
                labelTxt="Services"
                placeholderTxt={"Select Services"}
                isMultiValue={true}
                menuPlacement={"top"}
                // isDisabled={viewCompanyDetails}
                isCreatable={false}
                selectedValue={selectedKeywords}
                selectOptions={KeywordsSuggestions}
                isRequired={true}
                onChange={(e)=>{handleKeywordsChange(e)}}
              />
                  {/* <ComponentAndScreenConstant.DropdownWithSelectPicker
                    placeholderText={KeywordsEntries?.length>=1? "Add More...":"Enter company services"}
                    entryInput={KeywordsEntryInput}
                    entries={KeywordsEntries}
                    suggestions={KeywordsSuggestions}
                    setEntryInput={setKeywordsEntryInput}
                    setEntries={setKeywordsEntries}
                    setSuggestions={setKeywordsSuggestions}
                    handleEmailChange={handleKeywordsChange}
                    maxRoles={10}
                    multiline={true}
                    label={"Services"}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      shadowRadius: 4,
                      textAlignVertical: "top",
                      // height: 100,
                      minHeight: 100,
                      color: "#222B45",
                      borderRadius: 4,
                      fontSize: 14,
                    }}
                    CustomeEntryShow={false}
                    ParentFieldWrapper={{ height: "80px",width:"98.6%",padding:"4px",marginTop:"2px"}}
                    // customeStyleForSuggestionList={{top:"50px"}}
                    paddingLeftZeroRequired="true"
                  /> */}
                </div>
       </div>
       <div className="cmpnyDetailsBtns">
        <button onClick={()=>setLgOutPopup(true)}>Back</button>
        <button 
        onClick={handleAddCompany}
        >
          Submit</button>
       </div>
      </Modal>
      <LicenseModal 
        openModal={openPurchaseModal}
        closeModal={setOpenPurchaseModal}
        // purchaseItems={purchaseItems}
        // setPurchaseItems={setPurchaseItems}
      />
      <Modal
        isOpen={successPopup}
        onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={()=>setSuccessPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>setSuccessPopup(false)}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={errPopup1}
        onRequestClose={() => {setErrPopup1(false);setCompanyDetailsModal(true)}}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => {setErrPopup1(false);setCompanyDetailsModal(true)}} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div style={{marginTop:-20}}>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{padding:0}}>
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={() => { setErrPopup1(false);setCompanyDetailsModal(true)}}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={ApplictionActionloaderstyle}
        contentLabel="Example Modal"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
      <Modal
        isOpen={errPopup}
        onRequestClose={() => setErrPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setErrPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div style={{marginTop:-20}}>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{padding:0}}>
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>setErrPopup(false)}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={lgOutPopup}
        onRequestClose={() => setLgOutPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv" style={{ backgroundColor: "#FFF4F4" }}>
            <p onClick={() => setLgOutPopup(false)} className="delAccModalCrossPara" style={{ color: "#BC0000" }}>×</p>
          </div>
        </div>
        <div className='logoutLogoDiv'>
          <img src={LogOutLogo} />
        </div>
        <div className="delAccModalErrMsg" style={{ fontWeight: "normal",marginBottom:0}}>
        Would you like to logout?
        </div>
        <div className="okBtnModal" style={{ display: "flex", gap: "2%" }}>
          <button onClick={() => setLgOutPopup(false)} style={{ backgroundColor: "#ffff", border: "0.5px solid #007749", color: "#007749",cursor:"pointer" }}>Cancel</button>
          <button style={{ backgroundColor: "#BC0000",cursor:"pointer" }} onClick={handleLogout}>Logout</button>
        </div>
      </Modal>
    </div>
  );
};

export default Dashboard;
