import React, { useEffect, useState } from "react";
import "./ProfileTeammate.css";
import phoneCallImage from "../../assets/phoneCallImage.svg";
import emailImage from "../../assets/emailImage.svg";
import linkedInImage from "../../assets/linkedInImage.svg";
import editprofile from "../../assets/editprofile.svg";
import { useNavigate } from "react-router";
import { Url } from "../../EnvoirnmentSetup/APIs";
import Modal from "react-modal";
import { emailValidationForJobPoster, field, matchPassword, onPassword, onlyAlphabets, onlyLinkedInProfile, onlyNumber } from "../../Validation/Validation";
import { SHA256 } from 'crypto-js';
import Lottie from "lottie-react";
import loader from "../../assets/PANLoader.json";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import lock from '../../assets/lock.svg'
import employees from '../../assets/dash_employees.png'
import { useAuthContext, useSelectedCardContext } from "../../Context/Context";
import { ModuleCards } from "../../constants/ModuleCards";
import eyeicon from '../../assets/eyeIcon.svg'
import userProfile from '../../assets/userProfile.svg'
import hiddenEyeIcon from '../../assets/hiddenEyeIcon.svg'
import { useLocation } from "react-router-dom";
import whatsapp from "../../assets/whatsapp.svg";
import { ToastContainer, toast } from 'react-toastify';

const ProfileTeammate=()=> {
  const navigate = useNavigate();
  let propData = useLocation();

  const [UserDataDetails, setUserDataDetails] = useState({ });
  const [userId, setUserId] = useState("")
  const [userNameCurrent, setUserNameCurrent] = useState(field)
  const [userMobileNumberCurrent, setUserMobileNumberCurrent] = useState(field)
  const [userEmailCurrent, setUserEmailCurrent] = useState(field)
  const [userLinkedInCurrent, setUserLinkedInCurrent] = useState(field)
  const [selectedCountryCodeCurrent, setSelectedCountryCodeCurrent] = useState({})
  const [userName, setUserName] = useState(field)
  const [userMobileNumber, setUserMobileNumber] = useState(field)
  const [userMobileNumber1, setUserMobileNumber1] = useState(field)
  const [userEmail, setUserEmail] = useState(field)
  const [userEmail1, setUserEmail1] = useState(field)
  const [userLinkedIn, setUserLinkedIn] = useState(field)
  const [userLinkedIn1, setUserLinkedIn1] = useState(field)
  const [selectedCountryCode, setSelectedCountryCode] = useState({})
  const [currentPassword, setCurrentPassword] = useState(field)
  const [newPassword, setNewPassword] = useState(field)
  const [confirmPassword, setConfirmPassword] = useState(field)
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
  const [SuccessModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [error, setError] = useState("");
  const [editProfileModal, setEditProfileModal] = useState(false)
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const [fetching, setFetching] = useState(false);
  const [hidePwd,setHidePwd] = useState({pwdOne:false,pwdTwo:false,pwdThree:false})
  const [userName1,setUserName1] = useState(field)

  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const { userDetails, setUserDetails } = useAuthContext();

  const recruiterId = propData.state.recruiterId
  const userProfileloaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };

  const customStylesUpdateModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
      maxWidth: "55%"
    },
  };


  useEffect(() => {
    setCurrentPassword(field)
    setNewPassword(field)
    setConfirmPassword(field)
  }, [changePasswordModal])

  const [countryCode, setCountryCode] = useState([])
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "50%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const customStyles1 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "35%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const [editProfileForm, setEditProfileForm] = useState({ name: "", mno: "", cntryCodeId: "249", email: "", linkedIn: "" })


  useEffect(() => {
    try {
      fetch(Url.CountryCodes, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
        .then((resp) => resp.json())
        .then((res) => {
          console.log("country code api response", res);
          setCountryCode(res?.DATA)
        })
        .catch((e) => {
          console.log(e);
        })
    }
    catch (e) {
      console.log(e);
    }
  }, [])

  function openFailModal() {
    setFailmodalIsOpen(true);
  }

  function closeFailModal() {
    setFailmodalIsOpen(false);
  }

  function openSuccessModal() {
    setSuccessModalIsOpen(true);
  }

  function closeSuccessModal() {
    setSuccessModalIsOpen(false);
    window.location.reload(true)
  }


  console.log("UserDataDetailsUserDataDetails", UserDataDetails);

  const getUserCompleteDetails = () => {
    setFetching(true)
    let token = sessionStorage.getItem("token");
    try {
      fetch(Url.getuser+"/"+recruiterId, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,

        }
      })
        .then((resp) => resp.json())
        .then((res) => {
          setFetching(false)
          console.log("userData....", res);
          setUserEmail(emailValidationForJobPoster("Corporate Email", res?.DATA?.userName))
          setUserEmail1(emailValidationForJobPoster("Corporate Email", res?.DATA?.userName))
          setUserMobileNumber(onlyNumber(res?.DATA?.mobileNumber, 'Mobile Number'))
          setUserMobileNumber1(onlyNumber(res?.DATA?.mobileNumber, 'Mobile Number'))
          setUserName(onlyAlphabets('Full Name', res?.DATA?.fullName))
          setUserName1(onlyAlphabets('Full Name', res?.DATA?.fullName))
          setSelectedCountryCode(res?.DATA?.countryCode)
          setUserLinkedIn(onlyLinkedInProfile("LinkedIn Profile", res?.DATA?.linkedinProfile))
          setUserLinkedIn1(onlyLinkedInProfile("LinkedIn Profile", res?.DATA?.linkedinProfile))
          setUserId(res?.DATA?.userId)
          setUserEmailCurrent(emailValidationForJobPoster("Corporate Email", res?.DATA?.userName))
          setUserMobileNumberCurrent(onlyNumber(res?.DATA?.mobileNumber, 'Mobile Number'))
          setUserNameCurrent(onlyAlphabets('Full Name', res?.DATA?.fullName))
          setSelectedCountryCodeCurrent(res?.DATA?.countryCode)
          setUserLinkedInCurrent(onlyLinkedInProfile("LinkedIn Profile", res?.DATA?.linkedinProfile))
        })
        .catch((e) => {
          console.log(e);
        })
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getUserCompleteDetails()
  }, [])



  const updateProfile = () => {
    setFetching(true)
    let token = sessionStorage.getItem("token");

    const UserData = {
      userId: userId,
      messageAuditId: 0,
      messageOTP: 1111,
      emailAuditId: 0,
      emailOTP: 1111,
      fullName: userName?.fieldValue,
      mobileNumber: userMobileNumber?.fieldValue,
      userName: userEmail?.fieldValue,
      linkedinProfile: userLinkedIn?.fieldValue,
      countryCode: {
        countryCodeId: selectedCountryCode.countryCodeId,
      },
      designation: null,
      state: null,
      skill: null,
      experience: null
    };

    console.log({ UserData })
    if(userName.isValidField==false){
      setError("Invalid username.")
      setFailmodalIsOpen(true)
    }else if(userMobileNumber.isValidField==false){
      setError("Invalid mobile number.")
      setFailmodalIsOpen(true)
    }
    else if(userEmail.isValidField==false){
      setError("Invalid email.")
      setFailmodalIsOpen(true)
    }
    else if(userLinkedIn.isValidField==false){
      setError("Invalid LinkedIn profile url.")
      setFailmodalIsOpen(true)
    }
    else{
      try {
      fetch(Url.getuser, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(UserData)
      })
        .then((resp) => resp.json())
        .then((res) => {
          setFetching(false)
          console.log("userData....", res);
          if (res?.SUCCESS) {
            sessionStorage.setItem("userName", JSON.stringify(res?.DATA?.fullName))
            setUserDetails({
              ...userDetails, loggedInRole:res?.DATA?.roles[0]?.name
            })
            setsuccessMsg(`Profile updated successfully!`);
            getUserCompleteDetails();
            openSuccessModal();
            setEditProfileModal(false)
          } else {
            let originalString = res?.message;
            if(originalString.includes(':')){
              let resultString = originalString
              .split(":")
              .slice(1)
              .join(":")
              .trim();
            setError(resultString);
            }else{
              setError(originalString);
            }
          
            openFailModal();
          } setFetching(false)
        })
        .catch((e) => {
          console.log(e);
        })
    }
    catch (e) {
      console.log(e);
    }}
  }


  const updatePassword = () => {
    if(!newPassword?.isValidField){
      setError("Password must contain at least 1 lower case, 1 upper case, 1 numeric, special character, length maximum of 16 and minimum of 8")
      setFailmodalIsOpen(true)
    }else if(confirmPassword.isValidField==false){
      setError("Password did not match")
      setFailmodalIsOpen(true)
    }
    else{
    let token = sessionStorage.getItem("token");
    let currentPASS = SHA256(currentPassword?.fieldValue).toString();
    let newPASS = SHA256(newPassword?.fieldValue).toString();

    let PassObj = {
      oldPassword: currentPASS,
      password: newPASS
    }
    try {
      fetch(Url.changePassword, {
        method: "PATCH",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(PassObj)
      })
        .then((resp) => resp.json())
        .then((res) => {
          console.log("userData....", res);
          if (res?.SUCCESS) {
            setsuccessMsg(`Password updated successfully!`);
            openSuccessModal();
            setChangePasswordModal(false)
          } else {
            let originalString = res?.message;
            if(originalString.includes(':')){
              let resultString = originalString
              .split(":")
              .slice(1)
              .join(":")
              .trim();
            setError(resultString);
            }else{
              setError(originalString);
            }
            openFailModal();
          }
        })
        .catch((e) => {
          console.log(e);
        })
    }
    catch (e) {
      console.log(e);
    }
  }
  }
  const handleCloseEditModal = () => {
    setEditProfileModal(false);
    setUserName(userName1)
    setUserMobileNumber(userMobileNumber1)
    setUserLinkedIn(userLinkedIn1)
    setUserEmail(userEmail1)
  }

  const handleClick = (link, type) => {
    // Change the location to Google.com
    if (type == "mail") {
      link = `mailto:${link}`;
    } else if (type == "whatsapp") {
      link = "https://wa.me/" + link;
    }
    window.open(link);
  };

   const copyPath = async (copymobile) => {
     const textarea = document.createElement('textarea');
     textarea.value =  copymobile;
     textarea.style.top = 0;
     textarea.style.left = 0;
     textarea.style.width = '1px';
     textarea.style.height = '1px';
     textarea.style.opacity = 0;
     textarea.setAttribute('readonly', '');
 
     document.body.appendChild(textarea);
     textarea.select();
 
     try {

       document.execCommand('copy');
       console.log('Mobile number copied to clipboard');
       toast.success("Mobile number copied to clipboard");
     } catch (err) {
       console.error('Unable to copy Mobile number to clipboard', err);
       toast.error("Error copying Mobile number to clipboard")
     }
 
     document.body.removeChild(textarea);

  }; 

  return (
    <div className="userProfileWrapper">
      {/* GO Back functionality */}
      <div className="profileTitleDiv">
        {/* <p className="profileTitle">Profile</p> */}
        <p className="profileBackBtn">Recruiter Profile
        </p>
      </div>

      <div className="userHomePageImageWrapper">
        <div className="userNameWrapper">
          <div className="userName">{userNameCurrent?.fieldValue}</div>
        </div>

        <img src={employees} className="employeesImg" />
      </div>

      <div className="mainDisplyContainer">
        <div className="ContainerOne" onClick={()=>copyPath(selectedCountryCodeCurrent?.countryCode+userMobileNumberCurrent?.fieldValue)}>
          <div>
            <img src={phoneCallImage} className="displyImage" />
          </div>
          <div className="showText">+{selectedCountryCodeCurrent?.countryCode + " " + userMobileNumberCurrent?.fieldValue}</div>
        </div>
        <div className="ContainerTwo" onClick={()=>handleClick(userEmailCurrent?.fieldValue,"mail")}>
          <div>
            <img src={emailImage} className="displyImage" />
          </div>
          <div className="showText" >{userEmailCurrent?.fieldValue}</div>
        </div>
        <div className="ContainerThree"  onClick={()=>window.open(userLinkedInCurrent?.fieldValue)}>
          <div>
            <img src={linkedInImage} className="displyImage" />
          </div>
          <div className="showText" style={{marginTop:'5px'}}>{userLinkedInCurrent?.fieldValue}</div>
        </div>
        <div className="ContainerOne" onClick={()=>handleClick(selectedCountryCodeCurrent?.countryCode+userMobileNumberCurrent?.fieldValue,"whatsapp")}>
          <div>
            <img src={whatsapp} className="displyImage" />
          </div>
          <div className="showText">+{selectedCountryCodeCurrent?.countryCode + " " + userMobileNumberCurrent?.fieldValue}</div>
        </div>
      </div>

      {/* <div className="editAndcgPwdWrapper">
        <div onClick={() => { }} className="editProfileDiv">
          <img src={lock} className="editImage" onClick={() => setChangePasswordModal(true)} />
          <p className="editProfile" onClick={() => setChangePasswordModal(true)}>Change Password</p>
        </div>
        <div className="editProfileDiv">
          <img src={editprofile} className="editImage" onClick={() => setEditProfileModal(true)} />
          <p className="editProfile" onClick={() => setEditProfileModal(true)}>Edit Profile</p>
        </div>
      </div> */}

      <Modal
        isOpen={editProfileModal}
        onRequestClose={handleCloseEditModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="deleteModalWrapper">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={handleCloseEditModal}>
              ×
            </p>
          </div>
        </div>

        {/* <div className="editProFormOuterDiv">
          <div className="editProField">
            <label>Name:</label>
            <input type="text" value={userName?.fieldValue} onChange={(e) => setUserName(onlyAlphabets('Full Name', e.target.value))} />
            <p className="errorMsg">{userName?.errorField}</p>
          </div>
          <div className="editProField">
            <label>Mobile No:</label>
            <div className="editProMnoDiv">
              <select value={selectedCountryCode?.countryCode} onChange={(e) => {
                console.log("countyrcode", countryCode[e.target.selectedIndex])
                setSelectedCountryCode(countryCode[e.target.selectedIndex])
              }}>
                {
                  countryCode.map((item) => {
                    return (
                      <option >{item?.countryCode}</option>
                    )
                  })
                }
              </select>
              <input type="number" value={userMobileNumber?.fieldValue} onChange={(e) => setUserMobileNumber(onlyNumber(e.target.value, 'Mobile Number'))} />
            </div>
            <p className="errorMsg">{userMobileNumber?.errorField}</p>

          </div>
          <div className="editProField">
            <label>Email:</label>
            <input type="email" value={userEmail?.fieldValue} onChange={(e) => setUserEmail(emailValidationForJobPoster("Corporate Email", e.target.value))} />
            <p className="errorMsg">{userEmail?.errorField}</p>
          </div>
          <div className="editProField">
            <label>LinkedIn Profile:</label>
            <input type="text" value={userLinkedIn?.fieldValue} onChange={(e) => setUserLinkedIn(onlyLinkedInProfile("LinkedIn Profile", e.target.value))} />
            <p className="errorMsg">{userLinkedIn?.errorField}</p>
          </div>

          <div className="editProBtn">
            <button onClick={() => { updateProfile() }}>Update</button>
          </div>
        </div> */}

        <div className="editProImgDiv">
          <span>Edit Your Profile</span>
          <img src={userProfile}/>
        </div>

        <div className="userProfileFields">
           <div className="userProfileFieldRow">
             <div className="userProfileInputDiv">
               <label>Name<span style={{color:"#BC0000"}}>*</span></label>
               <input type="text" value={userName?.fieldValue} onChange={(e) => setUserName(onlyAlphabets('Full Name', e.target.value))} />
               <p className="errorMsg">{userName?.errorField}</p>
             </div>
             <div className="userProfileInputDiv" >
               <label>Mobile No<span style={{color:"#BC0000"}}>*</span></label>
               <div className="userProfileMnoDiv">

               <select value={selectedCountryCode?.countryCode} onChange={(e) => {
                console.log("countyrcode", countryCode[e.target.selectedIndex])
                setSelectedCountryCode(countryCode[e.target.selectedIndex])
              }}>
                {
                  countryCode.map((item) => {
                    return (
                      <option >{item?.countryCode}</option>
                    )
                  })
                }
              </select>
              <input type="number" value={userMobileNumber?.fieldValue} onChange={(e) => setUserMobileNumber(onlyNumber(e.target.value, 'Mobile Number'))} />
               </div>
               <p className="errorMsg">{userMobileNumber?.errorField}</p>
             </div>
           </div>

           <div className="userProfileFieldRow"style={{marginTop:"2%"}}>
           <div className="userProfileInputDiv">
               <label>Email Id<span style={{color:"#BC0000"}}>*</span></label>
               <input type="email" value={userEmail?.fieldValue} onChange={(e) => setUserEmail(emailValidationForJobPoster("Corporate Email", e.target.value))} />
            <p className="errorMsg">{userEmail?.errorField}</p>
             </div>

             <div className="userProfileInputDiv" >
               <label>LinkedIn<span style={{color:"#BC0000"}}>*</span></label>
               <input type="text" value={userLinkedIn?.fieldValue} onChange={(e) => setUserLinkedIn(onlyLinkedInProfile("LinkedIn Profile", e.target.value))} />
            <p className="errorMsg">{userLinkedIn?.errorField}</p>
             </div>
           </div>

           <div className="userProfileBtnDiv">
             <button className="userProBtnOne" onClick={handleCloseEditModal}>Cancel</button>
             <button className="userProBtnTwo"  onClick={() => { updateProfile() }}>Update</button>
           </div>
        </div>

      </Modal>


      <Modal
        isOpen={changePasswordModal}
        onRequestClose={() => setChangePasswordModal(false)}
        ariaHideApp={false}
        style={customStyles1}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="deleteModalWrapper">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={() => {
              setChangePasswordModal(false);
            }}>
              ×
            </p>
          </div>
        </div>

        <div className="editProFormOuterDiv">

          <h2>Change your password</h2>
          <div className="editProField">
            <label>Enter old password<span>*</span></label>
            <div className="editProfileFieldDiv">
              <input type={hidePwd.pwdOne==true?"text":"password"} placeholder="Old Password" value={currentPassword?.fieldValue} onChange={(e) => setCurrentPassword(onPassword(e.target.value))} />
              <img style={{marginTop:hidePwd?.pwdOne==true?"0px":"-1%"}} onClick={()=>setHidePwd({...hidePwd,pwdOne:!hidePwd.pwdOne})} src={hidePwd.pwdOne==true? eyeicon : hiddenEyeIcon} className="editProfileFieldImg" alt="" />
            </div>
            <p className="errorMsg">{currentPassword?.errorField}</p>

          </div>
          <div className="editProField">
            <label>Enter new password<span>*</span></label>
            <div className="editProfileFieldDiv">
            <input type={hidePwd.pwdTwo==true?"text":"password"} placeholder="New Password" value={newPassword?.fieldValue} onChange={(e) => setNewPassword(onPassword(e.target.value))} />
            <img style={{marginTop:hidePwd?.pwdTwo==true?"0px":"-1%"}} onClick={()=>setHidePwd({...hidePwd,pwdTwo:!hidePwd.pwdTwo})} src={hidePwd.pwdTwo==true? eyeicon : hiddenEyeIcon} className="editProfileFieldImg" alt="" />
            </div>
            <p className="errorMsg">{newPassword?.errorField}</p>
          </div>
          <div className="editProField">
            <label>Confirm Password<span>*</span></label>
            <div className="editProfileFieldDiv">
            <input type={hidePwd.pwdThree==true?"text":"password"} placeholder="Confirm Password" value={confirmPassword?.fieldValue} onChange={(e) => setConfirmPassword(matchPassword(e.target.value, newPassword?.fieldValue))} />
            <img style={{marginTop:hidePwd?.pwdThree==true?"0px":"-1%"}} onClick={()=>setHidePwd({...hidePwd,pwdThree:!hidePwd.pwdThree})} src={hidePwd.pwdThree==true? eyeicon : hiddenEyeIcon} className="editProfileFieldImg" alt="" />
            </div>
            <p className="errorMsg">{confirmPassword?.errorField}</p>
          </div>
          <div className="editProBtn">
          <button className="EditProfCancelBtn" onClick={() => setChangePasswordModal(false)}>Cancel</button>
            <button className="EdirProfChangeBtn" onClick={() => { updatePassword() }}>Change</button>
          </div>
        </div>

      </Modal>
      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={closeFailModal}
        ariaHideApp={false}
        style={customStylesUpdateModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={closeFailModal}>
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{error}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={closeFailModal}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={SuccessModalIsOpen}
        onRequestClose={closeSuccessModal}
        ariaHideApp={false}
        style={customStylesUpdateModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={closeSuccessModal} className="delAccModalCrossPara">
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{successMsg}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={closeSuccessModal}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={userProfileloaderstyle}
        contentLabel="Example Modal"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
      <ToastContainer autoClose={2000} />

    </div>
  );
}

export default ProfileTeammate;
