import React, { useEffect } from 'react'
import '../ReportsDashboard/reportsDashboard.css'
import { useProfileDropdownContext, useSelectedCardContext } from '../../../Context/Context';
import { useNavigate } from 'react-router-dom';
import breadCrumbArrow from '../../../assets/backArrowGray.svg'
import SummaryIcon from '../../../assets/summaryIcon.svg'
import individualReportIcon from '../../../assets/individualReportIcon.svg'
import jobPostingsIcon from '../../../assets/jobPostingsIcon.svg'
import activitySnapshot from '../../../assets/activitySnapshot.svg'
import { PortalUserRole } from '../../../EnvoirnmentSetup/APIs';
import nodatafound from '../../../assets/nodata.png'

const ReportsDashboard = () => {
  const navigate = useNavigate();
  const {dropDownVisible,setDropDownVisible} = useProfileDropdownContext()
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  useEffect(()=>{
   setSelectedCard(7)
  },[])

  return (
    <>{PortalUserRole == "ROLE_MANAGER"?
    <div>
       <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: ' 5px 13px 0',marginTop:0}}>
        <p className="breadCrumbDiv1p" style={{marginBottom:"5px"}}>Insights Dashboard</p>
        <ul className="breadCrumb">
        <li className="breadcrumbDiv2"><img className="breadcrumbDiv2Img" src={breadCrumbArrow} alt="" />
        <span onClick={()=>navigate(-1)} style={{color:"#007749"}}>Insights Dashboard</span>
        </li>
        </ul>
      </div>
      <div className='ReportsDashboardCardsDiv'>
      <div className='ReportCards' onClick={()=>{navigate('/posterDashboard/management/reportdashboard/job-posting-summary-across-company-report');setDropDownVisible(false)}}>
        <div>
        <img src={jobPostingsIcon}/>
        </div>
        <div>
       <span>Overall Job Postings</span>
       </div>
      </div>
      <div className='ReportCards' onClick={()=>{navigate('/posterDashboard/management/reportdashboard/job-posting-recruiter-summary-report');setDropDownVisible(false)}}>
        <div>
        <img src={SummaryIcon}/>
        </div>
        <div>
       <span>Summary of All Recruiter's Postings </span>
       </div>
      </div>
      <div className='ReportCards' onClick={()=>{navigate('/posterDashboard/management/reportdashboard/job-posting-per-recruiter');setDropDownVisible(false)}}>
        <div>
        <img src={individualReportIcon}/>
        </div>
        <div>
       <span>Individual Recruiter's Report</span>
       </div>
      </div>
      <div className='ReportCards' onClick={()=>{navigate('/posterDashboard/management/reportdashboard/total-usage-summary');setDropDownVisible(false)}}>
        <div>
        <img src={activitySnapshot}/>
        </div>
        <div>
       <span>Activity Snapshot</span>
       </div>
      </div>
      </div>
    </div>:
     <div style={{width:"100%",height:"85vh",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
      <img src={nodatafound} style={{width:"20%"}}/>
      <span>Oops! No Page Found</span>
     </div>
  }</>
  )
}

export default ReportsDashboard