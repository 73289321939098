import React, { useEffect, useRef, useState } from 'react'
import '../UserManagement/UserManagement.css'
import editIcon from '../../assets/edit.svg.svg'
import deleteIcon from '../../assets/delete.png'
import Modal from "react-modal";
import userProfile from '../../assets/userProfile.svg'
import searchIcon from '../../assets/searchLogo.svg'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant';
import { PortalUserRole, Url } from '../../EnvoirnmentSetup/APIs';
import { AddressValidation, companyValidation, emailValidationForJobPoster, field, onAllowAllCharacters, onlyAlphabets, onlyLinkedInProfileNonMandatory, onlyNumber } from '../../Validation/Validation';
import Lottie from "lottie-react";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import nodata from '../../assets/nodata.png'
import loader from "../../../src/assets/PANLoader.json";
import { useSelectedCardContext, useSelectedSubMenuContext, useSelectedSubMenuJobContext } from '../../Context/Context';
import deleteIcon2 from '../../assets/deleteIcon.svg'
import closeIcon from '../../assets/closeIcon.svg'
import { useNavigate } from 'react-router-dom';
import breadCrumbArrow from '../../assets/backArrowGray.svg'
import eyeIcon from '../../assets/eyeIconblue.svg'
import { DocumentUploader } from '../../constants/constantFunctions';
import addCompanyLogo from '../../assets/companyLogoUpload.svg'
import { RxCrossCircled } from "react-icons/rx";
import quikHireLogo from '../../assets/quikHireLogo.png'
import moment from 'moment';
import nodatafound from '../../assets/nodata.png'
import '../Dashboard/Dashboard.css'

const CompanyManagement = () => {
  const [popupMsg, setPopupMsg] = useState("")
  const [errPopup, setErrPopup] = useState(false)
  const [errPopup1, setErrPopup1] = useState(false)
  const [successPopup,setSuccessPopup] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [companyList2, setCompanyList2] = useState([])
  const [searchVal,setSearchVal] = useState("")
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [fetching, setFetching] = useState(false);
  const [companySizeOptions,setCompanySizeOptions] = useState([])
  const [industryOptions,setIndustryOptions] = useState([])
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const [deletemodalIsOpen,setDeletemodalIsOpen] = useState(false)
  const [fetchAgain,setFetchAgain] = useState(false)
  const [addCompanyDetailsModal,setCompanyDetailsModal] = useState(false)
  const [companyId,setCompanyId] = useState(null)
  const [companyUId,setCompanyUId] = useState(null)
  const [KeywordsEntryInput, setKeywordsEntryInput] = useState("");
  const [KeywordsEntries, setKeywordsEntries] = useState([]);
  const [KeywordsSuggestions, setKeywordsSuggestions] = useState([]);
  const [viewCompanyDetailsObj,setViewCompanyDetailsObj] = useState({
    industryObj:{}
  })
  const [managerLicenseInfo,setManagerLicenseInfo] = useState({})
  const [companyDetailsObj,setCompanyDetailsObj] = useState({
  companyName:field,
  companyLogo:null,
  companyDescription:field,
  companyAddress:field,
  companyUrl:field,
  linkedinUrl:field,
  facebookUrl:"",
  instagramUrl:"",
  companySize:{},
  industries:[{industryId:null}],
  establishmentDate:''
  })
  const [editCompany,setEditCompany] = useState(false)
  const [viewCompanyDetails,setViewCompanyDetails] = useState(false)
  const [logoImg,setLogoImg] = useState()
  const {subMenu, setSubMenu, subMenuColor, setSubMenuColor} = useSelectedSubMenuContext();
  const {subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs} = useSelectedSubMenuJobContext();

  const navigate = useNavigate()
  const fileUploadRef = useRef()
  const companyDetails = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: window.screen.width>1023? "60%" : "85%",
      maxWidth:window.screen.width>1023? "60%" : "85%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
      maxWidth:window.screen.width>1023?'60%':'90%'
    },
  };
  const customStyles1 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "35%",
      borderRadius: "25px",
      padding: "10px",
      maxWidth:window.screen.width>1023?'60%':'90%'
    },
  };
  const customStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "25px",
      minWidth: "35%",
      maxWidth:window.screen.width>1023?'60%':'90%'
    },
  };
  const loaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };
  const divRef = useRef(null);
  useEffect(() => {
    setSelectedCard(11)
    getCompanyList()
    getCompanySizeOptions();
    getIndustryOptions();
    setSubMenu(true)
    setSubMenuColor(1)
    setSubMenuJob(false)
    setSubMenuColorMyJobs(0)
    getManagerLicenseInfo()
  }, [fetchAgain])

  useEffect(()=>{
     setCompanyList(companyList2.filter((item)=>{
        return item?.companyName.toString().toLowerCase().includes(searchVal.toLowerCase())
     }))
    //  if(KeywordsSuggestions?.length>0){
    //   divRef.current.scrollTop = divRef.current.scrollHeight;
    // }
  },[searchVal,KeywordsSuggestions])
  
    
  const getIndustryOptions = () => {
    setFetching(true)
    let token = sessionStorage.getItem("token");
    try {
      fetch(Url.companyIndustry, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((resp) => resp.json())
        .then((res)=>{
          setFetching(false)
          setIndustryOptions(res?.DATA)
        })
        .catch((e)=>{
          setFetching(false)
          console.log(e);
        })
      }
      catch(e){
        setFetching(false)
        console.log(e);
      }
  }
  const getCompanySizeOptions = () => {
    setFetching(true)
    let token = sessionStorage.getItem("token");
    try {
      fetch(Url.compnaySize, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((resp) => resp.json())
        .then((res)=>{
          setFetching(false)
          setCompanySizeOptions(res?.DATA)
        })
        .catch((e)=>{
          setFetching(false)
          console.log(e);
        })
      }
      catch(e){
        setFetching(false)
        console.log(e);
      }
  }

  const getCompanyList = () => {
    setFetching(true)
    try{
    fetch(Url.getCompanyList,{
        method:"GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + sessionStorage.getItem('token'),
          }
    }).then((resp)=>resp.json())
    .then((res)=>{
        setFetching(false)
        if(res?.SUCCESS){
            setCompanyList(res?.DATA)
            setCompanyList2(res?.DATA)
        }
        else{
            setPopupMsg(res?.message)
            setErrPopup(true)
        }
    }).catch((e)=>{
      setFetching(false)
        console.log(e);
    })
    }
    catch(e){
      setFetching(false)
        console.log(e);
    }
  }
  const handleInputChange = (e,field) => {
    if(field=="Company Name"){
      setCompanyDetailsObj({...companyDetailsObj,companyName:companyValidation(field,e?.target?.value) })
    }else if(field=="Company Description"){
      setCompanyDetailsObj({...companyDetailsObj,companyDescription: companyValidation(field, e?.target?.value)})
    }else if(field=="Company Website Link"){
      setCompanyDetailsObj({...companyDetailsObj,companyUrl:companyValidation(field, e?.target?.value?.trim())})
    }else if(field=="Corporate Address"){
      setCompanyDetailsObj({...companyDetailsObj,companyAddress:companyValidation(field,e?.target?.value)})
    }
    else if(field=="companyLogo"){
      if(e.target.files[0]){
        const validLogoTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml']
        if(validLogoTypes?.includes(e.target.files[0]?.type)){
        setLogoImg(URL.createObjectURL(e.target.files[0]))
        setCompanyDetailsObj({...companyDetailsObj,companyLogo:e.target.files[0]})
        }else{
          setPopupMsg("Please select a valid Company Logo format.")
          setErrPopup(true)
        }
      }
    }
    else if(field=="LinkedIn URL"){
      setCompanyDetailsObj({...companyDetailsObj,linkedinUrl:onlyLinkedInProfileNonMandatory(field,e?.target?.value?.trim())})
    }else if(field=="facebookUrl"){
      setCompanyDetailsObj({...companyDetailsObj,facebookUrl:e?.target?.value?.trim()})
    }else if(field=="instagramUrl"){
      setCompanyDetailsObj({...companyDetailsObj,instagramUrl:e?.target?.value?.trim()})
    }
    else if(field == "Company Size"){
      if(!viewCompanyDetails){
      setCompanyDetailsObj({...companyDetailsObj,companySize:companySizeOptions[e.target.selectedIndex-1]})
      }
    }
    else if(field == "industry"){
      if(!viewCompanyDetails){
      setKeywordsEntries([])
      setSelectedKeywords([])
      setCompanyDetailsObj({...companyDetailsObj,industries:[{industryId:e?.target?.value}]})
      handleServiceGet(e?.target?.value)
      }
    }
  }
useEffect(()=>{
if(companyDetailsObj?.industries[0].industryId){
  handleServiceGet(companyDetailsObj?.industries[0].industryId)
}
},[companyDetailsObj])
  const handleLogout = () => {
    handleCloseCompanyDetailsModal()
    sessionStorage.clear()
    navigate('/login')
  }
  const handleServiceGet = (industryId)=>{
    try {
      let token = sessionStorage.getItem("token");
      fetch(Url?.getServicesOptions.replace('{industryid}',industryId), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            let newData = res?.DATA?.map((i) => {
              return {
                ...i,
                label: i?.serviceName,
                value: i?.serviceId,
              };
            });
              setKeywordsSuggestions(newData);
          }
        });
    } catch (error) {
      console.log("keywords.error..........", error);
    }
  }
  const handleUpdateCompany = () => {
    setFetching(true)
    if(!!companyDetailsObj?.companyLogo?.name){
      DocumentUploader(companyDetailsObj?.companyLogo, 'companyLogo', "company-logo")
      .then((result) => {
        setFetching(false)
        sendCopanyDetails1(result?.COMPANY_LOGO)
      })
      .catch((err)=>setFetching(false))
    }else{
      setFetching(false)
      sendCopanyDetails1()
    }
  }
  const sendCopanyDetails1 = async (companyLogoUrl=null) => {
    setFetching(true)
    const CompUrl =!!companyDetailsObj?.companyUrl?.fieldValue ?  await correctedUrl(companyDetailsObj?.companyUrl?.fieldValue?.trim()) : ""
    const CompLinkedUrl =!!companyDetailsObj?.linkedinUrl?.fieldValue ?  await correctedUrl(companyDetailsObj?.linkedinUrl?.fieldValue?.trim()) : ""
    const CompFBUrl =!!companyDetailsObj?.facebookUrl ? await correctedUrl(companyDetailsObj?.facebookUrl?.trim()) : ""
    const CompInstaUrl = !!companyDetailsObj?.instagramUrl ? await correctedUrl(companyDetailsObj?.instagramUrl?.trim()) : ""
    const obj = {
        "companyId": companyId,
        "companyName": companyDetailsObj?.companyName?.fieldValue,
        "companyLogo": companyLogoUrl ?? companyDetailsObj?.companyLogo,
        "companyDescription": companyDetailsObj?.companyDescription?.fieldValue,
        "companyAddress": companyDetailsObj?.companyAddress?.fieldValue,
        "companyUrl": CompUrl,
        "linkedinUrl":CompLinkedUrl?.length>0? CompLinkedUrl?.toLowerCase() : null,
        "facebookUrl":CompFBUrl?.length>0? CompFBUrl?.toLowerCase() : null,
        "instagramUrl":CompInstaUrl?.length>0? CompInstaUrl?.toLowerCase(): null,
        "companyUid":companyUId,
        "companySize":companyDetailsObj?.companySize,
        "establishmentDate":moment(companyDetailsObj?.establishmentDate).format('MMM-yyyy'),
        "industries":companyDetailsObj?.industries,
        "services":KeywordsEntries
    }

    if(!( !!companyDetailsObj?.companyName?.fieldValue)){
      setFetching(false)
      setPopupMsg("Please Enter Company Name.")
      setCompanyDetailsModal(false)
      setErrPopup1(true)
   }else if(!(!!companyDetailsObj?.companyName?.isValidField)){
    setFetching(false)
    setPopupMsg("Please Enter Valid Company Name.")
    setCompanyDetailsModal(false)
    setErrPopup1(true)
  }else if(!(!!companyDetailsObj?.companyDescription?.fieldValue)){
    setFetching(false)
     setPopupMsg("Please Enter Company Description.")
     setCompanyDetailsModal(false)
     setErrPopup1(true)
   }else if(!( !!companyDetailsObj?.companyDescription?.isValidField)){
    setFetching(false)
    setPopupMsg("Please Enter Valid Company Description.")
    setCompanyDetailsModal(false)
    setErrPopup1(true)
  }
   else if(!(!!companyDetailsObj?.companyUrl?.fieldValue)){
    setFetching(false)
     setPopupMsg("Please Enter Company Website Link.")
     setCompanyDetailsModal(false)
     setErrPopup1(true)
   } else if(!(!!companyDetailsObj?.companyUrl?.isValidField )){
    setFetching(false)
    setPopupMsg("Please Enter Valid Company Website Link.")
    setCompanyDetailsModal(false)
    setErrPopup1(true)
  }
   else if(!( !!companyDetailsObj?.companyAddress?.fieldValue)){
    setFetching(false)
     setPopupMsg("Please Enter Company Address.")
     setCompanyDetailsModal(false)
     setErrPopup1(true)
   }else if(!(!!companyDetailsObj?.companyAddress?.isValidField )){
    setFetching(false)
    setPopupMsg("Please Enter Valid Company Address.")
    setCompanyDetailsModal(false)
    setErrPopup1(true)
  }
  //  else if(!(!!companyDetailsObj?.linkedinUrl?.isValidField)){
  //   setPopupMsg("Please Enter Valid LinkedIn URL.")
  //   setCompanyDetailsModal(false)
  //   setErrPopup1(true)
  // }
  else if(!(!!companyDetailsObj?.industries[0]?.industryId)){
    setFetching(false)
    setPopupMsg("Please Select Industry.")
    setCompanyDetailsModal(false)
    setErrPopup1(true)
  }
  else if(!(!!companyDetailsObj?.establishmentDate)){
    setFetching(false)
    setPopupMsg("Please Select Company Established On.")
    setCompanyDetailsModal(false)
    setErrPopup1(true)
  }
  else if(!(!!companyDetailsObj?.companySize?.companySize)){
    setFetching(false)
    setPopupMsg("Please Select Company Size.")
    setCompanyDetailsModal(false)
    setErrPopup1(true)
  }
  else if(KeywordsEntries?.length == 0){
    setFetching(false)
    setPopupMsg("Please Select Services.")
    setCompanyDetailsModal(false)
    setErrPopup1(true)
  }
   else{
    setFetching(true)
    try{
        fetch(Url?.AddCompany, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          body: JSON.stringify(obj),
        }).then((resp)=>resp.json())
        .then((res)=>{
          // console.log("Add company api response",res);
        if(res.SUCCESS){
          setFetching(false)
            setPopupMsg("Company Details Updated Successfully.")
            setCompanyDetailsModal(false)
            setSuccessPopup(true)
            setKeywordsEntries([])
            setCompanyDetailsObj({companyName:"",companyLogo:"",companyDescription:"",companyAddress:"",companyUrl:"",linkedinUrl:"",facebookUrl:"",instagramUrl:"",companySize:{},industries:[{industryId:null}],establishmentDate: new Date()})
            setFetchAgain(!fetchAgain)
        }else{
          setFetching(false)
            setCompanyDetailsModal(false)
            setPopupMsg(res?.message)
            setErrPopup1(true)
        }
        })
        .catch((e)=>{
          setFetching(false)
          console.log(e);
        })
      }catch(e){
        setFetching(false)
        console.log(e);
      }
    }
  }
  const handleKeywordsChange = async (e) => {
    const selectedKeyWords = e.map((item) => ({
      value: typeof item.value === 'string' ? undefined : item.value,
      label: item.label
    }));

    const updatedKeywords = e.map((item) => ({
      serviceId: typeof item.value === 'string' ? undefined : item.value,
      serviceName: item.label
    }));

    setSelectedKeywords(selectedKeyWords);
    setKeywordsEntries(updatedKeywords);
  };


  const handleAddCompany = () => {
    setFetching(true)
    if(!!companyDetailsObj?.companyLogo){
    DocumentUploader(companyDetailsObj?.companyLogo, 'companyLogo', "company-logo").then((result) => {
      setFetching(false)
      console.log({result})
      sendCopanyDetails(result?.COMPANY_LOGO)
    })
    .catch((err)=>setFetching(false))
  }else{
      setFetching(false)
      sendCopanyDetails()
    }
  }

  const correctedUrl =(givenLink)=>{ 
    givenLink = givenLink?.toLowerCase()
    let data = new Promise((res, rej)=>{
      let updatedNewLink;
      if (givenLink?.startsWith("http://www.") || givenLink?.startsWith("https://www.") || givenLink?.startsWith("http://in.") || givenLink?.startsWith("https://in.")) {
        updatedNewLink = givenLink
      }
      else if (givenLink?.startsWith("www.") || givenLink?.startsWith("in.")) {
        updatedNewLink = `https://${givenLink}`
      }
      else if (givenLink?.startsWith("https://") || givenLink?.startsWith("http://")) {
        let newLink = givenLink
        let colIndex = newLink.indexOf(":") + 3
        let lastIndex = newLink.length
        let strAfterRemove = newLink.slice(colIndex, lastIndex)
        updatedNewLink = `https://www.${strAfterRemove}`
      }
      else {
        updatedNewLink = `https://www.${givenLink}`
      }
      console.log("updatedNewLink",updatedNewLink);
      res(updatedNewLink)
    })
    return data;
}

  const sendCopanyDetails = async (companyLogoUrl=null) =>{
    setFetching(true)
    const CompUrl =!!companyDetailsObj?.companyUrl?.fieldValue ?  await correctedUrl(companyDetailsObj?.companyUrl?.fieldValue?.trim()) : ""
    const CompLinkedUrl =!!companyDetailsObj?.linkedinUrl?.fieldValue ?  await correctedUrl(companyDetailsObj?.linkedinUrl?.fieldValue?.trim()) : ""
    const CompFBUrl =!!companyDetailsObj?.facebookUrl ? await correctedUrl(companyDetailsObj?.facebookUrl?.trim()) : ""
    const CompInstaUrl = !!companyDetailsObj?.instagramUrl ? await correctedUrl(companyDetailsObj?.instagramUrl?.trim()) : ""
    
    const obj =  {
      "companyName": companyDetailsObj?.companyName?.fieldValue,
      "companyLogo": !!companyLogoUrl ? companyLogoUrl : null,
      "companyDescription": companyDetailsObj?.companyDescription?.fieldValue,
      "companyAddress": companyDetailsObj?.companyAddress?.fieldValue,
      "companyUrl": CompUrl,
      "linkedinUrl":CompLinkedUrl?.length>0? CompLinkedUrl?.toLowerCase() : null,
      "facebookUrl":CompFBUrl?.length>0? CompFBUrl?.toLowerCase() : null,
      "instagramUrl":CompInstaUrl?.length>0? CompInstaUrl?.toLowerCase() : null,
      "companySize":companyDetailsObj?.companySize,
      "establishmentDate":moment(companyDetailsObj?.establishmentDate).format('MMM-yyyy'),
      "industries":companyDetailsObj?.industries,
      "services":KeywordsEntries
  } 
  if(!( !!companyDetailsObj?.companyName?.fieldValue)){
    setFetching(false)
    setPopupMsg("Please Enter Company Name.")
    setCompanyDetailsModal(false)
    setErrPopup1(true)
 }else if(!(!!companyDetailsObj?.companyName?.isValidField)){
  setFetching(false)
  setPopupMsg("Please Enter Valid Company Name.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}else if(!(!!companyDetailsObj?.companyDescription?.fieldValue)){
  setFetching(false)
   setPopupMsg("Please Enter Company Description.")
   setCompanyDetailsModal(false)
   setErrPopup1(true)
 }else if(!( !!companyDetailsObj?.companyDescription?.isValidField)){
  setFetching(false)
  setPopupMsg("Please Enter Valid Company Description.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
 else if(!(!!companyDetailsObj?.companyUrl?.fieldValue)){
  setFetching(false)
   setPopupMsg("Please Enter Company Website Link.")
   setCompanyDetailsModal(false)
   setErrPopup1(true)
 } else if(!(!!companyDetailsObj?.companyUrl?.isValidField )){
  setFetching(false)
  setPopupMsg("Please Enter Valid Company Website Link.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
 else if(!( !!companyDetailsObj?.companyAddress?.fieldValue)){
  setFetching(false)
   setPopupMsg("Please Enter Company Address.")
   setCompanyDetailsModal(false)
   setErrPopup1(true)
 }else if(!(!!companyDetailsObj?.companyAddress?.isValidField )){
  setFetching(false)
  setPopupMsg("Please Enter Valid Company Address.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
//  else if(!(!!companyDetailsObj?.linkedinUrl?.isValidField)){
//   setPopupMsg("Please Enter Valid LinkedIn URL.")
//   setCompanyDetailsModal(false)
//   setErrPopup1(true)
// }
else if(!(!!companyDetailsObj?.industries[0]?.industryId)){
  setFetching(false)
  setPopupMsg("Please Select Industry.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
else if(!(!!companyDetailsObj?.establishmentDate)){
  setFetching(false)
  setPopupMsg("Please Select Established Date.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
else if(!(!!companyDetailsObj?.companySize?.companySize)){
  setFetching(false)
  setPopupMsg("Please Select Company Size.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
else if(KeywordsEntries?.length == 0){
  setFetching(false)
  setPopupMsg("Please Select Services.")
  setCompanyDetailsModal(false)
  setErrPopup1(true)
}
 else{
  setFetching(true)
      try{
      fetch(Url?.AddCompany, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        body: JSON.stringify(obj),
      }).then((resp)=>resp.json())
      .then((res)=>{
        // console.log("Add company api response",res);
        if(res?.SUCCESS){
          setFetching(false)
          setPopupMsg("Company Details Added Successfully.")
          setCompanyDetailsModal(false)
          setSuccessPopup(true)
          setKeywordsEntries([])
          setCompanyDetailsObj({companyName:"",companyLogo:"",companyDescription:"",companyAddress:"",companyUrl:"",linkedinUrl:"",facebookUrl:"",instagramUrl:"", companySize:{},industries:[{industryId:null}],establishmentDate:new Date()})
          setFetchAgain(!fetchAgain)
        }else{
          setFetching(false)
          setCompanyDetailsModal(false)
          setPopupMsg(res?.message)
          setErrPopup1(true)
        }
      })
      .catch((e)=>{
        setFetching(false)
        console.log(e);
      })
    }catch(e){
      setFetching(false)
      console.log(e);
    }}
  }
  const handleEditCompany = (item) => {
    setCompanyId(item?.companyId)
    setCompanyUId(item?.companyUid)
    setEditCompany(true)
    setViewCompanyDetails(false)
    setLogoImg(item?.companyLogo)
    setCompanyDetailsObj({...companyDetailsObj,companyName:companyValidation("Company Name",item?.companyName),
                            companyLogo:item?.companyLogo,
                            companyDescription:companyValidation("Company Description",item?.companyDescription),
                            companyUrl:companyValidation("Company Website Link",item?.companyUrl),
                            companyAddress:companyValidation("Corporate Address",item?.companyAddress),
                            linkedinUrl:onlyLinkedInProfileNonMandatory("LinkedIn URL",item?.linkedinUrl),
                            facebookUrl:item?.facebookUrl,
                            instagramUrl:item?.instagramUrl,
                            industries:[{industryId:item?.industries[0]?.industryId}],
                            establishmentDate:!!item?.establishmentDate? new Date(item?.establishmentDate) : new Date(),
                            companySize: item?.companySize
                        })
                        const modifiedServices = item?.services?.map(i => {
                          return {...i, name: i.serviceName, Id:i.serviceId};
                        });
                        const selectedKeywords = item?.services?.map(i => {
                          return {...i, label: i.serviceName, value:i.serviceId};
                        });
                        setSelectedKeywords(selectedKeywords)
                        setKeywordsEntries(modifiedServices)
                        setCompanyDetailsModal(true)                    
  }
  const handleViewCompanyDetails = (item) => {
    setViewCompanyDetails(true)
    setEditCompany(false)
    setLogoImg(item?.companyLogo)
     setCompanyDetailsObj({...companyDetailsObj,companyName:onlyAlphabets("Company Name",item?.companyName),
        companyLogo:item?.companyLogo,
        companyDescription:onAllowAllCharacters("Company Description",item?.companyDescription),
        companyUrl:onAllowAllCharacters("Company Website Link",item?.companyUrl),
        companyAddress:onAllowAllCharacters("Corporate Address",item?.companyAddress),
        linkedinUrl:onlyLinkedInProfileNonMandatory("LinkedIn URL",item?.linkedinUrl),
        facebookUrl:item?.facebookUrl,
        instagramUrl:item?.instagramUrl,
        industries:[{industryId:item?.industries[0]?.industryId}],
        establishmentDate:!!item?.establishmentDate? new Date(item?.establishmentDate) : new Date(),
        companySize: item?.companySize
    })
    const modifiedServices = item?.services?.map(i => {
      return {...i, name: i.serviceName, Id:i.serviceId};
    });
    const selectedKeywords = item?.services?.map(i => {
      return {...i, label: i.serviceName, value:i.serviceId};
    });
    setSelectedKeywords(selectedKeywords)
    setKeywordsEntries(modifiedServices)
    setCompanyDetailsModal(true)
    let industryObjNew = industryOptions?.find((i,ind)=>{
     if(i?.industryId == item?.industries[0]?.industryId){
      return i
     }
    })
    setViewCompanyDetailsObj({...viewCompanyDetailsObj,industryObj:industryObjNew})
    // navigate('/posterDashboard/companyProfile',{state:{companyInfo:item}})
  }
  
  const handleCloseCompanyDetailsModal = () => {
    setCompanyDetailsObj({companyName:"",companyLogo:"",companyDescription:"",companyAddress:"",companyUrl:"",linkedinUrl:"",facebookUrl:"",instagramUrl:"",companySize:{},industries:[{industryId:null}],establishmentDate:new Date()})
    setKeywordsEntries([])
    setCompanyDetailsModal(false)
    setViewCompanyDetails(false)
    setKeywordsSuggestions([])
  }

  const handleAddCmpModal = () => {
    setCompanyDetailsObj({
      companyName:field,
      companyLogo:null,
      companyDescription:field,
      companyAddress:field,
      companyUrl:field,
      linkedinUrl:field,
      facebookUrl:"",
      instagramUrl:"",
      companySize:{},
      industries:[{industryId:null}],
      establishmentDate:''
    })
    setSelectedKeywords([])
    setKeywordsEntries([])
    setCompanyDetailsModal(true);
    setEditCompany(false)
    setKeywordsSuggestions([])
  }

  const getManagerLicenseInfo = () => {
    setFetching(true)
    fetch(Url.getManagerLicenseInfo, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization": "Bearer " + sessionStorage.getItem('token')
      }
    })
      .then((resp) => resp.json())
      .then((res) => {
       if(res?.SUCCESS){
        setFetching(false)
        setManagerLicenseInfo(res?.DATA)
       }else{
        setFetching(false)
        setPopupMsg(res?.message)
        setErrPopup1(true)
       }
      })
      .catch((e) => {
        setFetching(false)
        console.log(e);
      })
  }

  return (
    <>{PortalUserRole == "ROLE_MANAGER"?
    <div>
       {/* <div className="appcntRepoFixedContent">
        <div className="applcntRepoOuterDiv" style={{ marginTop: "5px" }}>
          <p style={{ color: '#007749', fontSize: '1.5rem', marginTop: '0px',marginBottom:"5px" }}>Poster's List</p>
        </div> 
       </div> */}
       <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: ' 5px 13px 0',marginTop:0}}>
        <p className="breadCrumbDiv1p" style={{marginBottom:"5px"}}>Companies</p>
        <ul className="breadCrumb">
        <li className="breadcrumbDiv2"><img className="breadcrumbDiv2Img" src={breadCrumbArrow} alt="" />
        <span onClick={()=>navigate(-1)} style={{color:"#007749"}}>Companies</span>
        </li>
        </ul>
      </div>

       <div className="appcntRepoSec2Div2 UMSearchAndAddUserWrapper" style={{justifyContent:"end",margin:"0 auto"}}>
          <div className="appcntRepoSearchSortDiv">
            <div className="appcntRepoSearchDiv UMsearchDiv" >

              <div className="appcntRepoImgDiv">
                <img src={searchIcon} />
              </div>

              <input
                type="text"
                placeholder="Search here..."
                className="appcntRepoSearchInput"
              // className="appcntRepoSearch"
              value={searchVal}
              onChange={(e)=>setSearchVal(e?.target?.value)}
              />
            </div>
          </div>
        {true && <button onClick={()=>{handleAddCmpModal()}}>Add Company</button>}
        </div>

        {/* <div className="regAndNonRegUsers userDetailsDivUM">
        <span className="regAndNonRegUsersSpan">
          <span>Total Companies</span>
          <span style={{color:'#202020',backgroundColor:"#D9D9D9"}}>{"0"}</span>
        </span>
        <span className="regAndNonRegUsersSpan">
          <span>Allocated Licenses</span>
          <span style={{color:"#05B221",backgroundColor:"#EAFFED"}}>{"0"}</span>
        </span>
        <span className="regAndNonRegUsersSpan">
          <span>Licenses Used</span>
          <span style={{color:"#FF6B00",backgroundColor:"#FFE0CB"}}>{managerLicenseInfo?.pendingUsers}</span>
        </span>
        <span className="regAndNonRegUsersSpan">
          <span style={{display:"flex",justifyContent:"space-between",paddingRight:"5px"}}>Licenses Left</span>
          <span style={{color:"#BC0000",backgroundColor:'#FFD5DC'}}>{0}</span>
        </span>
        <span className="regAndNonRegUsersSpan">
          <span>Inactive Companies</span>
          <span style={{color:"#007749",backgroundColor:"#E2EEFD"}}>{0}</span>
        </span>
        {PortalUserRole == "ROLE_MANAGER"? <span className="addMore" style={{width:"auto"}}>+ Add More</span>:<></>}
      </div> */}
         
      <div className="EditJobOuterDiv" style={{width:"97%",margin:"15px auto"}}>
          <div className="EditJobTableSubDiv">
          {companyList.length!=0?<table className="tableStyleMyJobs">
            <thead style={{position: "sticky", top:"0",height:"30px"}}>
                <tr
                  style={{backgroundColor: '#007749', color: '#fff' }}
                >
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderTopLeftRadius:"10px",width:"4%",borderRight:"0.5px solid gray",padding:window?.screen?.width<1020 && "0 3px"}}
                  >
                    <span>#</span>
                  </th>
                  
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ borderRight:" 0.5px solid gray", width:"25%"}}
                  >
                    <span>Company Name</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{borderRight:" 0.5px solid gray", width:"25%"}}
                  >
                    <span>Company Website Link</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderRight:" 0.5px solid gray", width:"25%"}}
                  >
                    <span>Company Career Link</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    className='paddingVertical'
                    style={{borderTopRightRadius:"10px",width:"12%",padding:window?.screen?.width<1020 && "0 10px"}}
                  >
                    Action
                  </th>
                </tr>
            </thead>
            <tbody style={{borderBottom:"0.5px solid gray"}}>
              {
                companyList?.map((item,ind)=>{
                    return(
                        <tr style={{backgroundColor:ind%2==0?"#C1E7D8":"#ffff"}}>
                            <td className='UMtbodyTd'><span style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{ind + 1}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan' style={{color:"blue",cursor:"pointer"}} onClick={()=>window.open(item?.companyProfile,'_blank')}>{item?.companyName}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan' style={{color:"blue",cursor:"pointer"}} onClick={()=>window.open(item?.companyUrl,'_blank')}>{item?.companyUrl}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan' style={{color:"blue",cursor:"pointer",textAlign:"center"}} onClick={()=>!!item?.companyCareerLink && window.open(item?.companyCareerLink,'_blank')}>{item?.companyCareerLink}</span></td>
                            <td>
                                <span className='UMtbodyActionSpan actionIconsNew'>
                                <img title="view"  src={eyeIcon} alt='' onClick={()=>handleViewCompanyDetails(item)}/>
                                <img title="edit"  src={editIcon} alt='' onClick={()=>handleEditCompany(item)}/>
                                {/* <img title='delete' style={{visibility:ind==0?"hidden":"block"}} onClick={()=>setDeletemodalIsOpen(true)} src={deleteIcon} alt=''/> */}
                                </span>
                            </td>
                        </tr>
                    )
                })
              }
              
            </tbody>
          </table>
          :
          <div className="noNotificationMsg" style={{height:"50vh"}}>
            <img
              src={nodata}
              className="nodataImage"
            />
            <p>No company details are found.</p>
          </div>
          }
          </div>
          </div>
          <Modal
        isOpen={addCompanyDetailsModal}
        onRequestClose={()=>{setCompanyDetailsModal(false)}}
        ariaHideApp={false}
        style={companyDetails}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="CmpnyDetailsCrossIcon" onClick={handleCloseCompanyDetailsModal}>x</div>
       {/* <div className="CmpnyDetailTitle">{editCompany?"Edit":viewCompanyDetails?"View":"Add"} Company Details</div> */}
       <div className="CmpnyDetailTitle">{viewCompanyDetails?"Company Profile":editCompany?"Edit Company Details":"Add Company Details"}</div>
       <div className="CmpnyDetailsForm" style={{height:viewCompanyDetails && "80vh"}} ref={divRef}>
          <div className="CmpnyNameLogoDescDiv">
             <div className="CmpnyNameLogoDiv">
                <div className="cmpnyNameDiv">
                  <label>Company Name<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
                  <input readOnly={viewCompanyDetails==true?true:false} value={companyDetailsObj?.companyName?.fieldValue} onChange={(e)=>handleInputChange(e,"Company Name")} type="text" placeholder="Enter your company name"/>
                  {viewCompanyDetails!=true && <small className='RegErrMsg'>{companyDetailsObj?.companyName?.errorField}</small>}
                </div>
                <div className="cmpnyNameDiv" style={{position:"relative"}}>
                  <label>Company Logo</label>
                  <input ref={fileUploadRef} disabled={viewCompanyDetails==true} accept="image/jpeg, image/jpg, image/png, image/svg+xml" type="file" className="inputTypeFile" style={{opacity:0,width:105}} onChange={(e)=>handleInputChange(e,"companyLogo")}/>
                  <div style={{position:"absolute",top:25}}>
                    {!!companyDetailsObj?.companyLogo?<img src={logoImg} onClick={()=>{if(!viewCompanyDetails){fileUploadRef.current.click()}}} style={{maxWidth:100,height:25,cursor:"pointer",position:"relative"}} alt='Something went wrong'/>
                    : <img src={addCompanyLogo} style={{width:100,cursor:"pointer",position:"relative"}} alt='Something went wrong' onClick={()=>{if(!viewCompanyDetails){fileUploadRef.current.click()}}}/>}
                     {!!companyDetailsObj?.companyLogo && viewCompanyDetails==false?<RxCrossCircled onClick={()=>{setCompanyDetailsObj({...companyDetailsObj,companyLogo:null});setLogoImg(null)}} style={{color:"red",fontSize:12,position:"absolute",cursor:"pointer"}}/>:<></>}
                  </div>
                </div>
             </div>
             <div className="CmpnyDescDiv">
              <label>Company Description<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
               <textarea maxLength={1500} readOnly={viewCompanyDetails==true?true:false}  onChange={(e)=>handleInputChange(e,"Company Description")} placeholder="Enter your company description here...">{companyDetailsObj?.companyDescription?.fieldValue}</textarea>
               {viewCompanyDetails!=true && <small className='RegErrMsg'>{companyDetailsObj?.companyDescription?.errorField}</small>}
             </div>
          </div>
          <div className="CmpnyLinksDiv">
            <div className="CmpnyLinkSubDiv">
              <label>Company Website Link<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
              <input type="text" readOnly={viewCompanyDetails==true?true:false} value={companyDetailsObj?.companyUrl.fieldValue} onChange={(e)=>handleInputChange(e,"Company Website Link")} placeholder="Enter your company website URL" />
             {viewCompanyDetails!=true &&  <small className='RegErrMsg'>{companyDetailsObj?.companyUrl?.errorField}</small>}
            </div>
            <div className="CmpnyLinkSubDiv">
              <label>Corporate Address<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
              <input type="text" readOnly={viewCompanyDetails==true?true:false} value={companyDetailsObj?.companyAddress?.fieldValue} onChange={(e)=>handleInputChange(e,"Corporate Address")} placeholder="Enter your corporate address" />
              {viewCompanyDetails!=true && <small className='RegErrMsg'>{companyDetailsObj?.companyAddress?.errorField}</small>}
            </div>
          </div>
          <div className="CmpnyLinksDiv">
            <div className="CmpnyLinkSubDiv">
              <label>LinkedIn</label>
              <input type="text" readOnly={viewCompanyDetails==true?true:false} value={companyDetailsObj?.linkedinUrl?.fieldValue} onChange={(e)=>handleInputChange(e,"LinkedIn URL")} placeholder="Enter your company's LinkedIn URL" />
              {!viewCompanyDetails&& <small className='RegErrMsg'>{companyDetailsObj?.linkedinUrl?.errorField}</small>}
            </div>
            <div className="CmpnyLinkSubDiv">
              <label>Facebook</label>
              <input type="text" readOnly={viewCompanyDetails==true?true:false} value={companyDetailsObj?.facebookUrl} onChange={(e)=>handleInputChange(e,"facebookUrl")} placeholder="Enter your company's Facebook URL" />
            </div>
          </div>
          <div className="CmpnyLinksDiv">
            <div className="CmpnyLinkSubDiv">
              <label>Instagram</label>
              <input type="text" readOnly={viewCompanyDetails==true?true:false} value={companyDetailsObj?.instagramUrl} onChange={(e)=>handleInputChange(e,"instagramUrl")} placeholder="Enter your company's Instagram URL" />
            </div>
            <div className="CmpnyLinkSubDiv">
            <label>Industry<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
            { !viewCompanyDetails?<select value={companyDetailsObj?.industries[0]?.industryId} onChange={(e)=>handleInputChange(e,"industry")}>
            <option style={{display:"none"}}>{"Select Industry"}</option>
              {
                industryOptions?.map((item,ind)=>{
                  return(
                    <option value={item?.industryId}>{item?.industryName}</option>
                  )
                })
              }
            </select>: 
              <input type="text" readOnly={viewCompanyDetails} value={viewCompanyDetailsObj?.industryObj?.industryName}/>
            }
            </div>
          </div>
          <div className="CmpnyLinksDiv">
            <div className="CmpnyLinkSubDiv">
              <ComponentAndScreenConstant.DatePickerComponent
                    placeholderText="Enter here"
                    label={"Established On"}
                    // minDate={new Date()}
                    maxDate={new Date()}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    dateFormat='MMM-yyyy'
                    showFullMonthYearPicker={true}
                    showMonthYearPicker={true}
                    ParentFieldWrapper={{width:"100%",marginTop:"2px"}}
                    customHeightRequired = "true"
                    selectedDate={!!companyDetailsObj?.establishmentDate ? new Date(companyDetailsObj?.establishmentDate) : ""}
                    onChange={(e) => {
                      if(!viewCompanyDetails){
                      setCompanyDetailsObj({ ...companyDetailsObj, establishmentDate: e });
                      }
                    }}
                    disabled={viewCompanyDetails}
                    // scrollBottom={scrollBottom}
                    // setScrollBottom={setScrollBottom}
                  />
            </div>
            <div className="CmpnyLinkSubDiv">
            <label>Company Size<span style={{color:"#BC0000",fontWeight: "bold"}}>*</span></label>
            { !viewCompanyDetails?<select value={companyDetailsObj?.companySize?.companySizeId}onChange={(e)=>handleInputChange(e,"Company Size")}>
            <option style={{display:"none"}}>Select Size</option>
             {
              companySizeOptions?.map((item,ind)=>{
                 return(
                  <option value={item?.companySizeId}>{item?.companySize}</option>
                 )
              })
             }
            </select>:
            <input readOnly={viewCompanyDetails} value={companyDetailsObj?.companySize?.companySize}/>
          }
            </div>
          </div>
          <div className="CmpnyLinkSubDiv">
              <ComponentAndScreenConstant.SelectField
                labelTxt="Services"
                placeholderTxt={"Select Services"}
                isMultiValue={true}
                menuPlacement={"top"}
                isDisabled={viewCompanyDetails}
                isCreatable={false}
                selectedValue={selectedKeywords}
                selectOptions={KeywordsSuggestions}
                isRequired={true}
                onChange={(e)=>{handleKeywordsChange(e)}}
              />
                  {/* <ComponentAndScreenConstant.DropdownWithSelectPicker
                    placeholderText= {KeywordsEntries?.length>=1? "Add More...":"Enter company services"}
                    entryInput={KeywordsEntryInput}
                    entries={KeywordsEntries}
                    suggestions={KeywordsSuggestions}
                    setEntryInput={setKeywordsEntryInput}
                    setEntries={setKeywordsEntries}
                    setSuggestions={setKeywordsSuggestions}
                    handleEmailChange={handleKeywordsChange}
                    maxRoles={10}
                    multiline={true}
                    label={"Services"}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      shadowRadius: 4,
                      textAlignVertical: "top",
                      // height: 100,
                      minHeight: 100,
                      color: "#222B45",
                      borderRadius: 4,
                      fontSize: 14,
                    }}
                    CustomeEntryShow={false}
                    lineHeight={true}
                    ParentFieldWrapper={{ height: "80px",width:"98.6%",padding:"4px",marginTop:"2px"}}
                    // customeStyleForSuggestionList={{top:"50px"}}
                    paddingLeftZeroRequired="true"
                    disabled={viewCompanyDetails}
                    industryId={companyDetailsObj?.industries[0]?.industryId}
                    selectIndustry={true}
                  /> */}
                </div>
       </div>
       <div className="cmpnyDetailsBtns">
        {viewCompanyDetails!=true && <button style={{color:"#FFFF",backgroundColor:"#007749"}} onClick={editCompany? handleUpdateCompany:handleAddCompany}>{editCompany?"Update":"Submit"}</button>}
       </div>
      </Modal>
          <Modal
        isOpen={errPopup}
        onRequestClose={() => setErrPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setErrPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div style={{marginTop:-20}}>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{padding:0}}>
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>setErrPopup(false)}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={errPopup1}
        onRequestClose={() => setErrPopup1(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setErrPopup1(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div style={{marginTop:-20}}>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{padding:0}}>
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={() => { setErrPopup1(false);setCompanyDetailsModal(true)}}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={successPopup}
        onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setSuccessPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>setSuccessPopup(false)}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderstyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>

      <Modal
        isOpen={deletemodalIsOpen}
        onRequestClose={()=>setDeletemodalIsOpen(false)}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="deleteModalWrapper" >
          <div className="delAccModalCrossDiv">
          <img src={closeIcon} alt="Close Icon" style={{cursor:"pointer"}} onClick={()=>setDeletemodalIsOpen(false) } />          </div>
        </div>
        <div className="delAccModalErrMsg" style={{margin:0,padding:0}}>
          <img src={deleteIcon2} alt="Delete Icon"/>
          <p>Would you like to delete this company?</p>
          <div>
            <button
              style={{
                padding: "7px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #007749",
                background: 'none',
                color: '#007749'
              }}
              onClick={()=>setDeletemodalIsOpen(false)}
            >
              Cancel
            </button>
            <button
              style={{
                padding: "7px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "none",
                color: "#FFF",
                backgroundColor: "#BC0000",
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>:
    <div style={{width:"100%",height:"85vh",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
      <img src={nodatafound} style={{width:"20%"}}/>
      <span>Oops! No Page Found</span>
     </div>
    }</>
  )
}

export default CompanyManagement