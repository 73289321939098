import React from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
const SelectField = ({
    labelTxt,
    selectOptions,
    inputWrapperStyle,
    errMsg,
    onChange,
    inputId,
    menuPlacement,
    isMultiValue,
    isDisabled,
    placeholderTxt,
    selectedValue,
    isCreatable,
    isRequired = false
}) => {
    const customStyles = (isDisabled) => ({
        placeholder: (provided) => ({
            ...provided,
            color: '#807D7D !important',
            fontSize: '13px',

        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#a7a7a7 !important',
            minHeight: '44px',
            width: window.screen.width>1023?'100%':'98%',
            margin: "5px 0",
            textAlign: 'left',
            boxShadow: null,
            borderRadius: '0px'
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            minHeight: '44px',
            padding: '0 6px',
            textAlign: 'left',
            fontSize: '13px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'left',
        }),
        indicatorSeparator: state => ({
            display: 'none',
            borderLeft: '1px solid'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            display: isDisabled ? 'none' : 'flex',
            height: '44px',
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: "#E1F5ED",
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#000000",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            display: isDisabled ? 'none' : 'flex',
            color: "#01774A",
            ':hover': {
                backgroundColor: "#01774A",
                color: '#fff',
            },
            transition: 'all 0.2s ease',
        }),
    });
    const skillsCustomStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: '#807D7D !important',
            fontSize: '13px',

        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#a7a7a7 !important',
            minHeight: '60px',
            width: '100%',
            margin: "5px 0",
            textAlign: 'left',
            boxShadow: null,
            borderRadius: '0px'
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            minHeight: '60px',
            padding: '0 6px',
            textAlign: 'left',
            fontSize: '13px'
        }),
        singleValue: (provided) => ({  
            ...provided,
            color: '#000 !important'
          }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            textAlign: 'left',
        }),
        indicatorSeparator: state => ({
            display: 'none',
            borderLeft: '1px solid'
        }),
        // indicatorsContainer: (provided, state) => ({
        //     ...provided,
        //     height: '44px',
        // }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: "#E1F5ED",
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#000000",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#01774A",
            ':hover': {
                backgroundColor: "#01774A",
                color: '#fff',
            },
            transition: 'all 0.2s ease',
        }),
    };
    return (
        <div className={inputWrapperStyle} style={{ display: "flex", flexDirection: "column",pointerEvents : isDisabled && 'none' }}>
            {!!labelTxt && <label className='inputLable' htmlFor={inputId}>{labelTxt}{isRequired && <small style={{ color: "#BC0000" }}>*</small>}</label>}
            {isCreatable ? <CreatableSelect noOptionsMessage={({inputValue}) => !inputValue ? "Please Select Industry" : "No results found"} menuPlacement={menuPlacement} placeholder={placeholderTxt} value={selectedValue} onChange={onChange} isMulti={isMultiValue} styles={labelTxt == "Skills" ? skillsCustomStyles :customStyles} options={selectOptions} 
                maxMenuHeight={200}/> : <Select menuPlacement={menuPlacement} noOptionsMessage={({ inputValue }) => 
                    !selectOptions || selectOptions.length === 0 ? 
                    "Please Select Industry" : 
                    (!inputValue ? "No results found" : "No results found")
                } placeholder={placeholderTxt} value={selectedValue} onChange={onChange} isMulti={isMultiValue} styles={customStyles(isDisabled)}                options={selectOptions} 
                maxMenuHeight={200}/>}
            {!!errMsg && <small className='errorMessageShow'>{errMsg}</small>}
        </div>
    )
}

export default SelectField