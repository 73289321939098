import { Url } from "../EnvoirnmentSetup/APIs";
import moment from "moment-timezone";

  export const getPremiumMemberUserInfo =  async() =>{

    let membershipStatus = new Promise(async(resolve, reject)=>{
      try {
        let token = sessionStorage.getItem("token");
        fetch(Url.MemberShipStatus, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          })
            .then((resp) => resp.json())
            .then((res) => {
                if (res.SUCCESS) {
                    if(!!res.DATA){
                      resolve(res.DATA)
                    }else{
                      resolve(res.DATA)
                 alert("Your premium membership is expired")
                    }
                  }
            })
            .catch((e) => {
              console.log(e);
            });
      } catch (error) {
        console.log('Membership', error);
      }
    })
    return membershipStatus
  }
  

  export const DocumentUploader =  (file, appendVariable, folderName) =>{
    console.log({file, appendVariable, folderName})
    let fileUrl = new Promise((resolve, reject)=>{
      let token = sessionStorage.getItem("token");
      if (file) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token,);
        
        const formdata = new FormData();
        formdata.append(appendVariable, file);
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow"
        };
        
        fetch(Url.UploadDocumentToBucket.replace("{folderName}",folderName), requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log("appendVariable",result)
            if(result?.SUCCESS){
            resolve(result?.DATA)
            }else{
              reject()
            }
          })
          .catch((error) => console.error(error));
      }
    })
    return fileUrl
   
  }

  export const ExcelDownload = async (api,fileName) => {
    try{
      const response = await fetch(api, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Authorization': "Bearer " + sessionStorage.getItem("token"),
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Get the response as a Blob
      const blob = await response.blob();
      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);
      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName? fileName + '.xlsx' : 'untitled.xlsx'; // Set the desired file name
      document.body.appendChild(a);
      a.click();
      // Clean up
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed', error);
    }
    }

    export const modifyTimezoneString=(timeString, formate)=>{
      //formate eg. "MM/DD/YYYY HH:mm A" etc
      const parsedDateTime = moment(timeString);
      const newTimeString = parsedDateTime.local();
      let str=newTimeString.format(formate);
      return str;
  }

// export const injectScript = (src, id, onLoad) => {
//   if (document.getElementById(id)) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.src = src;
//   script.async = true;
//   script.id = id;
//   script.onload = onLoad;
//   document.head.appendChild(script);
// };

// export const injectInlineScript = (content, id) => {
//   if (document.getElementById(id)) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.textContent = content;
//   script.id = id;
//   document.head.appendChild(script);
// };
