import React, { useEffect, useRef, useState } from "react";
import "./CreateJob.css";
import Modal from "react-modal";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { Url } from "../../EnvoirnmentSetup/APIs";
import {
  field,
  noValidationRequired,
  onAllowAllCharacters,
  onlyAlphabets,
  onlyNumber,
  onlyNumberAndNonMandatory,
  onlySalaryEntry,
} from "../../Validation/Validation";
import moment, { months } from "moment";
import Lottie from "lottie-react";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import DatePickerComponent from "../../components/DatePickerComponent/DatePickerComponent";
import { useLocation, useNavigate } from 'react-router-dom';
import quikHireLogo from '../../assets/quikHireLogo.png'
import postJob from '../../assets/PostJob.svg'
import UpdateJob from '../../assets/UpdateJob.svg'
import { modifyTimezoneString } from "../../constants/constantFunctions";
import loader from "../../assets/PANLoader.json";
import emojiRegex from 'emoji-regex';

const CreateJob = () => {
  const navigate = useNavigate();
  const regex = emojiRegex();
  const [RoleEntryInput, setRoleEntryInput] = useState("");
  const [RoleEntries, setRoleEntries] = useState([]);
  const [RoleSuggestions, setRoleSuggestions] = useState([]);
  const [companyInputChange,setCompanyInputChange] = useState("")
  const [CompanyEntries, setCompanyEntries] = useState([]);
  const [CompanySuggestions, setCompanySuggestions] = useState([]);
  const [KeywordsEntryInput, setKeywordsEntryInput] = useState("");
  const [KeywordsEntries, setKeywordsEntries] = useState([]);
  const [KeywordsSuggestions, setKeywordsSuggestions] = useState([]);
  const [stateEntryInput, setstateEntryInput] = useState("");
  const [fetching, setFetching] = useState(false);
  const [stateEntries, setstateEntries] = useState([
    // { stateId: 1, stateName: "Remote", name: "Remote", Id: 1 },
  ]);
  const [stateSuggestions, setstateSuggestions] = useState([]);
  const [companyList, setCompanyList] = useState([])
  const [basicInfo, setBasicInfo] = useState({
    jobname: field,
    country: field,
    location: field,
    years: field,
    description: field,
    salary: field,
    filldate: moment().add(7, "days").format("YYYY-MM-DD"),
    dateText: moment().add(7, "days").format("DD-MMM-YYYY"),
    noOfOpenings: {...field,fieldValue:'1'}
  });
  const [Experience, setExperience] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState({});
  const [idToDelete, setIdToDelete] = useState("");
  const [deletemodalIsOpen, setDeletemodalIsOpen] = useState(false);
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
  const [SuccessModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [error, setError] = useState("");
  const [rstSelector, setrstSelector]=useState()
  const [jobId, setJobId] = useState("")
  const [jobTypeOptions,setJobTypeOptions] = useState([])
  const [workModeOptions,setWorkModeOptions] = useState([])
  const [selectedWorkMode,setSelectedWorkMode] = useState({})
  const [selectedCompany,setSelectedCompany] = useState({})
  const [selectedJobType,setSelectedJobType] = useState({})
  const [scrollBottom,setScrollBottom] = useState("")
  const scrollForm = useRef(null)
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
      maxWidth:window.screen.width>1023?'60%':'90%'
    },
  };
  const loaderStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };
  let propData = useLocation();
  const flow = propData.state ? propData.state.flow : 'create';
 
  console.log({flow})
 
  useEffect(() => {
    GetExperiences();
    getJobTypes();
    getWorkModeOptions();
    getCompanyList();
    if(flow=='edit'){
      const jobId = propData.jobId ?? propData.state.jobId ;
      console.log({jobId})
      getJobData(jobId)
      setJobId(jobId)
    }
  }, []);
   
  useEffect(()=>{
    if(!!scrollBottom ){
       scrollForm.current.scrollTop = scrollForm.current.scrollHeight;
    }
  },[scrollBottom])   
  const getJobTypes = () => {
    try{
       fetch(Url.createJobType,{
        method:"GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token")
        },
       }).then((resp)=>resp.json())
       .then((res)=>{
        console.log("Create Job type response");
        if(res?.SUCCESS==true){
          setJobTypeOptions(res?.DATA)
        }else{
          setError(res?.message)
          setFailmodalIsOpen(true)
        }
       })
       .catch((e)=>{
        console.log(e);
       })
    }
    catch(e){
      console.log(e);
    }
  }
  const getCompanyList = () => {
    try{
    fetch(Url.getCompanyList,{
        method:"GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + sessionStorage.getItem('token'),
          }
    }).then((resp)=>resp.json())
    .then((res)=>{
        console.log("company list api response",res);
        if(res?.SUCCESS){
            setCompanyList(res?.DATA)
        }
        else{
            setError(res?.message)
            setFailmodalIsOpen(true)
        }
    }).catch((e)=>{
        console.log(e);
    })
    }
    catch(e){
        console.log(e);
    }
  }
  const getWorkModeOptions = () => {
    try{
      fetch(Url.createJobWorkMode,{
       method:"GET",
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
         Authorization: "Bearer " + sessionStorage.getItem("token")
       },
      }).then((resp)=>resp.json())
      .then((res)=>{
       console.log("Create Job Work Mode response");
       if(res?.SUCCESS==true){
        setWorkModeOptions(res?.DATA)
      }else{
        setError(res?.message)
        setFailmodalIsOpen(true)
      }
      })
      .catch((e)=>{
       console.log(e);
      })
   }
   catch(e){
     console.log(e);
   }
  } 
  function openDeleteModal() {
    setDeletemodalIsOpen(true);
  }

  function closeDeleteModal() {
    setDeletemodalIsOpen(false);
  }

  function openFailModal() {
    setFailmodalIsOpen(true);
  }

  function closeFailModal() {
    setFailmodalIsOpen(false);
  }

  function openSuccessModal() {
    setSuccessModalIsOpen(true);
  }

  function closeSuccessModal() {
    setSuccessModalIsOpen(false);
    if (flow == 'edit') {
      navigate(-1)
    }
  }



  const handleDeleteUser = async () => {
    setFetching(true)
    closeDeleteModal();
    let token = sessionStorage.getItem("token");
    fetch(Url?.deleteJob.replace("{jobId}", jobId), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res?.SUCCESS) {
          setsuccessMsg(`This job is deleted successfully!`);
          openSuccessModal();
          // navigate(-1)
          setFetching(false)
        } else {
          let originalString = res?.message;
          if(originalString.includes(':')){
            let resultString = originalString
            .split(":")
            .slice(1)
            .join(":")
            ?.trim();
          setError(resultString);
          }else{
            setError(originalString);
          }
          setFetching(false)
          openFailModal();
        }
      });
  };

  const handleCompanyChange = async (e) => {
    let text = e.target.value.replace(regex, '');
    setCompanyInputChange(text)
    if (text?.trim().length >= 2) {
      try {
        let token = sessionStorage.getItem("token");
        fetch(Url?.searchComapny + companyInputChange, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((res)=>{
            if (res.SUCCESS) {
              let newData = res?.DATA?.map((i) => {
                return {
                  ...i,
                  name: i?.companyName,
                  Id: i?.companyId,
                };
              });
              console.log("countrys...........", newData);
              if (text.length > 0) {
                setCompanySuggestions(newData);
              } else {
                setCompanySuggestions([]);
              }
            }

          })
        }catch(e){
          
        }
  }
}

  const handleRoleChange = async (e) => {
    console.log(e.target.value);
    let text = e.target.value.replace(regex, '');
    setRoleEntryInput(text);
    if (text?.trim().length >= 2) {
      try {
        let token = sessionStorage.getItem("token");
        fetch(Url?.searchDesignation.replace("{string}", text?.trim()), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            console.log({ res });
            if (res.SUCCESS) {
              let newData = res?.DATA?.map((i) => {
                return {
                  ...i,
                  name: i?.designationName,
                  Id: i?.designationId,
                };
              });
              console.log("countrys...........", newData);
              if (text.length > 0) {
                setRoleSuggestions(newData);
              } else {
                setRoleSuggestions([]);
              }
            }
          });
      } catch (error) {
        console.log("countrys.error..........", error);
      }
    } else {
      setRoleSuggestions([]);
    }
  };

  const handleKeywordsChange = async (e) => {
    let text = e.target.value.replace(regex, '');
    setKeywordsEntryInput(text);
    if (text?.trim().length >= 2) {
      try {
        let token = sessionStorage.getItem("token");
        fetch(Url?.searchKeywords.replace("{string}", text?.trim()), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            console.log({ res });
            if (res.SUCCESS) {
              let newData = res?.DATA?.map((i) => {
                return {
                  ...i,
                  name: i?.name,
                  Id: i?.skillId,
                };
              });
              console.log("keywords...........", newData);
              if (text.length > 0) {
                setKeywordsSuggestions(newData);
              } else {
                setKeywordsSuggestions([]);
              }
            }
          });
      } catch (error) {
        console.log("keywords.error..........", error);
      }
    } else {
      setKeywordsSuggestions([]);
    }
  };

  const handleStateChange = async (e) => {
    let text = e.target.value.replace(regex, '');
    setstateEntryInput(text);
    if (text?.trim().length >= 2) {
      try {
        let token = sessionStorage.getItem("token");
        fetch(
          Url?.searchLocation
            .replace("{state}", text?.trim())
            .replace("{countrycodeId}", 249),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
          .then((response) => response.json())
          .then((res) => {
            console.log({ res });
            if (res.SUCCESS) {
              let newData = res?.DATA?.map((i) => {
                return {
                  ...i,
                  name: i?.stateName,
                  Id: i?.stateId,
                };
              });
              console.log("countrys...........", newData);
              if (text.length > 0) {
                setstateSuggestions(newData);
              } else {
                setstateSuggestions([]);
              }
            }
          });
      } catch (error) {
        console.log("countrys.error..........", error);
      }
    } else {
      setstateSuggestions([]);
    }
  };

  const onTextChange = (fields, val) => {
    //console.log(fields)
    switch (fields) {
      case "Job Name":
        setBasicInfo({
          ...basicInfo,
          jobname: onlyAlphabets(fields, val),
        });
        break;
      case "Country":
        setBasicInfo({
          ...basicInfo,
          country: onAllowAllCharacters(fields, val),
        });
        break;
      case "Salary":
        if(/^[^a-zA-Z]*$/.test(val)){
          setBasicInfo({
          ...basicInfo,
          salary: onlySalaryEntry(fields, val),
        });}
        break;
      case "Job location":
        setBasicInfo({
          ...basicInfo,
          location: onAllowAllCharacters(fields, val),
        });
        break;
      case "Years":
        setBasicInfo({
          ...basicInfo,
          years: onlyNumber(val, 2, fields),
        });
        break;
      case "Job Description":
        const filteredValue = val.replace(regex, '');
        setBasicInfo({
          ...basicInfo,
          description: onAllowAllCharacters(fields, filteredValue),
        });
        break;
      case "No. of openings":
        if(val?.length<4){
          setBasicInfo({
            ...basicInfo,
            noOfOpenings: onlyNumberAndNonMandatory(val,fields, 3),
          });
        }
        break;
    }
  };

  const GetExperiences = async () => {
    try {
      // let token = sessionStorage.getItem("token");
      fetch(Url?.GetExperienceList, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log({ res });
          if (res.SUCCESS) {
            // let ExpList =  res?.DATA?.map((k)=>{
            //   return {...k, name:k?.experience}
            // })
            setExperience(res?.DATA);
          }
        });
    } catch (error) {
      console.log("Experience", error);
    }
  };


  const ValidateField = () => {
    const atLeastOneCharacterRegex = /(?=.*[a-zA-Z])/;
    let keywords = KeywordsEntries?.filter((i)=>{
      return i?.name?.trim() == ""
    })
    if (RoleEntries?.length < 1) {
      setError("Please select a Job Title.");
      openFailModal();
      return false;
    } else if (!atLeastOneCharacterRegex.test(RoleEntries[0]?.name)) {
      setError("Job Title must contain atleast one alphabet.");
      openFailModal();
      return false;
    } else if (KeywordsEntries?.length < 1) {
      setError("Please select Keywords.");
      openFailModal();
      return false;
    } else if(keywords?.length>0){
      setError("Empty entries not allowed in Keywords.");
      openFailModal();
      return false;
    }
    else if(!(!!selectedCompany?.companyId)){
      setError("Please select Company Name.");
      openFailModal();
      return false;
    }
    else if(!(!!basicInfo?.salary?.isValidField)){
    setError("Please Enter Valid Salary (LPA).")
    openFailModal();
    return false;
  }
    else if (stateEntries?.length < 1) {
      setError("Please select the Work Location.");
      openFailModal();
      return false;
    }else if(!(!!selectedWorkMode?.workModeId)){
      setError("Work Mode is required.")
       setFailmodalIsOpen(true)
       return false;
    }
    else if(!(!!selectedJobType?.jobTypeId)){
      setError("Job Type is required.")
      setFailmodalIsOpen(true)
      return false;
    }
    else if (!selectedExperience?.experienceId) {
      setError("Years of Experience is required.");
      openFailModal();
      return false;
    }else if (!!basicInfo?.noOfOpenings?.errorField) {
      setError("Invalid input of No. of Openings.");
      openFailModal();
      return false;
    } else if (basicInfo.filldate == '') {
      setError("Fill by is required.");
      openFailModal();
      return false;
    } else if (
      basicInfo.description.fieldValue === '' ||
      basicInfo.description.isValidField === false ||
      basicInfo.description.fieldValue.trim().length < 250
    ) {
      setError("Job Description with minimum 250 characters is required.");
      openFailModal();
      return false;
    } else if (basicInfo.description.fieldValue?.length < 10) {
      setError("Job Description should contain at least 10 characters.");
      openFailModal();
      return false;
    } else if (basicInfo.description.fieldValue?.length > 10000) {
      setError("Job Description should not exceed 10000 characters.");
      openFailModal();
      return false;
    }
    return true;
  };

  const SubmitJobPost = ()=>{
    if(ValidateField()){
      let token = sessionStorage.getItem("token");

      const stateData = stateEntries?.map(i => {
        return {
          stateId: i?.Id,
          stateName: i?.name,
        };
      });
  
      const skillData = KeywordsEntries?.map(i => {
        return {
          skillId: i?.Id,
          name: i?.name,
        };
      });
  
      // const CompanyName = CompanyEntries?.map(i => {
      //   return  {
      //     companyId: i?.Id,
      //     companyName: i?.name
      //   };
      // });
      const designationData = RoleEntries?.map(i => {
        return {
          designationId: i?.Id,
          designationName: i?.name,
        };
      });
  
      let jobPost = {
        salary: !!basicInfo?.salary?.fieldValue ? basicInfo?.salary?.fieldValue : null,
        isSalaryVisible: true,
        tillDate:  moment(basicInfo?.filldate).format("YYYY-MM-DD") ,
        description: basicInfo?.description?.fieldValue,
        designation: designationData,
        state: stateData,
        experience: {
          experienceId: selectedExperience?.experienceId,
        },
        skill: skillData,
        messageAuditId:0,
        isWeb: true,
        company:{
          companyId:selectedCompany?.companyId ? selectedCompany?.companyId : null,
          companyName:selectedCompany?.companyName,
        },
        jobType:{
          jobTypeId:selectedJobType?.jobTypeId
        },
        workMode:{
          workModeId:selectedWorkMode?.workModeId
        },
        noOfOpenings:!!basicInfo?.noOfOpenings?.fieldValue? basicInfo?.noOfOpenings?.fieldValue : "1"
      }
      if (flow == 'edit') {
        jobPost = {...jobPost, jobId: jobId};
      }
      console.log("jobPost$$$",jobPost)
      setFetching(true)
        fetch(Url.getAllJobs, {
          method:flow == 'edit' ? 'PUT' : 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Authorization: "Bearer " + token,
          },
          body: JSON.stringify(jobPost)
      }).then(response => response.json())
          .then(res => {
            console.log("Job posted updated",res)
            if(res?.SUCCESS){
            setsuccessMsg(!(!!res?.DATA?.notifiedUser) ? `Job ${flow == 'edit' ? "updated" : "posted"} successfully.` : `Job ${flow == 'edit' ? "updated" : "posted"} successfully, ${'\n'} notifying the top${' '}${ res?.DATA?.notifiedUser} ${'\n'}qualified applicants.`);
            
            openSuccessModal();
            setBasicInfo({
              jobname: field,
              country: field,
              location: field,
              years: field,
              description: field,
              salary: field,
              noOfOpenings:field,
              filldate: moment().add(7, "days").format("YYYY-MM-DD"),
              dateText: moment().add(7, "days").format("DD-MMM-YYYY"),
            })
            setRoleEntries([])
            setRoleSuggestions([])
            setKeywordsEntries([])
            setKeywordsSuggestions([])
            setstateEntries([
              { stateId: 1, stateName: "Remote", name: "Remote", Id: 1 },
            ])
            setSelectedExperience({})   
            setrstSelector(new Date()) 
            setSelectedCompany({})
            setFetching(false)
          }else{
            let originalString = res?.message;
            if(originalString.includes(':')){
              let resultString = originalString
              .split(":")
              .slice(1)
              .join(":")
              ?.trim();
            setError(resultString);
            }else{
              setError(originalString);
            }
            openFailModal();
            setFetching(false)
          }
          }).catch(err => {
              console.log("error", err)
              setFetching(false)
          })}
  }
  const getJobData = async (jobid) => {
    setFetching(true)
    try {
      let token = sessionStorage.getItem("token");
      fetch(Url?.getJobDetailsByJobId.replace('{id}', jobid), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            console.log('designations============>', res?.DATA);
            // setSelectedCountry(res?.DATA?.countryCode);
    
            const modifiedDesignation = res?.DATA?.designation?.map(i => {
              return {...i, name: i.designationName, Id:i.designationId};
            });
            setRoleEntries(modifiedDesignation);
            const modifiedKeywords = res?.DATA?.skill?.map(i => {
              return {...i, name: i.name, Id:i.skillId};
            });
            setKeywordsEntries(modifiedKeywords);
            const modifiedStates = res?.DATA?.state?.map(i => {
              return {...i, name: i.stateName, Id:i.stateId};
            });
            setstateEntries(modifiedStates);
            setSelectedExperience(res?.DATA?.experience);
            setCompanyEntries([{name:res?.DATA?.company?.companyName, id:res?.DATA?.company?.companyId}])
            let d = res?.DATA?.tillDate;
            let fdate = moment(d).format('YYYY-MM-DD');
            const dateText = moment(d).format('DD-MMM-YYYY');
            setSelectedWorkMode({workModeId:res?.DATA?.workMode?.workModeId, workMode:res?.DATA?.workMode?.workMode,name:res?.DATA?.workMode?.workMode, id:res?.DATA?.workMode?.workModeId})
            setSelectedCompany({...res?.DATA?.company, name:res?.DATA?.company?.companyName, id:res?.DATA?.company?.companyId})
            setSelectedJobType({jobTypeId:res?.DATA?.jobType?.jobTypeId, jobType:res?.DATA?.jobType?.jobType,name:res?.DATA?.jobType?.jobType, id:res?.DATA?.jobType?.jobTypeId})
            setBasicInfo({
              ...basicInfo,
              filldate: fdate,
              dateText: dateText,
              // salary:
              //   res?.DATA?.salary.length > 0
              //     ? onAllowAllCharacters('Salary', res?.DATA?.salary)
              //     : field,
              // noOfOpenings:onlyNumberAndNonMandatory(res?.DATA?.noOfOpenings, 'No. of openings'),
              // description: onAllowAllCharacters(
              //   'Job description',
              //   res?.DATA?.description,
              // ),
              salary:{...field,fieldValue:res?.DATA?.salary ?? ''},//onAllowAllCharacters('Salary', res?.DATA?.salary ?? ''),
              noOfOpenings:{...field,fieldValue:res?.DATA?.noOfOpenings ?? ''},//onlyNumberAndNonMandatory(res?.DATA?.noOfOpenings ?? '', 'No. of openings',5),
              description: {...field,fieldValue:res?.DATA?.description ?? ''},//onAllowAllCharacters('Job description',res?.DATA?.description ?? ''),
            });
            setFetching(false)
          }
        })
    } catch (error) {
      console.log('get Job Details', error);
      setFetching(false)
    }
  };

  return (
    <div>
      CreateJob
      <Modal
        isOpen={true}
        // onRequestClose={closeFromModal}
        // ariaHideApp={false}
        className="createjobModalWrapper"
      >
        <div className="createjobFormWrapper">
          <div className="FormWrapperParent">
            <div className="FormHeading" style={{marginBottom:"1.3vh"}}>
              <p>Let’s {flow == 'edit' ? "update" : "create"} your job post!</p>
            </div>
            <div className="CreateJobMainWrapper" ref={scrollForm}>
            <div className="FieldContainerWrapper1">
              <div className="FieldContainerWrapper1SubContainer1">
                <div className="jobTitleWrapper createJobLabelStyle">
                  <ComponentAndScreenConstant.DropdownWithSelectPicker
                    placeholderText="Enter here"
                    entryInput={RoleEntryInput}
                    entries={RoleEntries}
                    suggestions={RoleSuggestions}
                    setEntryInput={setRoleEntryInput}
                    setEntries={setRoleEntries}
                    setSuggestions={setRoleSuggestions}
                    handleEmailChange={handleRoleChange}
                    maxRoles={1}
                    multiline={true}
                    label={"Job Title"}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    CustomeEntryShow={true}
                    paddingLeftZeroRequired="true"
                  />
                </div>
                <div className="jobTitleWrapper createJobLabelStyle">
                  <ComponentAndScreenConstant.SelectPicker
                    // placeholderText="Enter here"
                    // entryInput={companyInputChange}
                    // entries={CompanyEntries}
                    // suggestions={CompanySuggestions}
                    // setEntryInput={setCompanyInputChange}
                    // setEntries={setCompanyEntries}
                    // setSuggestions={setCompanySuggestions}
                    // handleEmailChange={handleCompanyChange}
                    // maxRoles={1}
                    // multiline={true}
                    // label={"Company Name"}
                    // isRequired={true}
                    // InputSty={{
                    //   backgroundColor: "#FFF",
                    //   textAlignVertical: "top",
                    //   // height: 100,
                    //   color: "#222B45",
                    //   fontSize: 14,
                    // }}
                    // CustomeEntryShow={false}
                    label={"Company Name"}
                    isRequired={true}
                    data={companyList.map((k) => {
                      return { ...k, name: k?.companyName, id: k.companyId};
                    })}
                    onChange={(i, index) => {
                      console.log("selected", i.target.value,index)
                      setSelectedCompany(JSON.parse(i.target.value));
                    }}
                    rstSelector={rstSelector}
                    value={selectedCompany?.companyId}
                    defaultValueToDisplay={"Select Company Name"}
                  />
                </div>
                <div className="jobTitleWrapper createJobLabelStyle">
                  <ComponentAndScreenConstant.DropdownWithSelectPicker
                    placeholderText={
                      stateEntries.length > 0
                        ? "Add more ..."
                        : "Enter city name"
                    }
                    entryInput={stateEntryInput}
                    entries={stateEntries}
                    suggestions={stateSuggestions}
                    setEntryInput={setstateEntryInput}
                    setEntries={setstateEntries}
                    setSuggestions={setstateSuggestions}
                    handleEmailChange={handleStateChange}
                    maxRoles={5}
                    label={"Work Location"}
                    isRequired={true}
                    paddingLeftZeroRequired="true"
                  />
                </div>
              </div>
              <div className="FieldContainerWrapper1SubContainer2">
                <div className="keywordsWrapper createJobKeyword createJobLabelStyle">
                  <ComponentAndScreenConstant.DropdownWithSelectPicker
                    placeholderText={
                      KeywordsEntries?.length == 0
                        ? "Example: developer,etc."
                        : "Add more.."
                    }
                    entryInput={KeywordsEntryInput}
                    entries={KeywordsEntries}
                    suggestions={KeywordsSuggestions}
                    setEntryInput={setKeywordsEntryInput}
                    setEntries={setKeywordsEntries}
                    setSuggestions={setKeywordsSuggestions}
                    handleEmailChange={handleKeywordsChange}
                    maxRoles={10}
                    multiline={true}
                    label={"Keywords"}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      shadowRadius: 4,
                      textAlignVertical: "top",
                      // height: 100,
                      minHeight: 100,
                      color: "#222B45",
                      borderRadius: 4,
                      fontSize: 14,
                    }}
                    CustomeEntryShow={true}
                    ParentFieldWrapper={{height: "160px",padding:'4px', }}
                    customeStyleForSuggestionList={{}}
                    paddingLeftZeroRequired="true"
                    marginRequired={false}
                  />
                </div>
              </div>
            </div>

            <div className="FieldContainerWrapper2">
              <div className="FieldContainerWrapper1SubContainer1">
                <div className="jobTitleWrapper createJobLabelStyle">
                  <ComponentAndScreenConstant.InputTextBox
                    placeholderText="Ex. 6-7 / 8 / 12.6" 
                    label={'Salary (LPA)'}
                    isRequired={false}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    value={basicInfo?.salary?.fieldValue}
                    onTextChange={(e) => onTextChange("Salary", e.target.value)}
                    errormsg={basicInfo?.salary?.errorField}
                    paddingLeftZeroRequired="true"
                    maxLength={9}
                  />
                </div>
              </div>
              <div className="FieldContainerWrapper1SubContainer2">
                <div className="jobTitleWrapper createJobLabelStyle createJobDateWidth">
                  <ComponentAndScreenConstant.DatePickerComponent
                    placeholderText="Enter here"
                    label={"Active Till"}
                    minDate={moment().add(1,'day').toDate()}
                    maxDate={moment().add(1, 'months').toDate()}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    selectedDate={new Date(modifyTimezoneString(basicInfo?.filldate, 'ddd, MMM DD, YYYY, hh:mm:ss A [GMT]Z'))}
                    onChange={(e) => {
                      console.log(e);
                      setBasicInfo({ ...basicInfo, filldate: e });
                    }}
                    scrollBottom={scrollBottom}
                    setScrollBottom={setScrollBottom}
                  />
                </div>
              </div>
            </div>


            <div className="FieldContainerWrapper2" >
              <div className="FieldContainerWrapper1SubContainer1">
                <div className="jobTitleWrapper createJobLabelStyle">
                <ComponentAndScreenConstant.SelectPicker
                    label={"Work Mode"}
                    isRequired={true}
                    data={workModeOptions.map((k) => {
                      return { ...k, name: k?.workMode, id: k.workModeId};
                    })}
                    onChange={(i, index) => {
                      console.log("selected", i.target.value,index)
                      setSelectedWorkMode(JSON.parse(i.target.value));
                    }}
                    rstSelector={rstSelector}
                    value={selectedWorkMode?.workModeId}
                    defaultValueToDisplay={"Select Work Mode"}
                  />
                </div>
              </div>
              <div className="FieldContainerWrapper1SubContainer2">
                <div className="jobTitleWrapper createJobLabelStyle createJobDateWidth">
                <ComponentAndScreenConstant.SelectPicker
                    label={"Job Type"}
                    isRequired={true}
                    data={jobTypeOptions.map((k) => {
                      return { ...k, name: k?.jobType, id: k.jobTypeId};
                    })}
                    onChange={(i, index) => {
                      console.log("selected", i.target.value,index)
                      setSelectedJobType(JSON.parse(i.target.value));
                    }}
                    rstSelector={rstSelector}
                    value={selectedJobType?.jobTypeId}
                    defaultValueToDisplay={"Select Job Type"}
                  />
                </div>
              </div>
            </div>
            
            <div className="FieldContainerWrapper2" style={{marginBottom:"10px"}} >
              <div className="FieldContainerWrapper1SubContainer1">
                <div className="jobTitleWrapper createJobLabelStyle">
                <ComponentAndScreenConstant.SelectPicker
                    label={"Years of Experience"}
                    isRequired={true}
                    data={Experience.map((k) => {
                      return { ...k, name: k?.experience, id: k.experienceId};
                    })}
                    onChange={(i, index) => {
                      console.log("selected", i.target.value,index)
                      setSelectedExperience(JSON.parse(i.target.value));
                    }}
                    rstSelector={rstSelector}
                    value={selectedExperience?.experienceId}
                    defaultValueToDisplay={"Select Year of Experience"}
                  />
                </div>
              </div>
              <div className="FieldContainerWrapper1SubContainerNew">
                <div className="jobTitleWrapper createJobLabelStyle createJobDateWidth">
                <ComponentAndScreenConstant.InputTextBox
                    placeholderText="Enter here"
                    label={'No. of Openings'}
                    isRequired={false}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    value={basicInfo?.noOfOpenings?.fieldValue}
                    onTextChange={(e) => onTextChange("No. of openings", e.target.value, 5)}
                    errormsg={basicInfo?.noOfOpenings?.errorField}
                    paddingLeftZeroRequired="true"
                    maxLength={3}
                    inputType="number"
                  />
                </div>
              </div>
            </div>
            
            <div className="FieldContainerWrapper3">
              <div className="TextBoxWrapper createJobWidth createJobLabelStyle">
                <ComponentAndScreenConstant.TextBox
                  placeholderText="Enter here"
                  label={"Job Description"}
                  isRequired={true}
                  InputSty={{
                    backgroundColor: "#FFF",
                    textAlignVertical: "top",
                    // height: 100,
                    color: "#222B45",
                    fontSize: 14,
                  }}
                  value={basicInfo?.description?.fieldValue}
                  maxLength={10000}
                  isCounterRequired={true}
                  onTextChange={(e) =>
                    onTextChange("Job Description", e.target.value)
                  }
                  errormsg={basicInfo?.description?.errorField}
                />
              </div>
            </div>
            </div>
          </div>
          <div className="FieldContainerWrapper4">
              <button className="cancelBtn" onClick={()=>{ navigate(-1);}}>Close</button>
              {flow =='edit'?
                <button onClick={SubmitJobPost} className="postJobBtn" style={{padding:"10.5px 10px"}}><img style={{width:83}} src={UpdateJob}/></button>
                :
                <button onClick={SubmitJobPost} className="postJobBtn" style={{padding:"9.5px 12px"}}><img style={{width:75}} src={postJob}/></button>}
              {flow == 'edit' && <button onClick={ openDeleteModal} className="postJobBtn" style={{backgroundColor:"tomato", borderColor:"tomato"}}> Delete</button>}

            </div>
        </div>
      </Modal>
      <Modal
        isOpen={deletemodalIsOpen}
        onRequestClose={closeDeleteModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="deleteModalWrapper">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={closeDeleteModal}>
              ×
            </p>
          </div>
        </div>
        <div className="delAccModalErrMsg">
          <p>Would you like to delete this job post?</p>
          <div>
            <button
              style={{
                padding: "10px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #00ADEE",
              }}
              onClick={closeDeleteModal}
            >
              No
            </button>
            <button
              style={{
                padding: "10px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #00ADEE",
                color: "#FFF",
                backgroundColor: "#00ADEE",
              }}
              onClick={() => {
                handleDeleteUser();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={closeFailModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={closeFailModal}>×</p>
          </div>
        </div>
        <div>
          <Lottie animationData={Oops} loop={true} className="delAccModalErrLottie"/>
        </div>
        <div className="delAccModalErrMsg">
          {error}
        </div>
      </Modal>
      <Modal
        isOpen={SuccessModalIsOpen}
        onRequestClose={closeSuccessModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={closeSuccessModal} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
           className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {successMsg}
        </div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={()=>navigate(propData?.state?.navigateTo == "managementJobs"? '/posterDashboard/management/alljobs':'/posterDashboard/joblist')}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderStyle}
        contentLabel="Example Modal"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
    </div>
  );
};

export default CreateJob;
