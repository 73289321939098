import React, { useEffect, useState } from 'react'
import '../../components/Sidebar/Sidebar.css'
import hiremeLogo from '../../assets/quikHireLogo.png'
import dashIconGrey from '../../assets/dashIconGrey.svg'
import jobRepoGray from '../../assets/jobRepoGray.svg'
import UserIconGrey from '../../assets/UserIconGrey.svg'
import helpLogoGrey from '../../assets/helpLogoGrey.svg'
import dashIconWhite from '../../assets/dashIconWhite.svg'
import sidebarLogo2 from '../../assets/sidebarLogo2.svg'
import sidebarLogo3 from '../../assets/sidebarLogo3.svg'
import sidebarLogo4 from '../../assets/sidebarLogo4.svg'
import ProfileIconGray from '../../assets/ProfileIconGray.svg'
import ProfileIconWhite from '../../assets/ProfileIconWhite.svg'
import bellIconGray from '../../assets/bellIconGray.svg'
import bellIconWhite from '../../assets/bellIconWhite.svg'
import managementIconGray from '../../assets/managementIconGray.svg'
import managementIconWhite from '../../assets/managementIconWhite.svg'
import arrow from '../../assets/downArrow.svg'
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
import { useNavigate } from 'react-router-dom'
import myJobsWhite from '../../assets/myJobWhiteNew.svg'
import myJobsGray from '../../assets/myJobGrayNew.svg'
import companyJobWhite from '../../assets/companyJobsWhite.svg'
import companyJobGray from '../../assets/companyJobsGray.svg'
import teamManagementGray from '../../assets/teamManagementGray.svg'
import teamManagementWhite from '../../assets/teamManagementWhite.svg'
import companyMngGray from '../../assets/companyMngGray.svg'
import companyMngWhite from '../../assets/companyMngWhite.svg'
import { useProfileDropdownContext, useSelectedCardContext, useSelectedSubMenuContext, useSelectedSubMenuJobContext } from '../../Context/Context'
import { ModuleCards } from '../../constants/ModuleCards'
import { PortalUserRole } from '../../EnvoirnmentSetup/APIs'
import companyIconWhite from '../../assets/companyManagementWhite.svg'
import companyIconGray from '../../assets/companyManagementGray.svg'
import { getPremiumMemberUserInfo } from '../../constants/constantFunctions'
import createJob from '../../assets/Qcreate a job.svg'
import reportsGray from '../../assets/reportsGray.svg'
import reportsWhite from '../../assets/ReportsWhite.svg'
import Modal from "react-modal";
import Lottie from "lottie-react";
import loader from "../../../src/assets/PANLoader.json";
import qIcon from '../../assets/qIcon.svg'

const Sidebar = (props) => {


  const navigate = useNavigate()
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const {subMenu, setSubMenu, subMenuColor, setSubMenuColor} = useSelectedSubMenuContext();
  const {subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs} = useSelectedSubMenuJobContext();
  const [mobileScreen,setMobileScreen] = useState(false)
  const {dropDownVisible,setDropDownVisible} = useProfileDropdownContext()
  const [fetching, setFetching] = useState(false);


  const clearSession = () => {
    sessionStorage.removeItem("TablePageNumber");
    sessionStorage.removeItem("storedPageNumber");
    sessionStorage.removeItem("filterOption");
    sessionStorage.removeItem("pageEntriesLength");
    sessionStorage.removeItem("fromDate");
    sessionStorage.removeItem("toDate");
    sessionStorage.removeItem("DateSubmitStatus");
    sessionStorage.removeItem("searchTextString");
  };

  const ApplictionActionloaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };

  useEffect(()=>{
   if(window.screen.width<1024){
    setMobileScreen(true)
   }
   else{
    setMobileScreen(false)
   }
   if(selectedCard == ModuleCards?.Notification){
    setSubMenu(false)
    setSubMenuJob(false)
   }
  },[window.screen.width,selectedCard])
  return (
    <>{!mobileScreen?<div className='pDashSidebarOuterDiv'>
      <div className='pDashSidebarWrapper'>
      <div className='sidebarLogoDiv'>
        <img src={hiremeLogo} onClick={()=>{navigate('/posterDashboard');setSubMenu(false);setSubMenuJob(false);setDropDownVisible(false)}}/>
      </div>
      <div className='pDashNavContainer'>
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.Dashboard?"#007749":"#ffff",color:selectedCard== ModuleCards.Dashboard ?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.Dashboard);clearSession();navigate("/posterDashboard");setSubMenu(false);setSubMenuJob(false);}}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Dashboard? dashIconWhite:dashIconGrey} />
          </div>
          <div className="pDashSidebarNavs">
          Dashboard
          </div>
        </div>
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.JobRepository?"#007749":"#ffff",color:selectedCard==ModuleCards.JobRepository?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.JobRepository);
        clearSession();
        navigate("/posterDashboard/joblist");
        setSubMenu(false);
        setSubMenuJob(false);
        setDropDownVisible(false)
        }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.JobRepository ? myJobsWhite:myJobsGray} />
          </div>
          <div className="pDashSidebarNavs">
          My Postings
          </div>
        </div>
        {PortalUserRole == "ROLE_MANAGER"?<div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.CompanyJobs ? "#007749":"#ffff",color:selectedCard==ModuleCards.CompanyJobs ? "#ffff":"#9D9D9D"}} 
        // onClick={()=>{setSelectedCard(ModuleCards.ApplicantRepository );
        // clearSession();
        //   navigate('/posterDashboard/applicantRepository');
        //   setSubMenu(false);
        //   setSubMenuJob(false);
        //   setDropDownVisible(false)
        //   }}
         onClick={() => {
          clearSession();
          navigate("/posterDashboard/management/alljobs");
          setSubMenuColorMyJobs(1);
          setSelectedCard(ModuleCards.CompanyJobs);
          setDropDownVisible(false)}}
          >
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards?.CompanyJobs ? companyJobWhite:companyJobGray} />
          </div>
          <div className="pDashSidebarNavs">
          All Jobs
          </div>
        </div>:<></>}
        {/* {PortalUserRole=="ROLE_MANAGER" && <><div className='pDashSidebarItems' style={{marginBottom:0,backgroundColor:selectedCard==ModuleCards.JobRepository ? "#007749":"#ffff",color:selectedCard==ModuleCards.JobRepository ? "#ffff":"#9D9D9D"}} onClick={()=>{;
          clearSession();
          setSubMenuJob(!subMenuJob);
          setSubMenuColorMyJobs(0)
          setSubMenu(false)
          setDropDownVisible(false)
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.JobRepository ? sidebarLogo2: jobRepoGray} />
          </div>
          <div className="pDashSidebarNavs">
          Jobs &nbsp;<IoMdArrowDropdown className="sidebarDropdownArrow"/>
          </div>
        </div>
        {
           subMenuJob==true &&<>
           <div onClick={() => {clearSession();navigate("/posterDashboard/joblist");setSubMenuColorMyJobs(2);setSelectedCard(ModuleCards.JobRepository);setDropDownVisible(false)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"7px",width:"80%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{color:subMenuColorMyJobs==2?"#007749":"#9D9D9D",fontWeight:subMenuColorMyJobs==2?500:"normal"}}>
              <IoMdArrowDropright className="sidebarDropdownRightArrow"/> &nbsp;My Jobs
              </div>
            </div>
            <div  onClick={() => {clearSession();navigate("/posterDashboard/management/alljobs");setSubMenuColorMyJobs(1);setSelectedCard(ModuleCards.JobRepository);setDropDownVisible(false)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"5px 7px 7px 7px",width:"80%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{color:subMenuColorMyJobs==1?"#007749":"#9D9D9D",fontWeight:subMenuColorMyJobs==1?500:"normal"}}>
              <IoMdArrowDropright className="sidebarDropdownRightArrow"/> &nbsp;Company Jobs
              </div>
            </div>
            </>
        }
        </>} */}
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.ApplicantRepository ? "#007749":"#ffff",color:selectedCard==ModuleCards.ApplicantRepository ? "#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.ApplicantRepository );
        clearSession();
          navigate('/posterDashboard/applicantRepository');
          setSubMenu(false);
          setSubMenuJob(false);
          setDropDownVisible(false)
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.ApplicantRepository ? sidebarLogo3:UserIconGrey} />
          </div>
          <div className="pDashSidebarNavs">
          Applicants
          </div>
        </div>
        {PortalUserRole == "ROLE_MANAGER"?
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.      Management ? "#007749":"#ffff",color:selectedCard==ModuleCards.Management ? "#ffff":"#9D9D9D"}} onClick={()=>{
          setSelectedCard(ModuleCards.Management );
          clearSession();
          navigate("/posterDashboard/management/userManagement");
          setSubMenuColor(2);
          setDropDownVisible(false)}}
          >
            <div className="pDashSidebarImgDiv">
              <img src={selectedCard==ModuleCards.Management ? teamManagementWhite:teamManagementGray} />
            </div>
            <div className="pDashSidebarNavs">
            Team
            </div>
          </div>
         :
         <></>
        }
        {PortalUserRole == "ROLE_MANAGER"?
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.      CompanyManagement ? "#007749":"#ffff",color:selectedCard==ModuleCards.CompanyManagement ? "#ffff":"#9D9D9D"}} onClick={()=>{
          setSelectedCard(ModuleCards.CompanyManagement );
          clearSession();
          setDropDownVisible(false)
          navigate("/posterDashboard/companyManagement");
        }}
          >
            <div className="pDashSidebarImgDiv">
              <img src={selectedCard==ModuleCards.CompanyManagement ? companyMngWhite:companyMngGray} />
            </div>
            <div className="pDashSidebarNavs">
            Companies
            </div>
          </div>
         :
         <></>
        }
        {/* {PortalUserRole=="ROLE_MANAGER" && <><div className='pDashSidebarItems' style={{marginBottom:0,backgroundColor:selectedCard==ModuleCards.Management ? "#007749":"#ffff",color:selectedCard==ModuleCards.Management ? "#ffff":"#9D9D9D"}} onClick={()=>{;
          clearSession();
          setSubMenu(!subMenu);
          setSubMenuColor(0)
          setSubMenuJob(false);
          setDropDownVisible(false)
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Management ? managementIconGray: managementIconWhite} />
          </div>
          <div className="pDashSidebarNavs">
          Management &nbsp;<IoMdArrowDropdown className="sidebarDropdownArrow"/>
          </div>
        </div>
        {
           subMenu==true &&<>
           <div onClick={() => {navigate("/posterDashboard/management/userManagement");setSubMenuColor(2);setSelectedCard(ModuleCards.Management);clearSession();setDropDownVisible(false)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"7px",width:"80%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{color:subMenuColor==2?"#007749":"#9D9D9D",fontWeight:subMenuColor==2?500:"normal"}}>
              <IoMdArrowDropright className="sidebarDropdownRightArrow"/> &nbsp;Team Management
              </div>
            </div>
            <div  onClick={() => {navigate("/posterDashboard/companyManagement");setSubMenuColor(1);setSelectedCard(ModuleCards.Management);clearSession();setDropDownVisible(false)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"5px 7px 7px 7px",width:"80%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{color:subMenuColor==1?"#007749":"#9D9D9D",fontWeight:subMenuColor==1?500:"normal"}}>
              <IoMdArrowDropright className="sidebarDropdownRightArrow"/> &nbsp;Company Management
              </div>
            </div>
            </> 
        }
        </>} */}
        {PortalUserRole == "ROLE_MANAGER" &&<div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.Reports?"#007749":"#ffff",color:selectedCard== ModuleCards.Reports?"#ffff":"#9D9D9D"}} onClick={()=>{
          setSelectedCard(ModuleCards.Reports);
          clearSession();setSubMenu(false);
          setSubMenuJob(false);
          setDropDownVisible(false)
          setFetching(true)
         getPremiumMemberUserInfo()
         .then((status) => {
          setFetching(false)
        if(status){
          navigate('/posterDashboard/management/reportdashboard');
          setSelectedCard(ModuleCards.Reports)
        }})
        .catch((e)=>{
          setFetching(false)
          console.log(e);
        })
        }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Reports? reportsWhite:reportsGray} />
          </div>
          <div className="pDashSidebarNavs">
          Insights
          </div>
        </div>}
      </div>
      </div>
      <div className='pDashSidebarBottomDiv'>
            {/* <img src={createJob} className="pDashSidebarCreateJobs" alt='Something went wrong' onClick={()=>{
              setDropDownVisible(false)
              setFetching(true)
           getPremiumMemberUserInfo().then((status) => {
            setFetching(false)
            if(status){
              setSelectedCard(2);
              navigate('/posterDashboard/createJobs');setDropDownVisible(false)
            }})
            .catch((e)=>setFetching(false))
         }}/> */}
         <div className="pDashSidebarCreateJobs" id='createJob' onClick={()=>{
              setDropDownVisible(false)
              setFetching(true)
           getPremiumMemberUserInfo().then((status) => {
            setFetching(false)
            if(status){
              setSelectedCard(2);
              navigate('/posterDashboard/createJobs');setDropDownVisible(false)
            }})
            .catch((e)=>setFetching(false))
         }}>
          <img src={qIcon} />Create a job
         </div>
        {/* <div className='pDashSidebarCopyright' style={{border:"1px solid red"}}> */}
          <div className='pDashSidebarCopyright' style={{textAlign:"center"}}>&copy; {new Date().getFullYear()} QuikHire.ai</div>
          <div className='pDashSidebarCopyright' style={{textAlign:"center"}}>All rights reserved.</div>
        {/* </div> */}
      </div>
      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={ApplictionActionloaderstyle}
        contentLabel="Example Modal"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
    </div>:<></>}</>
  )
}

export default Sidebar