import React, { useEffect, useState } from "react";
import "./Notification.css";
import { useNavigate } from "react-router-dom";
import locationLogo from "../../assets/location.png";
import caenderLogo from "../../assets/dategray.png";
import experienceLogo from "../../assets/experiencegray.png";
import deleteRoundLogo from "../../assets/deleteround.png";
import { Url } from "../../EnvoirnmentSetup/APIs";
import moment from "moment";
import Modal from "react-modal";
import Lottie from "lottie-react";
import loader from "../../../src/assets/PANLoader.json";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import nodata from "../../assets/nodata.png";
import { useSelectedCardContext } from "../../Context/Context";
import breadCrumbArrow from "../../assets/backArrowGray.svg";
import { useNotificationContext } from "../../Context/Context";

const Notification = () => {
  const navigate = useNavigate();
  const {setNotificationCount} = useNotificationContext()
  const [notifications, setNotifications] = useState([]);
  const [deletemodalIsOpen, setDeletemodalIsOpen] = useState(false);
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
  const [SuccessModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [error, setError] = useState("");

  console.log("Allnotification*******", notifications);

  const Notificationloaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };
  const NotificationcustomStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
      maxWidth:window.screen.width>1023?'60%':'90%'
    },
  };

  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  useEffect(() => {
    setSelectedCard(5);
  }, []);

  useEffect(() => {
    getNotifications();
  }, []);

  function openDeleteModal() {
    setDeletemodalIsOpen(true);
  }

  function closeDeleteModal() {
    setDeletemodalIsOpen(false);
  }
  function openFailModal() {
    setFailmodalIsOpen(true);
  }

  function closeFailModal() {
    setFailmodalIsOpen(false);
  }

  function openSuccessModal() {
    setSuccessModalIsOpen(true);
  }

  function closeSuccessModal() {
    setSuccessModalIsOpen(false);
  }

  const getNotifications = () => {
    setFetching(true);
    try {
      let token = sessionStorage.getItem("token");
      fetch(Url?.GetNotifications, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("APIGetNotifications123", res);
          setFetching(false);
          if (res?.SUCCESS) {
            let changedData = res?.DATA.map((i) => {
              let stateString = "";
              i?.job?.state?.map((j, index) => {
                if (index < i?.job?.state?.length - 1) {
                  stateString = stateString + j.stateName + ", ";
                } else {
                  stateString = stateString + j.stateName;
                }
              });
              let designationString = "";
              i?.job?.designation?.map((j, index) => {
                if (index < i?.job?.designation?.length - 1) {
                  designationString =
                    designationString + j.designationName + ", ";
                } else {
                  designationString = designationString + j.designationName;
                }
              });
              let salaryWithCurrency = !!i?.job?.salary
                ? i?.job?.countryCode?.currencyPosition
                  ? i?.job?.countryCode?.currency + i?.job?.salary
                  : i?.job?.salary + i?.job?.countryCode?.currency
                : "";

              console.log({ salaryWithCurrency });
              return {
                ...i,
                stateString,
                designationString,
                salaryWithCurrency,
              };
            });
            console.log(
              "Get Notifications...........",
              JSON.stringify(changedData)
            );
            setNotifications(changedData);
          }
          setFetching(false);
        });
    } catch (error) {
      console.log("notification.error..........", error);
    }
  };

  const deleteNotification = (notificationId, doesNavigate, jobdetails) => {
    let token = sessionStorage.getItem("token");

    // let linkedinUsername = 'example'; // Replace with the LinkedIn username or profile ID you want to open
    try {
      let NotificationData = {
        notificationId: notificationId,
        seen: true,
        delete: true,
      };
      console.log("open linkedin", NotificationData);

      fetch(Url?.GetNotifications, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(NotificationData),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            setNotificationCount(new Date())
            if (doesNavigate) {
              navigate("/posterDashboard/joblist/appliedby", {
                state: {
                  flow: "Applied",
                  jobId: jobdetails?.jobId,
                  jobDetails: jobdetails,
                },
              });
            } else {
              getNotifications();
              setsuccessMsg(`The notification is deleted successfully!`);
              openSuccessModal();
            }
          } else {
            let originalString = res?.message;
            if(originalString.includes(':')){
              let resultString = originalString
              .split(":")
              .slice(1)
              .join(":")
              .trim();
            setError(resultString);
            }else{
              setError(originalString);
            }
            openFailModal();
          }
        });
    } catch (error) {
      console.log("called applicant err", error);
    }
  };

  return (
    <div className="NotificationPageWrapper">
      {/* GO Back functionality */}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="notificationHeader">Notifications</p>
        <ul className="breadCrumb">
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <img style={{ width: "8px" }} src={breadCrumbArrow} alt="" />
            <span
              onClick={() => navigate(-1)}
              style={{ fontSize: "14px", cursor: "pointer",color:"#007749" }}
            >
              Notifications
            </span>
          </li>
        </ul>
      </div>
      {notifications.length == 0 ? (
        <div className="noNotificationMsg">
          <img src={nodata} className="nodataImage" />
          <p>There are currently no notifications available.</p>
        </div>
      ) : (
        notifications?.map((item, index) => {
          console.log({ item });
          return (
            <div key={index} className="NotificationContainerWrapper">
              <div className="NotificationContainer">
                <div className="NotificationCardWrapper">
                  <div className="NotificationCard">
                    <div className="NotificationCardUpperPart">
                      <p>{item?.notificationTitle}</p>{" "}
                      <p className="NotificationCardsalary">
                        {item?.salaryWithCurrency
                          ? item?.salaryWithCurrency + " LPA"
                          : "Not Disclosed"}
                      </p>
                    </div>
                    <div className="NotificationCardLowerPart">
                      <p
                        className="NotificationCardJobTitle"
                        onClick={() => {
                          deleteNotification(
                            item?.notificationId,
                            true,
                            item?.job
                          );
                        }}
                      >
                        {item?.job?.designation
                          ?.map((v) => v?.designationName)
                          .join(", ")}
                      </p>
                      <div className="NotificationCardDeatails">
                        <div className="NotificationCardDeatailsLeft">
                          <div className="NotificationCardskillWrapper">
                            <img
                              src={experienceLogo}
                              className="NotificationCardapplicantSkillLogo"
                            />
                            <p style={{ marginLeft: "10px" }}>
                              {item?.job?.experience?.experience}
                            </p>
                          </div>
                          <div className="NotificationCardskillWrapper">
                            <img
                              src={caenderLogo}
                              className="NotificationCardapplicantSkillLogo"
                            />
                            <p style={{ marginLeft: "10px" }}>
                              Active Till Date:{" "}
                              {moment(item?.job?.tillDate).format("DD-MMM-YYYY")
                                ? moment(item?.job?.tillDate).format(
                                    "DD-MMM-YYYY"
                                  )
                                : "-"}
                            </p>
                          </div>
                          <div className="NotificationCardskillWrapper" style={{marginBottom:window.screen.width<1024 && "5px"}}>
                            <img
                              src={locationLogo}
                              className="NotificationCardapplicantSkillLogo"
                            />
                            <p style={{ marginLeft: "10px" }}>
                              {item?.job?.state
                                ?.map((v) => v?.stateName)
                                .join(", ")}
                            </p>
                          </div>
                        </div>
                        <div className="NotificationCardDeatailsRight">
                          <img
                            onClick={() =>
                              deleteNotification(item?.notificationId, false)
                            }
                            src={deleteRoundLogo}
                            className="NotificationCardDeleteIcon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}

      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={Notificationloaderstyle}
        contentLabel="Example Modal"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={closeFailModal}
        ariaHideApp={false}
        style={NotificationcustomStylesDeleteModal}
        contentLabel="Example Modal"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={closeFailModal}>
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{error}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={closeFailModal}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={SuccessModalIsOpen}
        onRequestClose={closeSuccessModal}
        ariaHideApp={false}
        style={NotificationcustomStylesDeleteModal}
        contentLabel="Example Modal"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={closeSuccessModal} className="delAccModalCrossPara">
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{successMsg}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={closeSuccessModal}>OK</button>
        </div>
      </Modal>
    </div>
  );
};

export default Notification;
