import React, { useEffect, useState } from "react";
import "./TotalUsageSummaryReport.css";
import { ComponentAndScreenConstant } from "../../../constants/componentAndScreenConstant";
import { PortalUserRole, Url } from "../../../EnvoirnmentSetup/APIs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelectedCardContext } from "../../../Context/Context";
import Modal from "react-modal";
import Lottie from "lottie-react";
import loader from "../../../../src/assets/PANLoader.json";
import breadCrumbArrow from '../../../assets/backArrowGray.svg'
import qSubmit from '../../../assets/QSubmit.svg'
import { ExcelDownload } from "../../../constants/constantFunctions";
import nodatafound from '../../../assets/nodata.png'

const TotalUsageSummaryReport = () => {
  const navigate = useNavigate();

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [allData, setAllData] = useState({
    totalJobPosted: 0,
    totalActiveJobs: 0,
    deletedJobs: 0,
    applicantRegistered: 0,
    UsJobPoster: 0,
    IndianJobPoster: 0,
    posterRegistered: 0,
    expiredJobs: 0,
    totalActiveCompanies:0
  });
  const [dataByDate, setDataByDate] = useState({
    uniquePeopleAccessedApplication: 0,
    uniqueApplicantAccessedMobileApp: 0,
    uniqueJobPosterAccessedMobileApp: 0,
    uniqueJobPosterAccessedWebApp: 0,
    notificationToJobPoster: 0,
    notificationToApplicant: 0,
    totalNewApplicants: 0,
    totalNewJobPoster: 0,
    totalNewCompanies: 0,
    totalJobs: 0,
  });
  const [showTable, setShowTable] = useState(false);
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setSelectedCard(7);
    getAllrecords();
  }, []);

  const loaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };

  const getAllrecords = () => {
    setFetching(true)
    try {
      let token = sessionStorage.getItem("token");

      fetch(Url?.AlldataReport, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }) 
        .then((response) => response.json())
        .then((res) => { 
          setFetching(false)
          console.log("new data ", res?.DATA)
          if (res.SUCCESS) {
            setAllData({
              totalJobPosted: res?.DATA?.totalJobs,
              totalActiveJobs: res?.DATA?.totalActiveJobs,
              deletedJobs: res?.DATA?.deletedJobs,
              applicantRegistered: res?.DATA?.totalApplicants,
              UsJobPoster: res?.DATA?.totalUSJobPosters,
              IndianJobPoster: res?.DATA?.totalIndianJobPosters,
              posterRegistered: res?.DATA?.totalJobPosters,
              expiredJobs: res?.DATA?.totalExpiredJobs,
              totalActiveCompanies:res?.DATA?.totalActiveCompanies
            });
          }
        });
    } catch (error) {
      setFetching(false)
      console.log("Experience", error);
    }
  };

  const getDataByDates = () => {
    //   console.log("date",moment(fromDate).format("yyyy-MM-DD"))
    setFetching(true)
    try {
      let token = sessionStorage.getItem("token");

      fetch(
        Url?.getReportByDates
          .replace("{fromDate}", moment(fromDate).format("yyyy-MM-DD"))
          .replace("{toDate}", moment(toDate).format("yyyy-MM-DD")),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          console.log({ res });
          if (res.SUCCESS) {
            setFetching(false)
            setDataByDate({
              uniquePeopleAccessedApplication: res?.DATA?.uniquePeoples,
              uniqueApplicantAccessedMobileApp: res?.DATA?.uniqueApplicants,
              uniqueJobPosterAccessedMobileApp:
                res?.DATA?.uniqueJobPostersMobile,
              uniqueJobPosterAccessedWebApp: res?.DATA?.uniqueJobPostersWeb,
              notificationToJobPoster: res?.DATA?.jobPosterNotifications,
              notificationToApplicant: res?.DATA?.applicantNotifications,
              totalNewApplicants: res?.DATA?.totalNewApplicants,
              totalNewJobPoster: res?.DATA?.totalNewJobPosters,
              totalNewCompanies: res?.DATA?.newCompanies,
              totalJobs: res?.DATA?.totalJobs,
            });
            setShowTable(true);
          }
          else{
            setFetching(false)
          }
        }).catch((e)=>{
          console.log(e);
          setFetching(false)
        })
    } catch (error) {
      setFetching(false)
      console.log("Experience", error);
    }
  };

  return (
    <>{PortalUserRole=="ROLE_MANAGER"?
    <div>
      {/* <div className="managementLandingOuterDiv" style={{ marginTop: "5px" }}>
        <p
          style={{
            color: "#007749",
            fontSize: "1.5rem",
            marginTop: "0px",
            marginBottom: "5px",
          }}
        >
          Management
        </p>
      </div> */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin:window.screen.width>1023? ' 5px 13px 0':'0 11px',marginTop:0 }}>
        <p className="breadCrumbDiv1p" style={{marginBottom:"5px"}}>Activity Snapshot</p>
        {/* <ul className="breadCrumb">
        <li className="breadcrumbDiv2"><img className="breadcrumbDiv2Img" src={breadCrumbArrow} alt="" />
        <span onClick={()=>navigate(-1)}>Management</span>
        </li>
        </ul> */}
      </div>

      <div className="managementreportUpperContainerOuterWrapper">
        <div className="managementreportUpperContainerWrapper">
          <div className="reportUpperContainerLeftWrapper" style={{fontSize:"12px"}}>
            <div className="reportentryName">
              <p className="CountContainer CountContainerWidth" style={{cursor:allData?.totalJobPosted!=0 && "pointer",color:allData?.totalJobPosted!=0 && "blue"}} onClick={()=>allData?.totalJobPosted!=0 && ExcelDownload(Url?.downloadExcel+"totalJobs","JobsPosted")}>{allData?.totalJobPosted}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Jobs Posted</p>
            </div>
            <div className="reportentryName">
              <p className="CountContainer CountContainerWidth" style={{cursor:allData?.totalActiveJobs!=0 && "pointer",color:allData?.totalActiveJobs!=0 && "blue"}} onClick={()=>allData?.totalActiveJobs!=0 && ExcelDownload(Url?.downloadExcel+"totalActiveJobs","CurrentActiveJobs")}>{allData?.totalActiveJobs}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Current Active Jobs</p>
            </div>
            <div className="reportentryName">
              <p className="CountContainer CountContainerWidth"style={{cursor:allData?.deletedJobs!=0 && "pointer",color:allData?.deletedJobs!=0 && "blue"}} onClick={()=>allData?.deletedJobs!=0 && ExcelDownload(Url?.downloadExcel+"deletedJobs","ArchivedJobs")}>{allData?.deletedJobs}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Archived Jobs</p>
            </div>
            <div className="reportentryName">
              <p className="CountContainer CountContainerWidth" style={{cursor:allData?.expiredJobs!=0 && "pointer",color:allData?.expiredJobs!=0 && "blue"}} onClick={()=>allData?.expiredJobs!=0 && ExcelDownload(Url?.downloadExcel+"totalExpiredJobs","ExpiredJobs")}>{allData?.expiredJobs}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Expired Jobs</p>
            </div>
          </div>
          <div className="reportUpperContainerRightWrapper" style={{fontSize:"12px"}}>
          <div className="reportentryName">
              <p className="CountContainer CountContainerWidth" style={{cursor:allData?.totalActiveCompanies!=0 && "pointer",color:allData?.totalActiveCompanies!=0 && "blue"}} onClick={()=>allData?.totalActiveCompanies!=0 && ExcelDownload(Url?.downloadExcel+"totalActiveCompanies","TotalRegisteredCompanies")}>{allData?.totalActiveCompanies}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Total Registered Companies</p>
            </div>
          <div className="reportentryName">
              <p className="CountContainer CountContainerWidth" style={{cursor:allData?.applicantRegistered!=0 && "pointer",color:allData?.applicantRegistered!=0 && "blue"}} onClick={()=>allData?.applicantRegistered!=0 && ExcelDownload(Url?.downloadExcel+"totalApplicants","RegisteredApplicants")}>{allData?.applicantRegistered}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Registered Applicants</p>
            </div>
            <div className="reportentryName">
              <p className="CountContainer CountContainerWidth" style={{cursor:allData?.posterRegistered!=0 && "pointer",color:allData?.posterRegistered!=0 && "blue"}} onClick={()=>allData?.posterRegistered!=0 && ExcelDownload(Url?.downloadExcel+"totalJobPosters","RegisteredRecruiters")}>{allData?.posterRegistered}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Registered Recruiters</p>
            </div>
            {/* <div className="reportentryName">
              <p className="CountContainer CountContainerWidth" style={{cursor:allData?.UsJobPoster!=0 && "pointer",color:allData?.UsJobPoster!=0 && "blue"}} onClick={()=>allData?.UsJobPoster!=0 && ExcelDownload(Url?.downloadExcel+"totalUSJobPosters","RegisteredRecruitersFromUS")}>{allData?.UsJobPoster}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Registered Recruiters from the US</p>
            </div> */}
            <div className="reportentryName">
              <p className="CountContainer CountContainerWidth" style={{cursor:allData?.IndianJobPoster!=0 && "pointer",color:allData?.IndianJobPoster!=0 && "blue"}} onClick={()=>allData?.IndianJobPoster!=0 && ExcelDownload(Url?.downloadExcel+"totalIndianJobPosters","RegisteredRecruitersFromIndia")}>{allData?.IndianJobPoster}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Registered Recruiters from India</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{display:"flex", justifyContent:"center"}}>
      <div className='TSteamReportMiddleContainerWrapper'>
        <div className="reportFromDate">
          <p style={{padding:"0px", margin:"5px", flex:2}}>From Date:</p>
        <ComponentAndScreenConstant.DatePickerComponent
                    placeholderText="Enter here"
                    label={""}
                    // minDate={new Date()}
                    maxDate={new Date()}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    selectedDate={new Date(fromDate)}
                    onChange={(e) => {
                      console.log(e);
                      setFromDate(e);
                      setToDate(new Date())
                    }}
                    minYearsRequired={6}
                  />
        </div>
        <div className="reportFromDate">
          <p style={{padding:"0px", margin:"5px", flex:2}}>To Date:</p>
        <ComponentAndScreenConstant.DatePickerComponent
                    placeholderText="Enter here"
                    label={""}
                    minDate={new Date(fromDate)}
                    maxDate={new Date()}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    selectedDate={new Date(toDate)}
                    onChange={(e) => {
                      console.log(e);
                      setToDate(e);
                    }}
                    minYearsRequired={6}
                  />
        </div>
        <button className="SubmitReportDates" onClick={()=>{getDataByDates()
        }}><img src={qSubmit} alt="Something went wrong"/></button>
        </div>
        </div>
        <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
        <div style={{width:window.screen.width>1024?"90%":"95%",marginTop:"20px"}}>
      {showTable&&  
      <div className="EditJobOuterDiv TSTableOuterDiv" style={{width:"100%",borderTopLeftRadius:10,borderTopRightRadius:10,borderBottomLeftRadius:10,borderBottomRightRadius:10}}>
      <div className="EditJobTableSubDiv" style={{height:"97%"}}>
      <table className="tableStyleMyJobs" style={{fontSize:"12px"}}>
        <thead style={{position: "sticky", top:"0",backgroundColor:"#007749",color:"#FFFF",height:"30px"}}>
        <tr style={{textAlign:"center"}}>
          <th style={{borderRight:"0.5px solid gray",backgroundColor:"#007749",borderTopLeftRadius:"10px"}}>Sr. No.</th>
          <th style={{borderRight:"0.5px solid gray"}}>Parameter</th>
          <th style={{backgroundColor:"#007749",borderTopRightRadius:"10px"}}>Total</th>
        </tr>
        </thead>
        <tbody>    
      {/* <tr style={{backgroundColor:"#E8F2FF",height:"30px"}}>
            <td>1</td>
            <td style={{borderRight:"0.5px solid gray",padding:"0 10px"}}>Application Users</td>
            <td>{dataByDate?.uniquePeopleAccessedApplication}</td>
          </tr> */}
          <tr style={{backgroundColor:"#C1E7D8",height:"30px"}}>
            <td className="txtAlgn" style={{borderRight:"0.5px solid gray"}}>1</td>
            <td style={{borderRight:"0.5px solid gray",padding:"0 10px"}}>Mobile Application Users</td>
            <td style={{textAlign:"end",paddingRight:"20px",cursor:dataByDate?.uniqueApplicantAccessedMobileApp!=0 && "pointer",color:dataByDate?.uniqueApplicantAccessedMobileApp!=0 && "blue"}} onClick={()=>dataByDate?.uniqueApplicantAccessedMobileApp!=0 && ExcelDownload(Url?.downloadExcelDateWise?.replace('{key}','uniqueApplicants').replace('{fromDate}',moment(fromDate).format("yyyy-MM-DD")).replace('{toDate}', moment(toDate).format("yyyy-MM-DD")),'MobileApplicationUsers')}>{dataByDate?.uniqueApplicantAccessedMobileApp}</td>
          </tr>
          <tr style={{height:"30px"}}>
            <td className="txtAlgn" style={{borderRight:"0.5px solid gray"}}>2</td>
            <td style={{borderRight:"0.5px solid gray",padding:"0 10px"}}>Mobile App Users: Recruiters</td>
            <td style={{textAlign:"end",paddingRight:"20px",cursor:dataByDate?.uniqueJobPosterAccessedMobileApp!=0 && "pointer",color:dataByDate?.uniqueJobPosterAccessedMobileApp!=0 && "blue"}} onClick={()=>dataByDate?.uniqueJobPosterAccessedMobileApp!=0 && ExcelDownload(Url?.downloadExcelDateWise?.replace('{key}','uniqueJobPostersMobile').replace('{fromDate}',moment(fromDate).format("yyyy-MM-DD")).replace('{toDate}', moment(toDate).format("yyyy-MM-DD")),'MobileAppUsersRecruiters')}>{dataByDate?.uniqueJobPosterAccessedMobileApp}</td>
          </tr>
          <tr style={{backgroundColor:"#C1E7D8",height:"30px"}}>
            <td className="txtAlgn" style={{borderRight:"0.5px solid gray"}}>3</td>
            <td style={{borderRight:"0.5px solid gray",padding:"0 10px"}}>Web App Users: Recruiters</td>
            <td style={{textAlign:"end",paddingRight:"20px",cursor:dataByDate?.uniqueJobPosterAccessedWebApp!=0 && "pointer",color:dataByDate?.uniqueJobPosterAccessedWebApp!=0 && "blue"}} onClick={()=>dataByDate?.uniqueJobPosterAccessedWebApp!=0 && ExcelDownload(Url?.downloadExcelDateWise?.replace('{key}','uniqueJobPostersWeb').replace('{fromDate}',moment(fromDate).format("yyyy-MM-DD")).replace('{toDate}', moment(toDate).format("yyyy-MM-DD")),'WebAppUsersRecruiters')}>{dataByDate?.uniqueJobPosterAccessedWebApp}</td>
          </tr>
          <tr style={{height:"30px"}}>
            <td className="txtAlgn" style={{borderRight:"0.5px solid gray"}}>4</td>
            <td style={{borderRight:"0.5px solid gray",padding:"0 10px"}}>Recruiter's Notifications</td>
            <td style={{textAlign:"end",paddingRight:"20px"}}>{dataByDate?.notificationToJobPoster}</td>
          </tr>
          <tr style={{backgroundColor:"#C1E7D8",height:"30px"}}>
            <td className="txtAlgn" style={{borderRight:"0.5px solid gray"}}>5</td>
            <td style={{borderRight:"0.5px solid gray",padding:"0 10px"}}>Applicant's Notifications</td>
            <td style={{textAlign:"end",paddingRight:"20px"}}>{dataByDate?.notificationToApplicant}</td>
          </tr>
          {/* <tr style={{backgroundColor:"#E8F2FF",height:"30px"}}>
            <td style={{borderRight:"0.5px solid gray"}}>7</td>
            <td style={{borderRight:"0.5px solid gray",padding:"0 10px"}}>New Applicants</td>
            <td>{dataByDate?.totalNewApplicants}</td>
          </tr> */}
          <tr style={{height:"30px"}}>
            <td className="txtAlgn" style={{borderRight:"0.5px solid gray"}}>6</td>
            <td style={{borderRight:"0.5px solid gray",padding:"0 10px"}}>New Recruiters</td>
            <td style={{textAlign:"end",paddingRight:"20px",cursor:dataByDate?.totalNewJobPoster!=0 && "pointer",color:dataByDate?.totalNewJobPoster!=0 && "blue"}} onClick={()=>dataByDate?.totalNewJobPoster!=0 && ExcelDownload(Url?.downloadExcelDateWise?.replace('{key}','totalNewJobPosters').replace('{fromDate}',moment(fromDate).format("yyyy-MM-DD")).replace('{toDate}', moment(toDate).format("yyyy-MM-DD")),'NewRecruiters')}>{dataByDate?.totalNewJobPoster}</td>
          </tr>
          {/* <tr style={{backgroundColor:"#E8F2FF",height:"30px"}}>
            <td style={{borderRight:"0.5px solid gray"}}>9</td>
            <td style={{borderRight:"0.5px solid gray",padding:"0 10px"}}>New Companies</td>
            <td>{dataByDate?.totalNewCompanies}</td>
          </tr>  */}
          <tr style={{backgroundColor:"#C1E7D8",height:"30px"}}>
            <td className="txtAlgn" style={{borderRight:"0.5px solid gray"}}>7</td>
            <td style={{borderRight:"0.5px solid gray",padding:"0 10px"}}>New Jobs Posted</td>
            <td style={{textAlign:"end",paddingRight:"20px",cursor:dataByDate?.totalJobs!=0 && "pointer",color:dataByDate?.totalJobs!=0 && "blue"}} onClick={()=>dataByDate?.totalJobs!=0 && ExcelDownload(Url?.downloadExcelDateWise?.replace('{key}','totalNewJobs').replace('{fromDate}',moment(fromDate).format("yyyy-MM-DD")).replace('{toDate}', moment(toDate).format("yyyy-MM-DD")),'NewJobsPosted')}>{dataByDate?.totalJobs}</td>
          </tr>
  </tbody>
      </table>
      </div>
      </div>
        }
       
        </div>
        </div>

        <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderstyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
    </div>:
    <div style={{width:"100%",height:"85vh",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
    <img src={nodatafound} style={{width:"20%"}}/>
    <span>Oops! No Page Found</span>
   </div>
    }</>
  );
};

export default TotalUsageSummaryReport;
