import React, { useEffect, useState } from "react";
import "./ApplicantRepository.css";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Url } from "../../EnvoirnmentSetup/APIs";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Lottie from "lottie-react";
import loader from "../../../src/assets/PANLoader.json";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import moment from "moment";
import editBtn from "../../assets/edit.png";
import deleteBtn from "../../assets/delete.png";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { BsArrowDownUp, BsArrowDown, BsArrowUp } from "react-icons/bs";
import { useSelectedCardContext } from "../../Context/Context";
import searchIcon from '../../assets/searchLogo.svg'
import whatsappIcon from '../../assets/whatsapp.svg'
import nodata from '../../assets/nodata.png';
import breadCrumbArrow from '../../assets/backArrowGray.svg'

const ApplicantRepository = () => {
  const navigate = useNavigate();
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  useEffect(() => {
    setSelectedCard(3);
    setPageSize(50)
  }, []);

  const [applicantList, setApplicantList] = useState([]);
  const [noDataMsg,setNoDataMsg] = useState("Search through applicants here!")
  const [fetching, setFetching] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [deletemodalIsOpen, setDeletemodalIsOpen] = useState(false);
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
  const [SuccessModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [error, setError] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [initialPageIndex, setInitialPageIndex] = useState(0);
  const [applicantListByDatePosted, setApplicantListByDatePosted] = useState([]);
  const [searchText, setsearchText] = useState("");

  const [sortingOptions, setSortingOptions] = useState([
    {
      displayName: "Sort By Keywords",
      valueTobeSent: "skill",
    },
    {
      displayName: "Sort By Applicant Name",
      valueTobeSent: "applicant",
    },
    {
      displayName: "Sort By Experience",
      valueTobeSent: "experience",
    },
  ]);
  const [selectedSortingOption, setSelectedSortingOption] = useState({
      displayName: "Sort By Keywords",
      valueTobeSent: "skill",
    },);
  //handle pagination states
  const [selectedPageNumber, setSelectedPageNumber] = useState({
    actualNumber: 1,
    gotoNumber: 1,
  });
  const [totalpages, setTotalpages] = useState(0);
  const [pageEntries, setPageEntries] = useState(50);
  const [isfilteredByDaterange, setIsfilteredByDaterange] = useState({
    status: false,
  });

  console.log({ selectedPageNumber });

  const loaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };
  const customStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const columns = React.useMemo(
    () => [
      { Header: "Keywords", accessor: "skillString" },
      {
        Header: "Experience",
        accessor: "experience.experience",
        width: "5%",
      },
      {
        Header: "Work Location",
        accessor: "stateString",
        width: "12%",
      },
    ],
    []
  );

  const {
    state: { pageIndex, pageSize },
    setPageSize,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page, // Add page variable
    nextPage, // Add nextPage function
    previousPage, // Add previousPage function
    canNextPage, // Add canNextPage variable
    canPreviousPage, // Add canPreviousPage variable
    gotoPage, // Function to go to a specific page
    pageCount, // Total number of pages
  } = useTable(
    {
      columns,
      data: applicantList,
      initialState: {
        pageIndex: initialPageIndex,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination // Add usePagination hook
  );
  const { globalFilter } = state;

  useEffect(() => {
    let newPage = parseInt(state.pageIndex) + 1;
    console.log({ newPage });
    sessionStorage.setItem("TablePageNumber", newPage);
    // setInitialPageIndex(newPage)
  }, []);

  useEffect(() => {
    let pageNumber = sessionStorage.getItem("storedPageNumber");
    if (!!pageNumber) {
      setSelectedPageNumber({
        actualNumber: Number(pageNumber),
        gotoNumber: Number(pageNumber),
      });
    }
    // let seachFieldText = sessionStorage.getItem("searchText");
    // console.log({seachFieldText})
    // if (!!seachFieldText) {
    //   setsearchText(seachFieldText);
    // }
    // let fiterOption =  sessionStorage.getItem("filterOption");
    // if (!!fiterOption) {
    //   fiterOption = JSON.parse(fiterOption)
    //   console.log({fiterOption})
    //   setSelectedSortingOption(fiterOption)
    // }
    // let pageEntriesLength = sessionStorage.getItem("filterOption");
    // if (!!pageEntriesLength) {
    //   setPageEntries(Number(pageEntriesLength));
    //   setPageSize(Number(pageEntriesLength));
    // }
  }, []);

  useEffect(() => {
    console.log({ searchText });
    if (searchText?.length > 2) {
      searchCreatedJobs(searchText);
      sessionStorage.setItem("searchText", searchText);
    } else if (searchText?.length == 0) {
      //getAllApplicantList();
      setApplicantList([])

    }
  }, [
    selectedPageNumber.actualNumber,
    pageEntries,
    isfilteredByDaterange?.status,
    selectedSortingOption?.valueTobeSent,
    searchText,
  ]);

  function openDeleteModal() {
    setDeletemodalIsOpen(true);
  }

  function closeDeleteModal() {
    setDeletemodalIsOpen(false);
  }
  function openFailModal() {
    setFailmodalIsOpen(true);
  }

  function closeFailModal() {
    setFailmodalIsOpen(false);
  }

  function openSuccessModal() {
    setSuccessModalIsOpen(true);
  }

  function closeSuccessModal() {
    setSuccessModalIsOpen(false);
  }

  const getAllApplicantList = async () => {
    // setIsLoading(true)
    console.log("running get API*****************************");

    try {
      setFetching(true);
      let token = sessionStorage.getItem("token");

      fetch(
        Url?.getApplicantrepositoryList
          .replace("{pageSize}", pageEntries)
          .replace("{pageNumber}", selectedPageNumber?.actualNumber - 1).replace("{field}", selectedSortingOption?.valueTobeSent),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          console.log({ res });
          if (res?.SUCCESS) {
            setFetching(false);
            setTotalpages(res?.DATA?.totalPages);

            let changedData = res?.DATA?.jobs?.map((i) => {
              console.log("rrrrrrr", i?.applicantCount);

              let stateString = "";
              i?.state?.map((j, index) => {
                if (index < i.state?.length - 1) {
                  stateString = stateString + j.stateName + ", ";
                } else {
                  stateString = stateString + j.stateName;
                }
              });
              let skillString = "";
              i?.skill?.map((j, index) => {
                if (index < i.skill?.length - 1) {
                  skillString = skillString + j.name + ", ";
                } else {
                  skillString = skillString + j.name;
                }
              });

              stateString = stateString == "" ? '-' : stateString
              skillString = skillString == "" ? '-' : skillString

              let combineMobileNumber =
                i?.countryCode?.countryCode + i?.mobileNumber;

                let showMobile =
               "+"+i?.countryCode?.countryCode + " " +i?.mobileNumber;
              return {
                ...i,
                stateString,
                combineMobileNumber,
                skillString,
                showMobile
              };
            });
            console.log(
              "Get Created Jobs...........",
              JSON.stringify(changedData)
            );
            // if(changedData.length>0){
            //   setIsJobAvailbale(true)
            // }else{
            //   setIsJobAvailbale(false)
            // }

            // const compareDatesposted = (a, b) =>
            //   moment(a?.postedDateTime).diff(moment(b?.postedDateTime));
            // const copiedJobDataArray1 = [...changedData];
            // const sortedArrayPosted =
            //   copiedJobDataArray1.sort(compareDatesposted);
            // console.log("sortedArrayPosted", JSON.stringify(sortedArrayPosted));
            setApplicantList([...changedData]);
            // setApplicantListByDatePosted(changedData);

            //     const compareDates = (a, b) => moment(a?.tillDate).diff(moment(b?.tillDate));
            //     const copiedJobDataArray2 = [...changedData]

            //     const sortedArray = copiedJobDataArray2.sort(compareDates);
            //     console.log({sortedArray})
            //     setApplicantListByDateFillDate(sortedArray)

            //     const copiedJobDataArray3 = [...changedData]
            //     const sortedJobDataArray = copiedJobDataArray3.sort((a, b) => b.experience.experienceId - a.experience.experienceId);
            //     setApplicantListByExperience(sortedJobDataArray)
          }
        });
    } catch (error) {
      console.log("Get Created Jobs.error..........", error);
    } finally {
      //   setIsLoading(false)
    }
  };

  const searchCreatedJobs = async (text) => {
    console.log(
      "running search API*****************************",
      Url?.getApplicantrepositoryList
        .replace("{pageSize}", pageEntries)
        .replace("{pageNumber}", selectedPageNumber?.actualNumber - 1)
        .replace("{keyword}", text).replace("{field}", selectedSortingOption?.valueTobeSent)
    );

    if (text?.length >= 2) {
      console.log({ isfilteredByDaterange });
      try {
        setFetching(true);
        let token = sessionStorage.getItem("token");

        fetch(
          Url?.searchApplicantfromrepositoryList
            .replace("{pageSize}", pageEntries)
            .replace("{pageNumber}", selectedPageNumber?.actualNumber - 1)
            .replace("{keyword}", text)
            .replace("{field}", selectedSortingOption?.valueTobeSent),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
          .then((response) => response.json())
          .then((res) => {
            console.log({
              res,
            });
            if (res?.SUCCESS) {
              setFetching(false);
              setTotalpages(res?.DATA?.totalPages);
              if(res?.DATA?.jobs?.length==0){
                setNoDataMsg("No Applicants Found.")
               }
              let changedData = res?.DATA?.jobs?.map((i) => {
                console.log("rrrrrrr", i?.applicantCount);
  
                let stateString = "";
                i?.state?.map((j, index) => {
                  if (index < i.state?.length - 1) {
                    stateString = stateString + j.stateName + ", ";
                  } else {
                    stateString = stateString + j.stateName;
                  }
                });
                let skillString = "";
                i?.skill?.map((j, index) => {
                  if (index < i.skill?.length - 1) {
                    skillString = skillString + j.name + ", ";
                  } else {
                    skillString = skillString + j.name;
                  }
                });
  
                let combineMobileNumber =
                  i?.countryCode?.countryCode + i?.mobileNumber;


                  let showMobile =
                  "+"+i?.countryCode?.countryCode + " " +i?.mobileNumber;

                return {
                  ...i,
                  stateString,
                  combineMobileNumber,
                  skillString,
                  showMobile
                };
              });
              console.log(
                "Get Created Jobs...........",
                JSON.stringify(changedData)
              );
              // if(changedData.length>0){
              //   setIsJobAvailbale(true)
              // }else{
              //   setIsJobAvailbale(false)
              // }
  
              // const compareDatesposted = (a, b) =>
              //   moment(a?.postedDateTime).diff(moment(b?.postedDateTime));
              // const copiedJobDataArray1 = [...changedData];
              // const sortedArrayPosted =
              //   copiedJobDataArray1.sort(compareDatesposted);
              // console.log("sortedArrayPosted", JSON.stringify(sortedArrayPosted));
              setApplicantList([...changedData]);
              // setApplicantListByDatePosted(changedData);
  
              //     const compareDates = (a, b) => moment(a?.tillDate).diff(moment(b?.tillDate));
              //     const copiedJobDataArray2 = [...changedData]
  
              //     const sortedArray = copiedJobDataArray2.sort(compareDates);
              //     console.log({sortedArray})
              //     setApplicantListByDateFillDate(sortedArray)
  
              //     const copiedJobDataArray3 = [...changedData]
              //     const sortedJobDataArray = copiedJobDataArray3.sort((a, b) => b.experience.experienceId - a.experience.experienceId);
              //     setApplicantListByExperience(sortedJobDataArray)
            }
          });
      } catch (error) {
        console.log("Get Created Jobs.error..........", error);
      } finally {
        //   setIsLoading(false)
      }
    } else if (text?.length == 0) {
      //getAllApplicantList();
      setApplicantList([])

    } else {
    }
    // setIsLoading(true)
  };

  const DeleteJob = (jobId) => {
    setIdToDelete(jobId);
    openDeleteModal();
  };

  const handleDeleteUser = async () => {
    closeDeleteModal();
    let token = sessionStorage.getItem("token");
    fetch(Url?.deleteJob.replace("{jobId}", idToDelete), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res?.SUCCESS) {
          //getAllApplicantList();
          setsuccessMsg(`This job is deleted successfully!`);
          openSuccessModal();
        } else {
          let originalString = res?.message;
          if(originalString.includes(':')){
            let resultString = originalString
            .split(":")
            .slice(1)
            .join(":")
            .trim();
          setError(resultString);
          }else{
            setError(originalString);
          }
          openFailModal();
        }
      });
  };

  // const sortBySelectedDateRange = () =>{

  //   const datesBetween = applicantListByDatePosted.filter(date => {
  //     let referenceDate = moment(date.postedDateTime).format("yyyy-MM-DD")
  //     let newfromDate =  moment(fromDate).format("yyyy-MM-DD")
  //     let newtoDate = moment(toDate).format("yyyy-MM-DD")
  //     console.log("innnnnn" ,moment(referenceDate).isBetween(newfromDate,newtoDate, null, '[]'),referenceDate, newfromDate, newtoDate)

  //    if(moment(referenceDate).isBetween(newfromDate,newtoDate, null, '[]')){
  //     return date
  //    }
  //   });

  //   console.log({datesBetween})
  //   setApplicantList(datesBetween)
  // }

  const clearDateFilter = () => {
    setSelectedPageNumber({
      actualNumber: 1,
      gotoNumber: 1,
    });
    setFromDate(null);
    setToDate(null);
    setIsfilteredByDaterange({ ...isfilteredByDaterange, status: false });
  };

  return (
    <div>
      {/* GO Back functionality */}
      <div className="appcntRepoFixedContent">
        <div className="applcntRepoOuterDiv" style={{ marginTop: "5px" }}>
          {/* <span className="appRepoName">Search Applicant</span> */}
          <p>Applicants</p>

          {/* <ul className="breadCrumb">
          <li style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px"}}><img style={{width:"8px"}} src={breadCrumbArrow} alt="" />
          <span style={{fontSize:"14px",cursor:"pointer"}} onClick={()=>navigate(-1)}>Search Applicant</span>
          </li>
        </ul> */}
          {/* <span
            onClick={() => {
              navigate(-1);
            }}
            className="applcntRepoP"
          >
            Back to homepage
          </span> */}
        </div>


        <div className="appcntRepoSec2Div2">
          { page.length !== 0 ?<div className="EntryCount entryCountNew">
            <p>Show</p>
            <div></div>
            <select
              className="selectEntry selectEntryNew"
              value={pageEntries}
              onChange={(e) => {
                setPageEntries(Number(e.target.value));
                setPageSize(Number(e.target.value));
                setSelectedPageNumber({
                  actualNumber: 1,
                  gotoNumber: 1,
                });
                sessionStorage.setItem(
                  "pageEntriesLength",
                  JSON.stringify(e.target.value)
                );
              }}
            >
              {[50, 100]?.map((pageSizeOption) => (
                <option key={pageSizeOption} value={pageSizeOption}>
                  1-{pageSizeOption}
                </option>
              ))}
            </select>
          </div> : <div></div>}

          <div className="appcntRepoSearchSortDiv">
            <div className={page.length == 0 ?"appcntRepoSearchDiv":"appcntRepoSearchDiv2"}>

              <div className="appcntRepoImgDiv">
                <img src={searchIcon} />
              </div>

              <input
                type="text"
                placeholder="Search here..."
                value={searchText || ""}
                onChange={(e) => {
                  setsearchText(e.target.value);setNoDataMsg("Search through applicants here!")
                }}
                className="appcntRepoSearchInput"
              // className="appcntRepoSearch"
              />
            </div>

            {page.length !== 0 ?<div className="sortFilter sortFilterNew">
              <select
                value={selectedSortingOption?.displayName}
                onChange={(e) => {
                  console.log("jjjjj", e.target.selectedIndex);
                  setSelectedSortingOption(
                    sortingOptions[e.target.selectedIndex]
                  );
                  sessionStorage.setItem(
                    "filterOption",
                    JSON.stringify(sortingOptions[e.target.selectedIndex])
                  );
                }}
                className="editSortfilter"
                style={{ outline: "none" }}
              >
                {sortingOptions
                  .map((i) => {
                    return i.displayName;
                  })
                  .map((entries, index) => (
                    <option key={entries} value={entries}>
                      {entries}
                    </option>
                  ))}
              </select>
            </div> : <div></div>}
          </div>
        </div>

      </div>
      <div className="appcntRepoTableMainDiv">
        {
          page.length == 0 ? <div className="noNotificationMsg" style={{height:"50vh"}}>
            <img
              src={nodata}
              className="nodataImage"
            />
            <p>{noDataMsg}</p>
          </div> :
          <>
          <div className="appcntRepoTableOuterDiv">
              <div className="appcntRepoTableSubDiv">
                <table {...getTableProps()} className="appcntRepoTableDiv">
                  <thead className="appcntRepoThead">
                    {headerGroups?.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="appcntRepoTr"
                        style={{ borderRadius: "20px" }}
                      >
                        <th
                          colspan="1"
                          role="columnheader"                          
                          className="appcntRepoTh"
                          style={{ borderTopLeftRadius: "10px",borderRight:"0.5px solid gray" }}>
                          #<span></span>
                        </th>
                        <th
                          colspan="1"
                          role="columnheader"                          
                          className="appcntRepoTableTh"
                          style={{ borderRight: "0.5px solid gray",textAlign:"center",width:"18%" }}
                        >
                          Applicant Name (LinkedIn)
                        </th>
                        {headerGroup?.headers?.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            style={{
                              // border:"1px solid red",
                              padding: "10px",
                              textAlign: "left",
                              width: column.width,
                              borderRight: "0.5px solid gray",textAlign:"center"
                            }}
                            className={column.className}
                          >
                            {column.render("Header")}
                            {/* <span style={{ padding: '2%', textAlign: 'center', height: "auto", alignSelf: "center" }}>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <BsArrowDown size={18} />
                        : <BsArrowUp size={18} />
                      : <BsArrowDownUp ssize={18} />
                    }
                  </span> */}
                          </th>
                        ))}
                        <th
                          colspan="1"
                          role="columnheader"                          
                          className="appcntRepoTableTh"
                          style={{ borderRight: "0.5px solid gray", width:"12%",textAlign:"center" }}
                        >
                          Mobile Number
                        </th>
                        <th
                          colspan="1"
                          role="columnheader"                          
                          className="appcntRepoTableTh chatWithAppcnt" style={{textAlign:"center",width:"14%"}}
                        >
                          Chat with Applicant
                        </th>
                        <th
                          colspan="1"
                          role="columnheader"                          
                          className="appcntRepoTableTh"
                          style={{ borderTopRightRadius: "10px",textAlign:"center"}}>
                          Email Id
                        </th>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="appcntRepoTbody" style={{borderBottom:"0.5px solid gray"}}>
                    {page?.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="trBorderBottom"
                          key={row.jobId}
                          style={{
                            backgroundColor:
                              row?.index % 2 == 0 ? "#C1E7D8" : "#ffff",
                          }}
                        >
                          <td style={{ border: "0.5px solid gray" }}>
                            <p className="appcntRepoIndex">{index + 1}</p>
                          </td>
                          <td style={{ border: "0.5px solid gray" }}>
                            <div className="ApplicantRepoCounterButtonsWrapper">
                              <p
                                style={{
                                  width: "90%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  margin: "0 5px",
                                  color: row?.original?.linkedinProfile && "blue",
                                  cursor: row?.original?.linkedinProfile && "pointer",
                                }}
                                onClick={() => {
                                  if(row?.original?.linkedinProfile){
                                  window.open(row?.original?.linkedinProfile);}
                                }}
                              >
                                {row?.original?.fullName}
                              </p>
                            </div>
                          </td>
                          {row?.cells?.map((cell) => (
                            <td
                              style={{border: "0.5px solid gray"}}
                              {...cell.getCellProps()}
                              className="appcntRepoTdPadding"
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}

                          <td style={{ border: "0.5px solid gray" }}>
                            <div className="ApplicantRepoCounterButtonsWrapper">
                              <p
                                className="ContactlinkWhatsapp"
                              >
                                {row?.original?.showMobile}
                              </p>
                            </div>
                          </td>
                          <td style={{ border: "0.5px solid gray" }}>
                            <div className="ApplicantRepoCounterButtonsWrapper" style={{ display: "flex", justifyContent: "center" }}>

                              <img
                                src={whatsappIcon}
                                className="eyeIconblue"
                                onClick={() => {
                                  window.open(
                                    "https://wa.me/" +
                                    row?.original?.combineMobileNumber
                                  );
                                }}
                              />
                            </div>
                          </td>

                          <td style={{ border: "0.5px solid gray" }}>
                            <div className="ApplicantRepoCounterButtonsWrapper">
                              <p
                                className="Contactlink"
                                onClick={() => {
                                  window.open(`mailto:${row?.original?.userName}`);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {row?.original?.userName}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              
            </div>
            <div className="appcntRepoBottomNavs">
                <ComponentAndScreenConstant.TablePagination
                  setPageNumber={setSelectedPageNumber}
                  pagenumber={selectedPageNumber}
                  totalpages={totalpages}
                />
              </div></>
            

        }

        {/* <div className="ApplicantRepomanagePrevNextPageWrapper">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="ApplicantRepopreviousNextButton"
          >
            {"<<"}
          </button>{" "}
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="ApplicantRepopreviousNextButton"
          >
            {"<"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {parseInt(state.pageIndex)  + 1} of {pageCount}
            </strong>{" "}
          </span>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="ApplicantRepopreviousNextButton"
          >
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="ApplicantRepopreviousNextButton"
          >
            {">>"}
          </button>{" "}
          <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={state.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{
                width: "50px",
                border: "1px solid var(----Color3)",
                borderRadius: "5px",
              }}
            />
          </span>{" "}
        </div> */}


        <div></div>
      </div>

      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderstyle}
        contentLabel="Example Modal"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
      <Modal
        isOpen={deletemodalIsOpen}
        onRequestClose={closeDeleteModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
      >
        <div className="ApplicantRepodeleteModalWrapper">
          <div className="ApplicantRepodelAccModalCrossDiv">
            <p
              className="ApplicantRepodelAccModalCrossPara"
              onClick={closeDeleteModal}
            >
              ×
            </p>
          </div>
        </div>
        <div className="ApplicantRepodelAccModalErrMsg">
          <p>Would you like to delete this job post?</p>
          <div>
            <button
              style={{
                padding: "10px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #00ADEE",
              }}
              onClick={closeDeleteModal}
            >
              No
            </button>
            <button
              style={{
                padding: "10px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #00ADEE",
                color: "#FFF",
                backgroundColor: "#00ADEE",
              }}
              onClick={() => {
                handleDeleteUser();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={closeFailModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
      >
        <div className="ApplicantRepodelAccModal1">
          <div className="ApplicantRepodelAccModalCrossDiv">
            <p
              className="ApplicantRepodelAccModalCrossPara"
              onClick={closeFailModal}
            >
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="ApplicantRepodelAccModalErrLottie"
          />
        </div>
        <div className="ApplicantRepodelAccModalErrMsg">{error}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={closeFailModal}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={SuccessModalIsOpen}
        onRequestClose={closeSuccessModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
      >
        <div className="ApplicantRepodelAccModal1">
          <div className="ApplicantRepodelAccModalCrossDiv">
            <p
              onClick={closeSuccessModal}
              className="ApplicantRepodelAccModalCrossPara"
            >
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="ApplicantRepodelAccModalErrLottie"
          />
        </div>
        <div className="ApplicantRepodelAccModalErrMsg">{successMsg}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={closeSuccessModal}>OK</button>
        </div>
      </Modal>
    </div>
  );
};

export default ApplicantRepository;
