import React, { useEffect, useState } from "react";
import "./ManagementLandingPage.css";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { Url } from "../../EnvoirnmentSetup/APIs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelectedCardContext } from "../../Context/Context";
import Modal from "react-modal";
import Lottie from "lottie-react";
import loader from "../../../src/assets/PANLoader.json";
import breadCrumbArrow from '../../assets/backArrowGray.svg'


const ManagementLandingPage = () => {
  const navigate = useNavigate();

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [allData, setAllData] = useState({
    totalJobPosted: 0,
    totalActiveJobs: 0,
    deletedJobs: 0,
    applicantRegistered: 0,
    UsJobPoster: 0,
    IndianJobPoster: 0,
    posterRegistered: 0,
    expiredJobs: 0,
  });
  //console.log( "alldata....>>>>>>>????",allData )
  const [dataByDate, setDataByDate] = useState({
    uniquePeopleAccessedApplication: 0,
    uniqueApplicantAccessedMobileApp: 0,
    uniqueJobPosterAccessedMobileApp: 0,
    uniqueJobPosterAccessedWebApp: 0,
    notificationToJobPoster: 0,
    notificationToApplicant: 0,
    totalNewApplicants: 0,
    totalNewJobPoster: 0,
    totalNewCompanies: 0,
    totalJobs: 0,
  });
  const [showTable, setShowTable] = useState(false);
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setSelectedCard(6);
    getAllrecords();
  }, []);

  const loaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };

  const getAllrecords = () => {
    setFetching(true)
    try {
      let token = sessionStorage.getItem("token");

      fetch(Url?.AlldataReport, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }) 
        .then((response) => response.json())
        .then((res) => { 
          setFetching(false);
          console.log( "AlldataReportAlldataReport...",res )
          if (res.SUCCESS) {
            setAllData({
              totalJobPosted: res?.DATA?.totalJobs,
              totalActiveJobs: res?.DATA?.totalActiveJobs,
              deletedJobs: res?.DATA?.deletedJobs,
              applicantRegistered: res?.DATA?.totalApplicants,
              UsJobPoster: res?.DATA?.totalUSJobPosters,
              IndianJobPoster: res?.DATA?.totalIndianJobPosters,
              posterRegistered: res?.DATA?.totalJobPosters,
              expiredJobs: res?.DATA?.totalExpiredJobs,
            });
          }
        });
    } catch (error) {
      setFetching(false)
      console.log("Experience", error);
    }
  };

  const getDataByDates = () => {
    //   console.log("date",moment(fromDate).format("yyyy-MM-DD"))
    try {
      let token = sessionStorage.getItem("token");

      fetch(
        Url?.getReportByDates
          .replace("{fromDate}", moment(fromDate).format("yyyy-MM-DD"))
          .replace("{toDate}", moment(toDate).format("yyyy-MM-DD")),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          console.log({ res });
          if (res.SUCCESS) {
            setDataByDate({
              uniquePeopleAccessedApplication: res?.DATA?.uniquePeoples,
              uniqueApplicantAccessedMobileApp: res?.DATA?.uniqueApplicants,
              uniqueJobPosterAccessedMobileApp:
                res?.DATA?.uniqueJobPostersMobile,
              uniqueJobPosterAccessedWebApp: res?.DATA?.uniqueJobPostersWeb,
              notificationToJobPoster: res?.DATA?.jobPosterNotifications,
              notificationToApplicant: res?.DATA?.applicantNotifications,
              totalNewApplicants: res?.DATA?.totalNewApplicants,
              totalNewJobPoster: res?.DATA?.totalNewJobPosters,
              totalNewCompanies: res?.DATA?.newCompanies,
              totalJobs: res?.DATA?.totalJobs,
            });
            setShowTable(true);
          }
        });
    } catch (error) {
      console.log("Experience", error);
    }
  };

  return (
    <div>
      {/* <div className="managementLandingOuterDiv" style={{ marginTop: "5px" }}>
        <p
          style={{
            color: "#007749",
            fontSize: "1.5rem",
            marginTop: "0px",
            marginBottom: "5px",
          }}
        >
          Management
        </p>
      </div> */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: ' 5px 13px 0',marginTop:0 }}>
        <p className="breadCrumbDiv1p" style={{marginBottom:"5px"}}>Management</p>
        <ul className="breadCrumb">
        <li className="breadcrumbDiv2"><img className="breadcrumbDiv2Img" src={breadCrumbArrow} alt="" />
        <span onClick={()=>navigate(-1)}>Management</span>
        </li>
        </ul>
      </div>
      <div className="UpperButtonsContainerWrapper">
        <button
          className="UpperButtons"
          onClick={() => navigate("/posterDashboard/management/alljobs")}
        >
          Search Job
        </button>
        <button className="UpperButtons"   onClick={() => navigate("/posterDashboard/management/userManagement")}>User Admin</button>
        <button className="UpperButtons" onClick={()=>navigate('/posterDashboard/management/reportdashboard')}>Reports</button>
      </div>
      <div className="managementreportUpperContainerOuterWrapper">
        <div className="managementreportUpperContainerWrapper">
          <div className="reportUpperContainerLeftWrapper">
            <div className="reportentryName">
              <p className="CountContainer">{allData?.totalJobPosted}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Total Jobs posted so far</p>
            </div>
            <div className="reportentryName">
              <p className="CountContainer">{allData?.totalActiveJobs}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Total Active Jobs</p>
            </div>
            <div className="reportentryName">
              <p className="CountContainer">{allData?.posterRegistered}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Total Recruiters</p>
            </div>
            <div className="reportentryName">
              <p className="CountContainer">{allData?.IndianJobPoster}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Total Indian Recruiters</p>
            </div>
            
          </div>
          <div className="reportUpperContainerRightWrapper">
          <div className="reportentryName">
              <p className="CountContainer">{allData?.deletedJobs}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Jobs that were deleted</p>
            </div>
            <div className="reportentryName">
              <p className="CountContainer">{allData?.expiredJobs}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Jobs that were expired</p>
            </div>
            <div className="reportentryName">
              <p className="CountContainer">{allData?.applicantRegistered}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Total Applicant Registered</p>
            </div>
            <div className="reportentryName">
              <p className="CountContainer">{allData?.UsJobPoster}</p>
              <div
                style={{ borderRight: "1px solid #9A9A9A", margin: "0px 5px" }}
              ></div>
              <p className="CountContainer">Total US Recruiters</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{display:"flex", justifyContent:"center"}}>
      <div className='teamReportMiddleContainerWrapper'>
        <div className="reportFromDate">
          <p style={{padding:"0px", margin:"5px", flex:2}}>From Date:</p>
        <ComponentAndScreenConstant.DatePickerComponent
                    placeholderText="Enter here"
                    label={""}
                    // minDate={new Date()}
                    maxDate={new Date()}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    selectedDate={new Date(fromDate)}
                    onChange={(e) => {
                      console.log(e);
                      setFromDate(e);
                      setToDate(new Date())
                    }}
                  />
        </div>
        <div className="reportFromDate">
          <p style={{padding:"0px", margin:"5px", flex:2}}>To Date:</p>
        <ComponentAndScreenConstant.DatePickerComponent
                    placeholderText="Enter here"
                    label={""}
                    minDate={new Date(fromDate)}
                    maxDate={new Date()}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    selectedDate={new Date(toDate)}
                    onChange={(e) => {
                      console.log(e);
                      setToDate(e);
                    }}
                  />
        </div>
        <button className="SubmitReportDates" onClick={()=>{getDataByDates()
        }}>Submit</button>
        </div>
        </div>
        <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
        <div className='managerReportLowerContainerWrapper'>
      {showTable&&  <div className='tableWrapper'>
        <table style={{width:"100%", borderTopLeftRadius:"20px", borderCollapse: 'collapse',borderSpacing:"0px", borderRadius:"20px",overflow:"hidden", padding:"5px"}}  className='tableBox'>
      <thead className='tableHeader'>
        <tr >
          <th>Sr. No</th>
          <th style={{display:"flex", justifyContent:"flex-start", alignItems:"center",padding:"0 10px"}}>Parameter</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>    
          {/* <tr style={{backgroundColor:"#E8F2FF"}}>
            <td>1</td>
            <td>Unique People Accessed the Application</td>
            <td>{dataByDate?.uniquePeopleAccessedApplication}</td>
          </tr>
          <tr >
            <td>2</td>
            <td>Unique Applicants accessed the mobile app</td>
            <td>{dataByDate?.uniqueApplicantAccessedMobileApp}</td>
          </tr>
          <tr style={{backgroundColor:"#E8F2FF"}}>
            <td>3</td>
            <td>Unique Recruiters Accessed the mobile app</td>
            <td>{dataByDate?.uniqueJobPosterAccessedMobileApp}</td>
          </tr>
          <tr >
            <td>4</td>
            <td>Unique Recruiters accessed the Web app</td>
            <td>{dataByDate?.uniqueJobPosterAccessedWebApp}</td>
          </tr>
          <tr style={{backgroundColor:"#E8F2FF"}}>
            <td>5</td>
            <td>Notifications Sent to Recruiters</td>
            <td>{dataByDate?.notificationToJobPoster}</td>
          </tr>
          <tr >
            <td>6</td>
            <td>Notifications sent to Applicants</td>
            <td>{dataByDate?.notificationToApplicant}</td>
          </tr>
          <tr style={{backgroundColor:"#E8F2FF"}}>
            <td>7</td>
            <td>Total NEW Applicants</td>
            <td>{dataByDate?.totalNewApplicants}</td>
          </tr>*/}
          <tr style={{backgroundColor:"#E8F2FF"}}>
            <td style={{textAlign:"center"}}>1</td>
            <td style={{padding:"0 10px"}}>Total New Jobs Poster</td>
            <td style={{textAlign:"center"}}>{dataByDate?.totalNewJobPoster}</td>
          </tr>
          {/* <tr style={{backgroundColor:"#E8F2FF"}}>
            <td>9</td>
            <td>Total NEW Companies</td>
            <td>{dataByDate?.totalNewCompanies}</td>
          </tr>  */}
          <tr style={{backgroundColor:"#E8F2FF"}}>
            <td style={{textAlign:"center"}}>2</td>
            <td style={{padding:"0 10px"}}>Total New Jobs Posted</td>
            <td style={{textAlign:"center"}}>{dataByDate?.totalJobs}</td>
          </tr>
      </tbody>
    </table>
        </div>}
       
        </div>
        </div>

        <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderstyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
    </div>
  );
};

export default ManagementLandingPage;
